import moment from "moment";

export function sortHistoryByDate(data:any):any {
  try {
    // Ensure the data object has the expected structure
    if (!data || !data.history || !Array.isArray(data.history)) {
      throw new Error('Invalid data structure');
    }

    // Sort the history array by updatedAt in descending order
    // @ts-ignore
    const sortedHistory:any = data.history.sort((a:any, b:any) => new Date(b.updatedAt) - new Date(a.updatedAt));

    // Return a new object with the sorted history and the rest of the properties
    return {
      ...data,
      history: sortedHistory
    };
  } catch (error:any) {
    // Return a default response in case of an error
    console.error('Error sorting history:', error.message);
    return {
      statusCode: 200,
      data: {
        canPublish: false,
        inventory: [],
        history: [],
        status: 'Error occurred while sorting history'
      }
    };
  }
}


export function extractAndCapitalizeLastWords(patches:any):any {
  try {
    if (!Array.isArray(patches)) {
      throw new Error('Patches must be an array');
    }

    // Array to store the transformed last words
    const lastWords:any = [];

    // Iterate over each patch in the patches array
    patches.forEach((patch:any) => {

      if (patch.path) {
        // Get the part of the path after the last '/'
        // const pathParts:any = patch.path.split('/');
        // const lastPathPart:any = pathParts[pathParts.length - 1];

        // Get the value string and split it into words
        const words:any = patch.path.split('/');
        const lastWord:any = words[words.length - 1];

        // Capitalize the first letter of the last word
        const capitalizedLastWord = lastWord.charAt(0).toUpperCase() + lastWord.slice(1).toLowerCase();

        // Add the capitalized last word to the result array
        lastWords.push(capitalizedLastWord.split('_').join(' '));
      }
    });

    return lastWords; // Return the array of last words

  } catch (error:any) {
    // Handle any errors and return an empty array as a default response
    console.error('Error processing patches:', error.message);
    return [];
  }
}

export const formateDateForHistory = (date:string):any => {
  try {
    return moment(date).format('MMM DD,YYYY@ HH:MM')
  }catch (e) {
    return date
  }
}

export function timeAgo(datetime:any) {
  try {
    const momentDatetime = moment(datetime); // Convert datetime to Moment object
    const currentTime = moment(); // Get current time using Moment

    return momentDatetime.from(currentTime); // Get relative time using Moment's `from` method
  } catch (error) {
    console.error('Error calculating time ago:', error);
    return 'Invalid date';
  }
}


export const ProductHistoryStatus =  {
  INVALID:'INVALID',
  ACCEPTED: 'ACCEPTED'
}
