import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classes from "./index.module.css";
import { Skeleton } from "@mui/material";

const AmazonConnectionsSkeletons = () => {
  const lines = 20;

  // Dummy data for the purpose of demonstration
  const products = new Array(lines).fill({ id: 0 }); // Assuming each row has a unique id

  return (
    <div className={classes.Container}>
      <TableContainer classes={{ root: classes.TableContainer }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Name
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Account Type
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Account ID
              </TableCell>
              {/* <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Last Authorized
              </TableCell> */}
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Status
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                {""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((_, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="left" component="th" scope="row">
                  <div className={"w-100"}>
                    <Skeleton variant={"text"} />
                  </div>
                </TableCell>
                <TableCell classes={{ root: classes.TableCells }} align="left" sx={{ width: "140px" }}>
                  <div className={"w-100 d-flex justify-content-start"}>
                    <Skeleton className={"w-25"} variant={"text"} />
                  </div>
                </TableCell>
                <TableCell classes={{ root: classes.TableCells }} align="left">
                  <div className={"w-100 d-flex justify-content-start"}>
                    <Skeleton className={"w-25"} variant={"text"} />
                  </div>
                </TableCell>
                <TableCell classes={{ root: classes.TableCells }} align="left">
                  <div className={"w-100 d-flex justify-content-start gap-2"}>
                    <Skeleton width={"80px"} variant={"text"} />
                  </div>
                </TableCell>
                <TableCell classes={{ root: classes.TableCells }} align="left">
                  <div className={"w-100 d-flex justify-content-start gap-2"}>
                    <Skeleton width={"40px"} variant={"text"} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AmazonConnectionsSkeletons;
