import { ContentStatusEnum } from "../../../../utils/constants/enums";

export const publishAndApprovedModalText:any = {
  publish: 'You are about to publish the selected products. Once published, these products will go live on Amazon. \n Do you want to proceed? This action cannot be undone.',
  approve: 'You are about to approve the selected products. Approved products will be marked as ready for publishing but will not go live on Amazon yet. \n Do you want to proceed? This action cannot be undone.',
  approvePublish: 'You are about to approve & publish the selected products. Once published, these products will go live on Amazon. \n Do you want to proceed? This action cannot be undone.'
}

export const publishAndApprovedModalTitle:any = {
  publish: 'Confirm Publish',
  approve: 'Confirm Approve',
  approvePublish: 'Confirm Approve & Publish'
}

export const publishModalTypeObj = {
  publish:'publish',
  approve:'approve',
  approvePublish:'approvePublish',
}


export const getBulkApprovePublishResponse = (rows:any) => {
  try {
   const response:any = []
    rows.forEach((row:any) => {

      if(row.status === ContentStatusEnum.DRAFT || row.status === ContentStatusEnum.EDITED) {
        response.push({productASIN: row.productASIN, userID: row.userID, shouldPublish:true, forceApprove: true })
      }else {
        response.push({productASIN: row.productASIN, userID: row.userID, shouldPublish:true, forceApprove: false })
      }
   })
  return response
  }catch(err) {
    console.log('test pick error getBulkApprovePublishResponse',err)
  }
}

export const getBulkPublishResponse = (rows:any) => {
  try {
   const response:any = []
    rows.forEach((row:any) => {
      if(row.status === ContentStatusEnum.DRAFT || row.status === ContentStatusEnum.EDITED) {
      }else {
        response.push({productASIN: row.productASIN, userID: row.userID, shouldPublish:true, forceApprove: false })
      }
   })
  return response
  }catch(err) {
    console.log('test pick error getBulkPublishResponse',err)
  }
}

export const getBulkApproveResponse = (rows:any) => {
  try {
    const response:any = []
    rows.forEach((row:any) => {
      if(row.status === ContentStatusEnum.DRAFT || row.status === ContentStatusEnum.EDITED) {
        response.push({productASIN: row.productASIN, userID: row.userID })
      }
    })
    return response
  }catch(err) {
    console.log('test pick error getBulkApproveResponse',err)
  }
}