import axios from "../axios";
import {
  AddFeedbackPayload,
  ApproveProductApi,
  ApproveProductPayload,
  BSRChartDropdownObject,
  BSRPerformanceChartDropdownObject, BSRSalesAnalyticsObject,
  CompleteProductApi,
  CsvProductsResponse,
  EditGeneratedProductPayload,
  LinkAsinToProductPayload,
  ProductEventsHistoriesPayload,
  UpdateBulkProductPayload,
  UpdateProductTitleVariationPayload,
  UserProductResponse,
} from "./types/generate-product";
import { errorHandler } from "../utils/helpers/apis";
import { initialGetAllProductPayload } from "./types/product";
import { transformGetAllBrandsApi } from "./mapping/product-mapping";
import { ProductLiveStatusEnum } from "../utils/constants/enums";
export const generateProductApis = {
  async getUserProducts(payload: any) {
    return axios
      .post<any, UserProductResponse>(`/generated-product/all-products`, payload)
      .then((r: UserProductResponse) => r.data.data);
  },
  async getProductVariations(payload: any) {
    return axios
      .post<any, any>(`/generated-product/group-products`, payload)
      .then((r: any) => r.data.data);
  },
  async getOpportunityReport(payload: any) {
    return axios.post<any, any>(`/generated-product/opportunity-report`, payload).then((r: any) => r.data.data);
  },
  async getOpportunityReportDownloadUrl(payload: any) {
    return axios.post<any, any>(`/generated-product/opportunity-report-csv`, payload).then((r: any) => r.data.data);
  },
  async checkRegenerationLimit() {
    return axios
      .get<any>(`/generated-product/check-regeneration-limit`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async getCompleteProduct(productASIN: string, userID: string) {
    return axios
      .get<any, CompleteProductApi>(`/generated-product/complete-product/${productASIN}/${userID}`)
      .then((r: CompleteProductApi) => {
        const data:any = r.data.data;
        return {...data,bestSellersRank: data?.bestSellersRank.sort((a:any,b:any) => a.rank-b.rank)}
      });
  },
  async editGeneratedProductContent(payload: EditGeneratedProductPayload) {
    return axios
      .put<EditGeneratedProductPayload, CompleteProductApi>(`/generated-product/edit-generated-content`, payload)
      .then((r: CompleteProductApi) => r.data.data);
  },
  async deleteGeneratedProducts(productIDs: string[]) {
    return axios
      .delete(`/generated-product/delete-generated-products?productIDs=${productIDs}`)
      .then((r: any) => r.data?.data);
  },
  async getBsrChartData(payload: BSRChartDropdownObject) {
    return axios
      .post<BSRChartDropdownObject>(`/generated-product/bsr-vs-search-volume-chart`, payload)
      .then((r: any) => r.data.data);
  },
  async getBsrPerformanceChartData(payload: BSRPerformanceChartDropdownObject) {
    return axios
      .post<BSRPerformanceChartDropdownObject>(`/generated-product/bsr-time-series`, payload)
      .then((r: any) => r.data.data);
  },
  async getBsrSalesAnalytics(payload: BSRSalesAnalyticsObject) {
    return axios
      .post<BSRSalesAnalyticsObject>(`/generated-product/product-sales-analytics`, payload)
      .then((r: any) => r.data.data);
  },
  async getBsrAverageChartData(payload: BSRPerformanceChartDropdownObject) {
    return (
      axios
        .post<BSRPerformanceChartDropdownObject>(`/generated-product/bsr-comparison-vs-competitors`, payload)
        // .post<BSRPerformanceChartDropdownObject>(`/generated-product/bsr-time-series`, payload)
        .then((res: any) => res.data.data)
    );
  },

  async getCsvProducts({ brand, userID, isCompany = false, companyID, showProtectedFields = false, filters }: any) {
    const searchParams = new URLSearchParams();
    if (brand) {
      searchParams.set("productBrand", brand);
    }
    if (isCompany) {
      searchParams.set("companyID", companyID);
    } else if (userID) {
      searchParams.set("userID", userID);
      if (showProtectedFields) {
        searchParams.set("showProtectedFields", showProtectedFields);
      }
    }

    const queryString = searchParams?.toString();
    return axios
      .post<any, CsvProductsResponse>(
        // `/generated-product/csv-email${brand ? `?productBrand=${brand}` : ""}${isCompany ? `?companyID=${companyID}` : userID ? `?userID=${userID}${showProtectedFields ? `?showProtectedFields=${showProtectedFields}` : ""}` : ""
        // }`
        `/generated-product/csv-email${queryString ? `?${queryString}` : ""}`,
        {...filters}
      )
      .then((r: CsvProductsResponse) => r.data.data);
  },

  async emailProductsHistory() {
    return axios
      .get<any, any>(
        `/generated-product/email-products-history`
      )
      .then((r: any) => r.data.data);
  },

  async getSelectedProductsCsv(payload: any) {
    return axios
      .post<any, CsvProductsResponse>(`/generated-product/selected-products-data`, payload)
      .then((r: CsvProductsResponse) => r.data.data);
  },
  async checkProductsLive(
    checkLiveASINsAndTheirUserIDs: { productASIN: string; userID: string }[],
    isSingle?: boolean
  ) {
    return axios
      .put<any, CompleteProductApi>(`/generated-product/${isSingle ? "single-check-live" : "check-live"}`, {
        checkLiveASINsAndTheirUserIDs,
      })
      .then((r: CompleteProductApi) => r.data.data);
  },
  async getAllBrands(payload = initialGetAllProductPayload) {
    return axios
      .post<any, any>(`/generated-product/get-all-brands`, { ...payload })
      .then((r: any) => transformGetAllBrandsApi(r.data.data));
  },
  async approveProductContent(payload: ApproveProductPayload) {
    return axios
      .put<ApproveProductPayload, ApproveProductApi>(`/generated-product/approve-content`, payload)
      .then((r: ApproveProductApi) => r.data.data);
  },
  async addFeedbackAgainstGeneratedProductByProductASIN(payload: AddFeedbackPayload) {
    return axios.post<AddFeedbackPayload, any>(`/generated-product/feedback`, payload).then((r: any) => r.data.data);
  },
  async linkAsinToProduct({ userID, newAsin, oldAsin }: LinkAsinToProductPayload) {
    return axios
      .put<LinkAsinToProductPayload, any>(
        `/product/update-unattached-asin/${oldAsin}?newASIN=${newAsin}&userID=${userID}`
      )
      .then((r: any) => r.data);
  },
  async deleteBulkProducts(productIDs: string[]) {
    return axios.delete(`/product/delete-bulk-products?productIDs=${productIDs}`).then((r: any) => r.data?.data);
  },
  async updateBulkProduct(payload: UpdateBulkProductPayload) {
    return axios.put<UpdateBulkProductPayload>(`/product/update-bulk-product`, payload).then((r: any) => r.data?.data);
  },
  async getProductEventsHistories(payload: ProductEventsHistoriesPayload) {
    return axios
      .post<ProductEventsHistoriesPayload>(`/generated-product/product-events-histories`, payload)
      .then((r: any) => r.data?.data);
  },
  async updateSelectedTitleVariant(payload: UpdateProductTitleVariationPayload) {
    return axios
      .put<UpdateProductTitleVariationPayload, any>(
        `/generated-product/update-selected-title-variant`, payload)
      .then((r: any) => r.data.data);
  },
};
export type ProductLive = {
  isTitleLive: boolean;
  isBulletPointsLive: boolean;
  isDescriptionLive: boolean;
  isProductLive: ProductLiveStatusEnum;
  lastLiveDate: string;
};
