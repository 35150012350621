import React from "react";
import ParentVariationsGrid from "./ParentVariationsGrid/ParentVariationsGrid";

import classes from "./index.module.css";

const ParentVariations = () => {
  return (
    <div className={classes.Container}>
      <ParentVariationsGrid />
    </div>
  );
};

export default ParentVariations;
