import React, { useEffect, useState } from "react";
import MTypography from "../../../../Atoms/MTypography";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Switch from "@mui/material/Switch";
import classes from "./index.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import { BrandAliasTableProps } from "../BrandAliasTable/BrandAliasTable";
import { checkIsCompanyAdmin, showUserEmailInProductsPage } from "../../../../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../../../store/user/user.selector";
import { toDateAndTimeObject } from "../../../../../utils/helpers/date";
import AccessSkeletonM from "../../../../Molecules/AnimatedSkeletonCard/AccessSkeletonM";
import NoResultsFound from "../../../../Atoms/NoResultsFound/NoResultsFound";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";

const BrandAliasCardsContainerM: React.FC<BrandAliasTableProps> = (props) => {
  const { brandsList, editBrand, totalBrands, setPageNumber, pageNumber, handleHideToggle, loading } = props;

  const dbUser = useSelector(DbUserSelector);
  const [hasMore, setHasMore] = useState<boolean>(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!loading) {
      setHasMore(totalBrands > brandsList?.length);
    }
  }, [brandsList]);

  const fetchMore = () => {
    if (totalBrands > brandsList?.length) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className={"d-flex flex-column h-auto p-2  m-2 mt-0 pt-0"} style={{ marginBottom: "75px" }}>
      <InfiniteScroll
        dataLength={brandsList?.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={
          <div className={"d-flex justify-content-center my-3"}>
            {loading && <CircularProgress color={"primary"} />}
          </div>
        }
      >
        {loading ? (
          <AccessSkeletonM />
        ) : brandsList?.length <= 0 ? (
          <>{!loading && <NoResultsFound pt={4} />}</>
        ) : (
          brandsList?.map((item: any, i: any) => (
            <div
              className={"mt-2 py-3 px-3 d-flex flex-column w-100"}
              style={{ backgroundColor: "white", borderRadius: "12px" }}
              key={i}
            >
              <div className={`d-flex mb-2 justify-content-between align-items-center`}>
                <div className="d-flex w-100 justify-content-between">
                  <div className="w-100">
                    <div
                      style={{ maxWidth: "100%", overflow: "hidden", display: "block" }}
                      className={`${classes.CellLinesLimitContainer} ${classes.BrandName}`}
                      onClick={() => editBrand(item)}
                    >
                      {capitalizeEachLetter(item.name)}
                    </div>
                    <div
                      style={{ maxWidth: "100%", overflow: "hidden", display: "block" }}
                      className={`${classes.CellLinesLimitContainer} ${classes.BrandAlias}`}
                    >
                      {item.alias}
                    </div>

                    {/* <div className="pb-2">
                      <div
                        style={{ maxWidth: "100%", overflow: "hidden", display: "block" }}
                        className={`${classes.CellLinesLimitContainer} ${classes.Email}`}
                      >
                        {item.voice ? item.voice : "--"}
                      </div>
                      <div className={`${classes.Title}`}>Brand Voice</div>
                    </div> */}
                  </div>
                  <div className="pr-1">
                    <ModeEditOutlinedIcon
                      color="primary"
                      sx={{ fontSize: "24px" }}
                      className={"cursor-pointer"}
                      onClick={() => editBrand(item)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap w-100">
                <div className="d-flex justify-content-between w-100">
                  {(showUserEmailInProductsPage(dbUser?.role) || checkIsCompanyAdmin(dbUser?.role)) &&
                    item?.userEmail && (
                      <div>
                        <div
                          style={{ maxWidth: "100%", overflow: "hidden", display: "block" }}
                          className={`${classes.Email}`}
                        >
                          {item.userEmail}
                        </div>
                        <div className={`${classes.Title}`}>User</div>
                      </div>
                    )}
                  <div>
                    <>
                      <div className={classes.SwitchContainer}>
                        <Switch size="small" checked={item?.status} onChange={() => handleHideToggle(item)} />
                      </div>
                      <MTypography variant={"subtitle2"} customClass={`${classes.HideBrandName}`}>
                        {"Hide Brand Name"}
                      </MTypography>
                    </>
                  </div>
                </div>
              </div>
              <div>
                <MTypography
                  variant={"subtitle1"}
                  customClass={`${classes.LastGeneratedContainer} d-flex justify-content-between align-items-center`}
                >
                  <div className="">Last updated:</div>
                  <div className="fw-semibold">
                    {toDateAndTimeObject(item.updatedAt).date} @ {toDateAndTimeObject(item.updatedAt).time}
                  </div>
                </MTypography>
              </div>
            </div>
          ))
        )}
      </InfiniteScroll>
    </div>
  );
};

export default BrandAliasCardsContainerM;
