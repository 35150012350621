// import { Dispatch } from "redux";
import { Dispatch } from "react";
import { AmazonConnectionsActionTypes } from "./amazonAuthorize.action-types";
import { amazonAuthorizeApi } from "../../apis/amazonAuthorize";


export interface SetAmazonConnectionsList {
  type: AmazonConnectionsActionTypes.setAmazonConnectionsList;
  payload: any[] | [],
}

export const setAuthorizedAmazonConnectionsList = (dataList: SetAmazonConnectionsList[] | []): SetAmazonConnectionsList => {
  return {
    type: AmazonConnectionsActionTypes.setAmazonConnectionsList,
    payload: dataList
  };
};

export const getAuthorizedAmazonConnectionsListDispath =
  (): any =>
    async (dispatch: Dispatch<any>) => {
      try {
        let res: any[] = await amazonAuthorizeApi.getAuthorizedAccounts();
        if (res.length) {
          dispatch(setAuthorizedAmazonConnectionsList(res));
        } else {
          dispatch(setAuthorizedAmazonConnectionsList([]));
        }
        return;
      } catch (error) {
        return error;
      }
    };


export type amazonAuthorizeActionType =
  | SetAmazonConnectionsList
