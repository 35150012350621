import { AppState } from "../reducers";
import { UserProductTag } from "../../apis/types/tag";

export const AsinKeywordHistorySelector = (state: AppState) => state.product.asinKeywordHistory;
export const SavedProductsSelector = (state: AppState) => state.product.savedProducts;
export const OpportunityProductsSelector = (state: AppState) => state.product.opportunityProducts;
export const SelectedKeywordsSelector = (state: AppState) => state.product.selectedKeywords;
export const PriortiseKeywordsSelector = (state: AppState) => state.product.priortiseKeywords;
export const TotalProductSelector = (state: AppState) => state.product.totalProducts;
export const TotalOpportunityProductSelector = (state: AppState) => state.product.totalOpportunityProduct;
export const UserProductTagsSelector = (state: AppState) => state.product.userProductTags as UserProductTag[];

export const ActiveBrandTabSelector = (state: AppState) => state.product.activeBrandTab;
export const OpportunityReportStatsSelector = (state: AppState) => state.product.opportunityStats;
export const ChangeKeywordProductOwnerId = (state: AppState) => state.product.changeKeywordsProductOwnerId;

export const ProductsPaginationSelector = (state: AppState) => state.product.productsPagination;
export const ParentVariationPaginationSelector = (state: AppState) => state.product.parentVariationPagination;
export const TotalProductsStatusSelector = (state: AppState) => state.product.totalProductsStatus;
export const ProductSearchFilterSelector = (state: AppState) => state.product.searchFilter;
export const ProductVariationsSearchFilterSelector = (state: AppState) => state.product.variationsSearchFilter;
export const WaitingProductsRefetchSelector = (state: AppState) => state.product.isRefetchWaitingProducts;
