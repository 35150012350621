import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import classes from "./index.module.css";
import { useDeviceType } from "../../../hooks/useDeviceType";
import MButton from "../../Atoms/MButton";
import MTypography from "../../Atoms/MTypography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Divider } from "@mui/material";
import MuiTooltip from "../../Atoms/MuiTooltip";
import { regenIsTempDisabled } from "../../../utils/constants/common";
interface ActionBoxProps {
  open: boolean;
  title: string;
  message?: any;
  backText?: string;
  isDanger?: boolean;
  handleBack?: (...params: any[]) => any;
  actionText?: string;
  handleAction?: (...params: any[]) => any;
  isLoading?: boolean;
  children?: React.ReactNode;
  isGenericVariation?: boolean;
}
const ActionBox: React.FC<ActionBoxProps> = ({
  open,
  handleAction,
  handleBack,
  title,
  message,
  actionText,
  backText,
  isDanger,
  isLoading = false,
  children = <></>,
  isGenericVariation = false,
}) => {
  const { isDesktop } = useDeviceType();
  const [close] = useState<boolean>(false);

  return (
    <div>
      <Dialog open={open && !close} sx={{ maxWidth: "100vw" }}>
        <div
          className={`${classes.Container} ${isDanger && classes.ContainerD} ${isDesktop && classes.ContainerWidth}`}
        >
          <div className={"d-flex align-items-start w-100"}>
            {isDanger ? (
              <ErrorOutlineIcon
                className={`${classes.Checkmark} ${isDanger && classes.CheckmarkD}`}
                fontSize={isDesktop ? "medium" : "small"}
              />
            ) : (
              <CheckCircleOutlineIcon
                className={`${classes.Checkmark} ${isDanger && classes.CheckmarkD}`}
                fontSize={isDesktop ? "medium" : "small"}
              />
            )}
            <div className={"d-flex flex-column justify-content-start w-100"}>
              <div className={"d-flex align-items-center justify-content-between w-100"}>
                <MTypography variant={"subtitle2"} customClass={`${classes.Title} ${isDanger && classes.TitleD}`}>
                  {title}
                </MTypography>
              </div>
              {message && (
                <MTypography
                  variant={"subtitle2"}
                  customClass={`${isDesktop && "w-100"} ${classes.Description} ${isDanger && classes.DescriptionD}`}
                >
                  {message}
                </MTypography>
              )}
              {children}
              <Divider sx={{ mb: 1 }} />
              <div className={`d-flex justify-content-end align-items-center gap-3 ${classes.Actions}`}>
                {backText && (
                  <MButton disabled={isLoading} onClick={handleBack} size={"small"} variant={"outlined"}>
                    {backText}
                  </MButton>
                )}
                {actionText && isGenericVariation && (
                  <MuiTooltip arrow content={regenIsTempDisabled} placement="top">
                    <MButton isLoading={isLoading} onClick={handleAction} size={"small"} disabled={isGenericVariation}>
                      {actionText}
                    </MButton>
                  </MuiTooltip>
                )}
                {actionText && !isGenericVariation && (
                  <MButton isLoading={isLoading} onClick={handleAction} size={"small"} disabled={isGenericVariation}>
                    {actionText}
                  </MButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ActionBox;
