export enum ContentStatusEnum {
  GENERATING = "generating",
  DRAFT = "pending",// approved + publish
  APPROVED = "approved", // publish
  EDITED = "pending_approval", // approved + publish
  FETCH_PRODUCT = "fetch_product",
  ERROR = "error",
  WAITING = "waiting",
  NOT_FOUND = "not_found",
  FETCHING = "fetching",
  RESTRICTED = "restricted",
  PUBLISHED = "published",
  On_HOLD = 'on_hold',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
}
export enum ProductLiveStatusEnum {
  LIVE = "live",
  INACTIVE = "inactive",
  PARTIALLY_LIVE = "partially_live",
  CHECKING = "checking",
  NEVER = "never",
  Waiting = "waiting",
  NOT_FOUND = "not_found",
  FETCHING = "fetching",
  DEACTIVATED = "deactivated",
}
export enum RolesEnum {
  ADMIN = "admin",
  EDITOR = "editor",
  INTERNAL = "internal",
  OWNER = "owner",
  PAID = "paid", // paid user
  PAID_EDITOR = "paid_editor",
  USER = "user", // trial user // and team member
  COMPANY_ADMIN = "company_admin",
  TRIAL = "trial", // for trial company
}

export enum ProductFetchType {
  STANDARD = "Standard",
  MANUAL = "Manual",
  GOGO = "Go Go",
}

export enum PagesEnum {
  ENTER_ASIN = "enterAsin",
  COMPETITORS = "competitors",
  KEYWORDS = "keywords",
  PRIORITISE = "arrange-keywords",
}

export enum TabsEnum {
  SAVED = "saved-products",
  REVIEW = "review-products",
  BLACKLIST = "keyword-blocklist",
  ACCOUNT_MANAGEMENT = "account-management",
  MORE = "more",
  SETTINGS = "settings",
  PRODUCTS = "Products",
  OPPORTUNITY_REPORT = "opportunity-report",
  SAVED_ASIN = "saved-products-asin",
  CLIENTS = "clients",
  CONTACT_US = "contact-us",
}

export enum SettingsEnum {
  BRAND = "brand",
  GoGoAutomato = "go-go-automato-settings",
  API_ACCESS_KEYS = "api-access-keys", // using constants only for ui instead of this enum
  AUTOMATO_API_ACCESS = "automato-api-access", // using constants only for ui instead of this enum
  // BULLET_POINT_FORMAT = "bullet-point-format",
  CONTENT_FORMAT_SETTINGS = "content-format-settings",
  BRAND_ALIAS_MANAGEMENT = "brand-management",
  CONFIG_FILES = "config-files",
  COMPANY_PROFILE = "company-profile",
  USER_PROFILE = "user-profile",
  TEAM_MANAGEMENT = "team-management",
  USERS = "users",
  ADD_INTERNAL_USER = "add-internal-user",
  BRAND_VOICE = "brand-voice",
  ADD_BRAND_VOICE = "add-brand-voice",
  AI_MODEL_MANAGEMENT = "ai-model-management",
  MANAGE_PUBLISH_ACCESS = "manage-publish-access",
  THIRD_PARTY_INTEGRATION = "third-party-integration",
  PIM = "pim",
  AMAZON_CONNECTIONS = "amazon-connections",
}

export enum RegistersEnum {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}

export enum ContentScoreEnum {
  OLD_DATA = "oldData",
  NEW_DATA = "newData",
}

export enum CatalogEnum {
  DETAIL_PAGE = "details",
  REVIEW_PAGE = "review",
}

export enum ParamsEnum {
  ASIN = "asin",
  TAB = "tab",
  CATALOG = "catalog",
  USER_ID = "userID",
  DOMAIN = "domain",
  BRAND = "brand",
}

export enum UserStatusEnum {
  Active = "active",
  Pause = "pause",
  Inactive = "inactive",
  Pending = "pending",
}

export enum BulletFormatEnum {
  UPPERCASE_COLON = "HEADLINE EXAMPLE: The bullet point content",
  PARENTHESIS_COLON = "【Headline Example】The bullet point content",
  DEFAULT_COLON = "Headline example: The bullet point content",
  UPPERCASE_HYPHEN = "HEADLINE EXAMPLE - The bullet point content",
  DEFAULT_HYPHEN = "Headline Example - The bullet point content",
}

export enum BulletsLengthFormatEnum {
  LONG = "long",
  SHORT = "short",
}
export enum CountryDomainEnum {
  AMAZON_USA = "amazon.com",
  AMAZON_UK = "amazon.co.uk",
  AMAZON_CANADA = "amazon.ca",
}

export enum ProductDetailsEditableKeys {
  TITLE = "generatedTitle",
  BULLET_POINTS_BY_CHAT = "bulletPointsByChat",
  DESCRIPTION = "generatedDescription",
  SEARCH_TERMS = "searchTerm",
  BULLET_POINTS = "bulletPoints",
}

export enum TagsActionEnums {
  ADD = "add",
  REMOVE = "remove",
}

export enum SwipeableTabTitlesEnums {
  TITLE = "Item Name (Title)",
  BULLET_POINTS = "Bullet Points",
  DESCRIPTION = "Product Description",
}

export enum aiModelEnums {
  GPT4 = "GPT4",
  GPT_4o = "GPT-4o",
  GPT_4o_MINI = "GPT-4o mini",
  GPT_3_5 = "GPT-3.5",
  CLAUDE_3 = "Claude 3",
  CLAUDE_SONNET = "Claude Sonnet 3.5",
  GOOGLE_GEMINI = "Google Gemini",
  GROK = "Grok",
  Mistral = "Mistral",
}

export enum aiModelNickNameEnums {
  GPT4_NICK_NAME = "Hal",
  GPT_4o_NICK_NAME = "CP30",
  CLAUDE_SONNET_NICK_NAME = "Awesome-O",
}
export enum BlacklistWordsEnum {
  BROAD = "broad",
  EXACT = "exact",
}

export enum PartnerType {
  SELLER = "seller",
  VENDOR = "vendor",
}

export enum EventsEnum {
  BUY_BOX = "buyBox",
  IS_PRIME = "primeBadge",
  PRICE = "price",
  INVENTORY = "inventory",
  BEST_SELLER_BADGE = "bestSellerBadge",
  LIMITED_DEAL_BADGE = "limitedTimeDealBadge",
  AMAZON_CHOICE = "amazonsChoice",
  COUPON_TEXT_BADGE = "couponTextBadge",
  NEGATIVE_REVIEWS = "negativeReviews",
  RATING = "averageStarRating",
  NEGATIVE_REVIEWS_ON_PAGE = "negativeReviewsOnPage",
  VARIANTS = "variants",
  COMPETITORS_PRICE = "competitorPrice",
  STANDARD_DELIVERY_DAYS = "standardDeliveryDays",
  FASTEST_DELIVERY_DAYS = "fastestDeliveryDays",
}

export enum EventsBadgesEnum {
  Badging = "Badging",
  Feedback = "Feedback",
  Buy_Box = "Buy Box",
  Pricing = "Pricing",
  Competitor_Pricing = "Competitor Price",
  Availability = "Availability",
  Fulfillment = "Fulfillment",
  Variation = "Variation",
  Amazon_Choice = "Amazon's Choice",
  IS_PRIME= "Prime Badge"
}

export enum TitleVariationsEnum {
  ORIGINAL = 'original',
  AMAZON_RECOMMENDED = 'amazon_recommended',
  AUTOMATO_RECOMMENDED = 'automato_recommended',
  CUSTOM = 'custom',
  VARIATION_1 = 'variation_1',
  VARIATION_2 = 'variation_2',
}


export enum UniqueVariantThemeEnum {
  NONE = 'none',
  ALL = 'all',
  STYLE = 'style',
  COLOR = 'color',
  SIZE = 'size',
  TEAM_NAME = 'team name',
  STYLE_COLOR = 'style-color',
}

export const UniqueVariantThemeEnumObject:any = {
  NONE : 'none',
  ALL : 'all',
  STYLE : 'style',
  COLOR : 'color',
  SIZE : 'size',
  TEAM : 'team name',
  'STYLE-COLOR' : 'style-color',
}