import * as yup from "yup";

export interface authorizedAccountNameAndVendorCodeObject {
  name: string;
  vendorCode?: string;
}

export const authorizedAccountInputNames = {
  name: "name",
  vendorCode: "vendorCode",
};

// for vendor central

export const validationSchemaForVendorCentral = yup.object().shape({
  name: yup.string().trim().required("Name cannot be empty."),
  vendorCode: yup.string().trim().required("Vendor code cannot be empty."),
});

// for seller central
export const initialValueForSellerCentral: authorizedAccountNameAndVendorCodeObject = {
  name: "",
};
export const validationSchemaForSellerCentral = yup.object().shape({
  name: yup.string().trim().required("Name cannot be empty."),
});
