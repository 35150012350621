import axios from "../axios";
import { errorHandler } from "../utils/helpers/apis";
import { UpdateAuthorizedAccountPayload } from "./types/amazonAuthorize";

export const amazonAuthorizeApi = {
  async loginAmazon({ spapi_oauth_code, state, selling_partner_id, partner_type }: any) {
    return axios
      .get<any, any>(`/sellerpartner/login?spapi_oauth_code=${encodeURIComponent(spapi_oauth_code)}&state=${encodeURIComponent(state)}&selling_partner_id=${encodeURIComponent(selling_partner_id)}&partner_type=${encodeURIComponent(partner_type)}`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  // this is only for showing consoles to backend
  async logAmazon(body: any) {
    return axios
      .post<any, any>(`/sellerpartner/log`, body)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async getAuthorizedAccounts() {
    return axios
      .get<any, any>(`/sellerpartner/authorizations`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async getPublishDetail({userId,asin}:any) {
    return axios
      .get<any, any>(`/sellerpartner/publishdetail?userid=${userId}&asin=${asin}`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async updateAuthorizedAccount(body: UpdateAuthorizedAccountPayload) {
    return axios
      .post<any, any>(`/sellerpartner/authorizations`, body)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async bulkPublish(body: any) {
    return axios
      .put<any, any>(`/sellerpartner/bulkpublish`, { jobs:body })
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async bulkApprove(body: any) {
    return axios
      .put<any, any>(`/sellerpartner/bulkapprove`, { jobs:body })
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  
};
