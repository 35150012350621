import { capitalizeEachLetter } from "../../../utils/helpers/strings";
// import pimIcon from "../../../assets/svgs/settingsIcons/pim-icon.svg"
import amazonConnections from "../../../assets/svgs/settingsIcons/amazon-connections.svg"
import { SettingsEnum } from "../../../utils/constants/enums";

export const thirdPartyIntegrationOptions = [
    // only adding one for now 
    // {
    //     title: capitalizeEachLetter(SettingsEnum.PIM),
    //     description: "PIM (Product Information Management systems) centralize and streamline product data across channels, ensuring accuracy and consistency.",
    //     icon: pimIcon,
    //     name: SettingsEnum.PIM,
    // },
    {
        title: capitalizeEachLetter(SettingsEnum.AMAZON_CONNECTIONS.split("-").join(" ")),
        description: "Grant AutoMato access to your Amazon Seller or Vendor account to manage product listings, sync data, and enhance your marketplace performance.",
        icon: amazonConnections,
        name: SettingsEnum.AMAZON_CONNECTIONS,
    },
]