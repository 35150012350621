import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Divider } from "@mui/material";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import MButton from "../../../Atoms/MButton";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import classes from "./index.module.css";
import Dialog from "../../../Atoms/Dialog";
import { useDialog } from "../../../Atoms/Dialog/useDialog";
import FormTextField from "../../../Molecules/FormTextField";
import { generateProductApis } from "../../../../apis/generate-product";
import { errorHandler } from "../../../../utils/helpers/apis";
import { navigatePage } from "../../../../utils/helpers/common";
import { URL_PRODUCT_CATALOG } from "../../../../routes/routes-path";
import { CatalogEnum } from "../../../../utils/constants/enums";
import { extractASIN } from "../../../../utils/helpers/general-helpers";

interface LinkAsinProps {
  refetchProduct: React.Dispatch<React.SetStateAction<boolean>>;
  asin: string;
  userID: string;
}

interface LinkAsinForm {
  productAsin: string;
}

const initialValue: LinkAsinForm = {
  productAsin: "",
};

export const validationSchema = yup.object().shape({
  productAsin: yup
    .string()
    .trim()
    .required("Product identifier is required")
    .test("is-asin-or-link", "Enter a valid Amazon ASIN or a proper Amazon link with ASIN", (value) => {
      // Check if it's a valid Amazon ASIN
      const isASIN = /^[A-Za-z0-9]{10}$/.test(value);

      // Check if it's a valid Amazon link with ASIN
      const isAmazonLink = /(?:dp|gp\/product|exec\/obidos\/ASIN)\/(\w{10})/.test(value);

      return isASIN || isAmazonLink;
    }),
});

const LinkAsin: React.FC<LinkAsinProps> = (props) => {
  const { refetchProduct, asin, userID } = props;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isDesktop } = useDeviceType();
  const { onClose, isOpen, onOpen } = useDialog();

  const submitForm = (values: LinkAsinForm) => {
    setIsLoading(true);
    const productAsin = extractASIN(values.productAsin);
    generateProductApis
      .linkAsinToProduct({ newAsin: productAsin, oldAsin: asin, userID })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
          refetchProduct(true);
          navigatePage(`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${productAsin}/${userID}`, navigate);
          onClose();
        }, 1000);
      })
      .catch((e) => {
        toast.error(errorHandler(e));
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog title={"Link ASIN"} onClose={onClose} isOpen={isOpen}>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form autoComplete="off">
              <div>
                <div className={"d-flex flex-column w-100 p-4"}>
                  <FormTextField
                    name={"productAsin"}
                    label={"Enter ASIN / Paste Product Link"}
                    placeholder={"Enter ASIN / Paste Product Link"}
                  />
                </div>
                <Divider />
                <div className={"d-flex p-4 justify-content-end"}>
                  <MButton disabled={isLoading} variant={"text"} onClick={onClose} rootClass={"mr-2"} color={"warning"}>
                    Cancel
                  </MButton>
                  <MButton isLoading={isLoading} type={"button"} onClick={handleSubmit} color={"primary"}>
                    Link
                  </MButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
      <div className={isDesktop ? classes.Container : classes.ContainerMobile}>
        <div className={isDesktop ? "d-flex mr-4" : "d-flex mb-3"}>
          <InfoOutlinedIcon style={{ color: "#0052EA" }} />
          <p className={classes.Text}>
            This product’s content has been generated manually and is not linked to any live Amazon product. To link
            this product’s content to a live Amazon product, click the “Link ASIN” button and paste the product’s Amazon
            link.
          </p>
        </div>
        <div
          className={isDesktop ? `d-flex justify-content-end` : `w-100 d-flex justify-content-end`}
        >
          <MButton size={"small"} variant="contained" onClick={() => onOpen()} rootClass={classes.Button}>
            {"Link ASIN"}
          </MButton>
        </div>
      </div>
    </>
  );
};

export default LinkAsin;
