import React, { FC } from "react";

import MDrawer from "../../../Atoms/MDrawer";
import MTypography from "../../../Atoms/MTypography";

import classes from './PublishHistoryDrawer.module.css'
import KeyboardTabRoundedIcon from "@mui/icons-material/KeyboardTabRounded";
import { Divider, IconButton } from "@mui/material";
import { useDeviceType } from "../../../../hooks/useDeviceType";

import PublishHistoryCard from "./PublishHistoryCard/PublishHistoryCard";
import { ProductHistoryStatus } from "../PublishErrorCard/PublishErrorCard.config";

interface EventsDrawerProps {
  open: boolean;
  onClose: () => void;
  errorHistory: any;
  submittedContent: any;
  errorObject: any;
}

const PublishHistoryDrawer: FC<EventsDrawerProps> = (props) => {
  const { open, onClose,errorHistory } = props;
  const { isDesktop } = useDeviceType();

  return (
    <>
      <MDrawer open={open} onClose={onClose}>
        <div className={classes.DrawerContainer}>
          <div className={`${isDesktop ? classes.TitleBarWithFilterDrawer : classes.TitleBarWithFilterDrawerM}`}>
            <MTypography variant="body1" customClass={classes.ProdcutEventsTitleDrawer}>
              Publish History
            </MTypography>
            <div className={`d-flex align-items-center gap-3 justify-content-between`}>

              <IconButton onClick={onClose}>
                <KeyboardTabRoundedIcon htmlColor="#80828A" />
              </IconButton>
            </div>
          </div>
          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.5)" }} />
          <div className={`${isDesktop ? classes.EventCardsContainer : classes.EventCardsContainerM}`}>
            {errorHistory.map((history:any)=> history?.response?.status === ProductHistoryStatus.ACCEPTED ? <PublishHistoryCard history={history} content={{}} key={history._id} /> :  <>
              {history.response?.issues.length > 0 ? history.response.issues.map((item:any,index:number) => <PublishHistoryCard key={item?.code + index} history={history} content={item}  isError />) : <div></div>}
            </>)}
          </div>
        </div>
      </MDrawer>
    </>
  );
};

export default PublishHistoryDrawer;
