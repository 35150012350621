import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
import MTypography from "../../../../Atoms/MTypography";
import classes from "./index.module.css";
import CardOverlay from "../../../../Atoms/CardOverlay";
import {
  validationSchemaForVendorCentral,
  validationSchemaForSellerCentral,
  authorizedAccountNameAndVendorCodeObject,
  authorizedAccountInputNames,
} from "../../../../mui/dialogebox/AmazonAcountNameAndVendorDialoge/config";
import FormTextField from "../../../../Molecules/FormTextField";
import MButton from "../../../../Atoms/MButton";
import { amazonAuthorizeApi } from "../../../../../apis/amazonAuthorize";
import { SUCC_CONNECTION_DETAILS_UPDATED } from "../../../../../utils/constants/messages/success";
import { errorHandler } from "../../../../../utils/helpers/apis";
import { Link, useNavigate, useParams } from "react-router-dom";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";
import { navigatePage } from "../../../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../../../routes/routes-path";
import { PartnerType, SettingsEnum, TabsEnum } from "../../../../../utils/constants/enums";
import { getAuthorizedAmazonConnectionsListDispath } from "../../../../../store/amazonAuthorize/amazonAuthorize.actions";
import { useSelector } from "react-redux";
import { amazonConnectionsListSelector } from "../../../../../store/amazonAuthorize/amazonAuthorize.selector";
import { UpdateAuthorizedAccountPayload } from "../../../../../apis/types/amazonAuthorize";

const UpdateConnectionDetails = () => {
  const { isDesktop } = useDeviceType();
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connectionID } = params;

  const [selectedConnection, setSelectedConnection] = useState<any>(null);

  const connectionsList = useSelector(amazonConnectionsListSelector);

  const fetchData = async () => {
    try {
      await dispatch(getAuthorizedAmazonConnectionsListDispath());
      const connection = connectionsList?.find((item: any) => item._id === connectionID);
      setSelectedConnection(connection);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData();
  }, [connectionID]);

  const handleBackClick = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AMAZON_CONNECTIONS}`, navigate, {
      replace: true,
    });
  };

  const isVendorCentral = selectedConnection?.partnerType === PartnerType.VENDOR || selectedConnection?.vendorCode;

  const formInitialValues = isVendorCentral
    ? {
        name: selectedConnection?.name ? selectedConnection?.name : "",
        vendorCode: selectedConnection?.vendorCode ? selectedConnection?.vendorCode : "",
      }
    : {
        name: selectedConnection?.name ? selectedConnection?.name : "",
      };

  const validationSchema = isVendorCentral ? validationSchemaForVendorCentral : validationSchemaForSellerCentral;

  const submitForm = async (values: authorizedAccountNameAndVendorCodeObject) => {
    if (connectionID) {
      setLoading(true);
      try {
        let payload: UpdateAuthorizedAccountPayload = {
          id: connectionID,
          name: values.name,
          sellingPartnerID: selectedConnection?.sellingPartnerID,
        };

        if (selectedConnection?.partnerType === PartnerType.VENDOR || selectedConnection?.vendorCode) {
          payload = {
            ...payload,
            vendorCode: values.vendorCode,
          };
        }
        await amazonAuthorizeApi.updateAuthorizedAccount(payload);
        toast.success(SUCC_CONNECTION_DETAILS_UPDATED);
        setLoading(false);
        handleBackClick();
      } catch (error) {
        console.log(error);
        toast.error(errorHandler(error));
        setLoading(false);
      }
    } else {
      toast.error("Selling Partner ID cannot be null");
    }
  };

  return (
    <>
      {/* titles */}
      {isDesktop ? (
        <div className={`d-flex justify-content-between align-items-center ${classes.Container}`}>
          <MTypography variant={"h3"} customClass={classes.Heading}>
            {capitalizeEachLetter("Update Connection")}
          </MTypography>
          <div className={"cursor-pointer"} onClick={handleBackClick}>
            <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
          </div>
        </div>
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex align-items-center justify-content-between"}>
            <ArrowBackIcon onClick={handleBackClick} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>{"account title"}</MTypography>
          </div>
        </div>
      )}
      <div className={`${classes.Container}`}>
        <div className={""}>
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={submitForm}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, dirty }) => (
              <Form
                autoComplete="off"
                className="d-flex flex-column justify-content-between"
                style={{ minHeight: "100%" }}
              >
                <CardOverlay>
                  <div className={isDesktop ? "d-flex flex-column py-1 px-4" : "d-flex flex-column py-1 px-3"}>
                    <FormTextField name={authorizedAccountInputNames.name} label={"Enter name"} />
                    {isVendorCentral && (
                      <>
                        <FormTextField name={authorizedAccountInputNames.vendorCode} label={"Enter Your Vendor Code"} />
                        <Link
                          to={"https://www.automatoai.com/blog/how-to-find-your-amazon-vendor-code"}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <MTypography variant="body2" color="primary" customClass={classes.FindVendorCodeText}>
                            Need help? Learn where to find your Vendor Code?
                          </MTypography>
                        </Link>
                      </>
                    )}
                  </div>
                </CardOverlay>
                <div className="pt-4">
                  <div className={"gap-3 d-flex align-items-center justify-content-end p-1"}>
                    <MButton size={"large"} variant={"contained"} disabled={loading || !dirty} onClick={handleSubmit}>
                      <div className={"d-flex align-items-center gap-1"}>{loading ? "Loading" : "Save"}</div>
                    </MButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateConnectionDetails;
