import React from 'react'

import classes from './PublishStatusCard.module.css'

interface PublishStatusCardProps {
  sku:string;
  onClick:() => void;
  errors: number;
}

const PublishStatusCard:React.FC<PublishStatusCardProps> = ({sku,onClick,errors=0}) => {



  return  <div className={classes.CardContainer}>

    <p className={classes.Title}>SKU</p>
    <p className={classes.SkuTitle}>{sku}: <span className={classes.ErrorText} onClick={onClick}>{errors} Errors</span></p>
  </div>

}

export default PublishStatusCard;