import React from "react";
import classes from "./index.module.css";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import MTypography from "../../Atoms/MTypography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MuiTooltip from "../../Atoms/MuiTooltip";

interface TagsPros {
  type: "Active";
  showInfo?: boolean;
  variant?: "small" | "large";
  text?: string;
}
const AmazonAccountStatus: React.FC<TagsPros> = ({ showInfo = true, text, variant = "large", type }) => {
  const { isDesktop } = useDeviceType();
  let statusType = "Active";
  if (type) statusType = capitalizeEachLetter(type.replace("_", " "));
  const showLightClasses = type === "Active";
  const StatusContent: any = {
    active : { name: "Active", content: "The product content is partially live on Amazon." },
  };

  if (!type) {
    return <div />;
  } else {
    return (
      <div
        className={`${
          variant === "large"
            ? isDesktop
              ? classes.TagContainer
              : classes.TagContainerM
            : isDesktop
            ? classes.TagContainerS
            : classes.TagContainerSM
        } ${classes[statusType.replace(" ", "")]}`}
      >
        <MTypography
          variant={"subtitle2"}
          customClass={`${
            variant === "large"
              ? isDesktop
                ? classes.StatusText
                : classes.StatusTextS
              : isDesktop
              ? classes.StatusTextM
              : classes.StatusTextSM
          } ${showLightClasses && classes.LightStatusColor}`}
        >
          {text || StatusContent.active?.name}
        </MTypography>
        {showInfo && (
          <MuiTooltip arrow={true} content={`${StatusContent[type]?.content}`} placement={"right"}>
            <InfoOutlinedIcon
              fontSize="small"
              className={`d-flex align-items-center justify-content-center cursor-pointer ${
                showLightClasses ? classes.InfoIconLight : classes.InfoIconDark
              } ${(variant === "small" || !isDesktop) && classes.SmallIcon}`}
            />
          </MuiTooltip>
        )}
      </div>
    );
  }
};

export default AmazonAccountStatus;
