import amazonSellerLogo from "../../../../assets/svgs/amazon-seller-central.svg"
import amazonVendorLogo from "../../../../assets/svgs/amazon-vendor-central.svg"
import { PartnerType } from "../../../../utils/constants/enums"


export const cardsList = [
    {
        logo: amazonSellerLogo,
        title: "Seller Central",
        description: "Sell products directly to customers.",
        type: PartnerType.SELLER
    },
    {
        logo: amazonVendorLogo,
        title: "Vendor Central",
        description: "Manage wholesale orders and inventory.",
        type: PartnerType.VENDOR
    }
]

export const cardsListForAmazonConnections = [
    {
        logo: amazonSellerLogo,
        title: "Seller Central",
        description: "Authorize your Amazon Seller Central account to manage your marketplace listings and streamline your operations.",
        type: PartnerType.SELLER
    },
    {
        logo: amazonVendorLogo,
        title: "Vendor Central",
        description: "Authorize your Amazon Vendor Central account to optimize your product catalog and bulk operations.",
        type: PartnerType.VENDOR
    }
]