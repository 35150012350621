import React, { 
  // useContext, 
  useEffect, useState } from "react";
import { Divider } from "@mui/material";

import MTypography from "../../../components/Atoms/MTypography";
import ProductFetchScreen from "../../../components/Molecules/ProductFetchScreen";
import FetchAsinForm from "../../../components/Organisms/FetchAsin/FetchAsinForm";
import CreateProductForm from "../../../components/Organisms/FetchAsin/CreateProductForm";
import FetchProduct from "../../../components/Organisms/FetchProductDetails/productFetch";
import { PagesEnum } from "../../../utils/constants/enums";
import { useDeviceType } from "../../../hooks/useDeviceType";

import classes from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";

import { ScrapeProductSelector } from "../../../store/product/productScrape/productScrape.selectors";

import { resetScrapeProduct } from "../../../store/product/productScrape/productScrape.actions";
import MButton from "../../../components/Atoms/MButton";
import ActionBox from "../../../components/Molecules/ActionBox";
import { ApiRetries } from "../../../utils/helpers/apis";
import { UserApis } from "../../../apis/user";
import { setUserDispatch } from "../../../store/user/user.actions";
import { DbUserSelector, UserRoleSelector } from "../../../store/user/user.selector";
import { disableDontHaveLiveAsinButton } from "../../../utils/helpers/priviligesChecks";
import { setCompanyAction } from "../../../store/company/company.actions";
import { CompanySelector } from "../../../store/company/company.selector";

const FetchAsinPage = (): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const dispatch = useDispatch();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const [productFetch, setProductFetch] = useState<boolean>(false);
  const [isProductAsin, setIsProductAsin] = useState<boolean>(true);
  const currentUser = useSelector(DbUserSelector);
  const currentCompany = useSelector(CompanySelector);
  const role = useSelector(UserRoleSelector);
  const userApiCall = () => {
    UserApis.getUser()
      .then((res: any) => {
        if(res?.companyID){
          dispatch(setCompanyAction(res?.companyID))
        }
        dispatch(setUserDispatch(res));
      })
      .catch((error: any) => console.log("error while fetching user", error));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    resetScrapeProduct(dispatch);
    userApiCall();
    return () => userApiCall();
  }, []);


  const toggleView = () => {
    setIsProductAsin(!isProductAsin);
  };
  const handleBack = () => {
    resetScrapeProduct(dispatch);
    window.history.back();
  };

  const isControlDisabled = currentUser?.companyID
    ? currentCompany?.contentGenerationLimit === currentCompany?.countContentGeneration
    : currentUser?.contentGenerationLimit === currentUser?.countContentGeneration && disableDontHaveLiveAsinButton(role); 

  /* page 1 FetchAsinPage*/
  return (
    <ProductFetchScreen page={PagesEnum.ENTER_ASIN}>
      <div className={`${isDesktop ? classes.Container : classes.ContainerM}`}>
        <FetchAsinForm isVisible={isProductAsin} setProductFetch={setProductFetch} productFetch={productFetch} />
        {!isProductAsin && (
          <div className={"w-100"}>
            <MButton disabled={productFetch} variant={"outlined"} rootClass={"w-100"} onClick={toggleView}>
              Enter amazon product ASIN
            </MButton>
          </div>
        )}
        {scrapeProduct?.productFetch && !scrapeProduct?.isUnAttached && <FetchProduct />}

        <Divider className={"my-5"}>
          <MTypography variant={"subtitle2"} customClass={classes.Or}>
            OR
          </MTypography>
        </Divider>
        {isProductAsin ? (
          <div className={"w-100 mb-4"}>
            <MButton
              disabled={productFetch || isControlDisabled}
              variant={"outlined"}
              rootClass={"w-100"}
              onClick={toggleView}
            >
              Don't have live ASIN?
            </MButton>
          </div>
        ) : (
          <CreateProductForm setProductFetch={setProductFetch} />
        )}

        <ActionBox
          open={Boolean(scrapeProduct?.retryCount)}
          title={(scrapeProduct?.retryCount || 0) > ApiRetries ? "Please Try Again Later" : "Loading"}
          message={
            (scrapeProduct?.retryCount || 0) > ApiRetries
              ? "We're currently experiencing higher-than-normal activity. Please try your request again later. Thank you for your patience and understanding."
              : "Please wait a moment while we process your request. Thank you for your patience."
          }
          actionText={(scrapeProduct?.retryCount || 0) > ApiRetries ? "Back" : ""}
          isDanger={(scrapeProduct?.retryCount || 0) > ApiRetries}
          handleAction={handleBack}
        />
      </div>
    </ProductFetchScreen>
  );
};

export default FetchAsinPage;
