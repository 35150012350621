import React from "react";
import MTypography from "../../components/Atoms/MTypography";
import MButton from "../../components/Atoms/MButton";
import { logoutUser } from "../../apis/auth";
import { useNavigate } from "react-router-dom";
import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { RolesEnum, TabsEnum } from "../../utils/constants/enums";
import classes from "./index.module.css";
import settingIcon from "../../assets/svgs/tabIcons/tab-icon-settings-disabled.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { handleBookMeetingClick, navigatePage } from "../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../routes/routes-path";
import UpgradeCard from "../../components/Molecules/UpgradeCard";
import { toast } from "react-toastify";
import { SUCC_LOG_OUT } from "../../utils/constants/messages/success";
import { CompanySelector } from "../../store/company/company.selector";

import supportIconDisable from "../../assets/svgs/support-disable-icon.svg";

import * as previliges from "../../utils/helpers/priviligesChecks";
const MoreTab = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector((state: AppState) => state.user.role);
  const currentCompany = useSelector(CompanySelector);

  const navigateSettingsPage = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };
  const navigateContactUsPage = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.CONTACT_US}`, navigate);
  };
  const handleLogoutUser = async () => {
    await logoutUser(navigate, dispatch);
    toast.success(SUCC_LOG_OUT);
  };
  return (
    <div className={"d-flex flex-column h-auto p-2 mt-2"}>
      <div className="d-flex justify-content-between ms-2 align-items-center" style={{ marginBottom: "30px" }}>
        <div className={"mx-1"}>
          <MTypography variant={"h5"}>{"More"}</MTypography>
        </div>
      </div>
      {(currentCompany
        ? userRole === RolesEnum.COMPANY_ADMIN && currentCompany?.role === RolesEnum.TRIAL
        : userRole === RolesEnum.USER) && <UpgradeCard onClick={handleBookMeetingClick} />}

      <div>
        <div className={` ${classes.Tab}`} onClick={navigateSettingsPage}>
          <div className={"d-flex align-items-center"}>
            <img src={settingIcon} alt={"Setting Icon"} />
            <MTypography variant={"subtitle2"} customClass={classes.CaptionColor}>
              {capitalizeEachLetter(TabsEnum.SETTINGS.replace("-", " "))}
            </MTypography>
          </div>
        </div>
        {!previliges.userRole.isAdminOrEditorOrInternalOrOwner(userRole) && (
          <div className={` ${classes.Tab}`} onClick={navigateContactUsPage}>
            <div className={"d-flex align-items-center"}>
              <img src={supportIconDisable} alt={"Setting Icon"} />
              <MTypography variant={"subtitle2"} customClass={classes.CaptionColor}>
                {capitalizeEachLetter(TabsEnum.CONTACT_US.replace("-", " "))}
              </MTypography>
            </div>
          </div>
        )}

        <div className={"my-5"}>
          <MButton rootClass={classes.ButtonLogout} onClick={handleLogoutUser} variant={"contained"} fullWidth>
            {"Log Out"}
          </MButton>
        </div>
      </div>
    </div>
  );
};

export default MoreTab;
