import React, { ChangeEvent, useState, DragEvent, useEffect } from "react";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";

import MTextField from "../../../Atoms/MTextField";
import DialogBox from "../../../mui/dialogebox";
import { Box, DialogProps } from "@mui/material";
import classes from "./UploadConfigModal.module.css";
import { toast } from "react-toastify";
import MTypography from "../../../Atoms/MTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from "xlsx";
import {
  checkFileSizeLessThan10Mbs,
  formatUploadFileSize, validJsonFileTypes,
} from "../../../../utils/helpers/common";

type BulkImportBoxProps = DialogProps & {
  open: boolean;
  onClose: () => void;
  handleContinueClick: any;
  setSelectedJsonContent:any;
  selectedAsinsArray: string[];
};

const UploadConfigModal: React.FC<BulkImportBoxProps> = (props) => {
  const { open, onClose, handleContinueClick, setSelectedJsonContent } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableAction, setDisableAction] = useState<boolean>(true);

  const [fileName,setFileName] = useState<string>('')

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      setSelectedFile(null);
    }
  }, [open]);
  useEffect(() => {
    if ( selectedFile !== null) {
      setDisableAction(true);
    } else {
      setDisableAction(false);
    }
  }, [ selectedFile]);

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) onClose(event, "backdropClick");
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const droppedFiles = event.target.files;
    if (droppedFiles && droppedFiles.length > 0) {
      saveFile(droppedFiles[0]);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      saveFile(droppedFiles[0]);
    }
  };
  function fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Handle successful read
      reader.onload = () => resolve(reader.result as ArrayBuffer);

      // Handle errors
      reader.onerror = () => reject(reader.error);

      // Read the file as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  const readFileContent = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result as string);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const saveFile = async (uploadedFile: File) => {

    const file = new File([uploadedFile], `${fileName? fileName+'.json' : uploadedFile.name}`, {
      type: uploadedFile.type,
      lastModified: uploadedFile.lastModified
    });



    const fileType = file.type;
    const isValidSize = checkFileSizeLessThan10Mbs(file?.size);
    const isValidFileType = validJsonFileTypes(fileType);
    if (isValidFileType && isValidSize) {
      setSelectedFile(file);
      setFileName('')
      setLoading(true);
      try {
        const arrayBuffer = await fileToArrayBuffer(file); // Convert file to ArrayBuffer

        const workbook = XLSX.read(arrayBuffer, { type: "buffer" });

        const firstSheetName = workbook.SheetNames[0];

        if (!firstSheetName) {
          throw new Error("No sheets found in the workbook.");
        }

      setLoading(false)
      } catch (e) {
        console.error("Error reading the file:", e);
      }

    } else {
      let errorMsg = "Please attach a JSON file with a max size of 10MB.";
      toast.error(errorMsg, { autoClose: 3000 });
      setSelectedFile(null);
    }
  };

  const handleDeleteClick = () => {
    setSelectedFile(null);
    setSelectedJsonContent({});
  };

  const downloadSampleFile = () => {
    try {
      const fileName = "sample.json";
      const fileUrl = `${window.location.origin}/sample.json`;

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName; // Suggests a file name for the downloaded file
      link.click();

      link.remove();
    } catch (e) {
      console.log("error downloadSampleFile", e);
    }
  };

  const handleSave = async() => {
    handleContinueClick(selectedFile)
    if(selectedFile){
      const fileContent = await readFileContent(selectedFile);
      setSelectedJsonContent(JSON.parse(fileContent))
    }
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <DialogBox
        open={open}
        onClose={onClose}
        cancelButton={{
          action: handleCancel as any,
          element: "Cancel",
        }}
        confirmButton={{
          action: handleSave as any,
          element: <> {"Save"}</>,
          disabled: disableAction ? false : true,
        }}
        title={"Bulk Import"}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "780px !important",
          },
        }}
      >
        <div className={classes.Container}>

          <MTextField onChange={(e:any) =>  setFileName(e.target.value)} name={'fileName'} value={fileName} label={'Enter File Title (Optional)'} placeholder={'Enter File Title (Optional)'} />

          {/* upload box */}
          {selectedFile ? <Box
            component={"div"}
            className={classes.UploadContainerDisable}
          >
            <UploadFileIcon color="primary" />
            <Box component={"div"} py={"10px"} sx={{ fontSize: "16px", lineHeight: "24px" }}>
              <span className={classes.ClickToUpload}>Click to upload</span> or drag and drop
            </Box>
            <Box component={"div"} className={classes.CsvOrXLS}>
              JSON (max. 10MB)
            </Box>
            <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileChange} />
          </Box> : <Box
            component={"div"}
            className={classes.UploadContainer}
            onClick={() => {
              document.getElementById("fileInput")?.click();
            }}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <UploadFileIcon color="primary" />
            <Box component={"div"} py={"10px"} sx={{ fontSize: "16px", lineHeight: "24px" }}>
              <span className={classes.ClickToUpload}>Click to upload</span> or drag and drop
            </Box>
            <Box component={"div"} className={classes.CsvOrXLS}>
              JSON (max. 10MB)
            </Box>
            <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileChange} />
          </Box>}
          {/* samples */}
          <Box component={"div"} className={classes.SampleDownloadContainer}>
            <Box component="div" onClick={downloadSampleFile} className="cursor-pointer">
              <GetAppOutlinedIcon fontSize="small" color="primary" /> Sample File
            </Box>
          </Box>



          {/* file details section */}
          {selectedFile && (
            <Box component={"div"} sx={{ mt: "15px" }}>
              <MTypography variant="h6" customClass={classes.UploadedFile}>
                Uploaded File
              </MTypography>
              <Box component={"div"} className={classes.FileDetailBox}>
                <Box component={"div"}>
                  <UploadFileIcon sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: "16px" }} />
                </Box>
                <Box component={"div"} className={classes.FileDetails}>
                  <Box component={"div"} sx={{ color: "#000000", fontSize: "16px", lineHeight: "24px" }}>
                    {fileName ? fileName : selectedFile?.name}
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ color: "rgba(rgba(0, 0, 0, 0.6)", fontSize: "14px", lineHeight: "20px" }}
                  >
                    {formatUploadFileSize(selectedFile?.size)} {loading ? " . Loading..." : ""}

                  </Box>
                </Box>
                <Box component={"div"} display={"flex"} alignItems={"center"} gap={1}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DeleteIcon
                      sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: "20px", cursor: "pointer" }}
                      onClick={handleDeleteClick}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </div>
      </DialogBox>
    </div>
  );
};

export default UploadConfigModal;
