import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MTypography from "../../../Atoms/MTypography";
import classes from "../index.module.css";
import React from "react";
import { DensitiesList } from "../KeywordDensity";
import { keywordsDensityTitle } from "../../../../utils/constants/common";
import {
  acceptablePercentage,
  acceptableTitleCount,
} from "../../../../utils/helpers/keywordDensityHelper/keywordDensityChecker";
import { SwipeableTabTitlesEnums } from "../../../../utils/constants/enums";
import ShowMoreLess from "../../../Atoms/MShowMoreLess";
import { useDeviceType } from "../../../../hooks/useDeviceType";

interface KeywordsTableProps {
  keywordsDensityList: DensitiesList[];
  onKeywordClick: (phrase: string, highlightClass: string) => void;
  title: string;
}

const KeywordsTable: React.FC<KeywordsTableProps> = (props) => {
  const { keywordsDensityList, onKeywordClick, title } = props;
  const { isMobile } = useDeviceType();

  return (
    <div className={classes.BoxContainer}>
      <MTypography variant="subtitle1" customClass={classes.DensityDetail}>
        <ShowMoreLess
          content={keywordsDensityTitle}
          maxLength={isMobile ? 70 : 100}
          textVariant={"subtitle2"}
          textCustomClass={"pt-1 pr-2 " + classes.DensityDetail}
        />
        {/* {} */}
      </MTypography>
      <div>
        <TableContainer classes={{ root: classes.TableContainer }}>
          <Table stickyHeader sx={{ minWidth: "inherit" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.TableHeading }} align="left">
                  {""}
                </TableCell>
                <TableCell classes={{ root: classes.TableHeading }} align="center">
                  Count
                </TableCell>
                <TableCell classes={{ root: classes.TableHeading }} align="center">
                  Percent
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywordsDensityList?.length ? (
                keywordsDensityList?.map((item, i) => {
                  const isExceed =
                    title === SwipeableTabTitlesEnums.TITLE
                      ? item?.occurrences <= acceptableTitleCount
                      : item?.density <= acceptablePercentage;
                  return (
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={i}>
                      <TableCell
                        onClick={() =>
                          onKeywordClick(
                            item?.word,
                            isExceed ? "KeywordDensityHighlighted" : "KeywordDensityHighlightedRed"
                          )
                        }
                        component="th"
                        scope="row"
                        align="left"
                        classes={{
                          root: `${classes.TableCells} fw-semibold px-0 cursor-pointer ${
                            isExceed ? "primary-fc" : classes.LimitExceedColor
                          }`,
                        }}
                      >
                        {item?.word}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{
                          root: `${classes.TableCells} ${
                            isExceed ? classes.LimitNotExceedColor : classes.LimitExceedColor
                          }`,
                        }}
                        align="center"
                        sx={{ minWidth: "45px" }}
                      >
                        {item?.occurrences}
                      </TableCell>
                      <TableCell
                        classes={{
                          root: `${classes.TableCells} ${
                            isExceed ? classes.LimitNotExceedColor : classes.LimitExceedColor
                          }`,
                        }}
                        align="center"
                        sx={{ minWidth: "45px" }}
                      >
                        {item?.density}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0, height: "100%", paddingTop: "10%" } }}>
                  <TableCell colSpan={7} align="center" sx={{ verticalAlign: "center", borderBottom: "none" }}>
                    No results found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default KeywordsTable;
