import React, { useState } from "react";

import classes from "./index.module.css";
import MTypography from "../../Atoms/MTypography";
import {
  ContentStatusEnum,
  ProductDetailsEditableKeys,
  ProductLiveStatusEnum,
  RolesEnum,
} from "../../../utils/constants/enums";
import ProductStatus from "../ProductStatus";
import EditNoteOutlinedIcon from "../../../assets/svgs/edit-icon-active.svg";
import EditedIconDisabled from "../../../assets/svgs/editedIcon.svg";
import MButton from "../../Atoms/MButton";
import { useDispatch, useSelector } from "react-redux";
import { CompleteProductSelector } from "../../../store/product/productDetails/productDetails.selectors";
import {
  completeProductDispatch,
  handleEditModeForDetailPageAction,
  saveEditedValueForDetailPageAction,
} from "../../../store/product/productDetails/productDetails.actions";
import { allBulletPointsLength } from "../../Organisms/ProductDetails/ProductDetailsSwipeableTabs/ProductDetailsTab/config";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { toast } from "react-toastify";
import { errorHandler } from "../../../utils/helpers/apis";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  checkIsTrailCompany,
  checkIsUser,
  showDensityOnDetailsPage,
  showDensityOnDetailsPageCompany,
} from "../../../utils/helpers/priviligesChecks";
import { DbUserSelector } from "../../../store/user/user.selector";
import KeywordDensity from "../keywordDensity/KeywordDensity";
import { Box } from "@mui/material";
import { CompanySelector } from "../../../store/company/company.selector";
import ToastError from "../../Atoms/ToastError";
import { UnlockGreaterPossibilities } from "../../../utils/constants/messages/errors";
import { useDeviceType } from "../../../hooks/useDeviceType";
import ProductDetailsTabCardMenuM from "../ProductDetailsTabCardMenuM";

interface BulletPointsProps {
  children: React.ReactNode;
  heading: string;
  isLong: boolean;
  body: string;
  isLive: boolean;
  liveProperty: boolean;
  showEditButton?: boolean;
  editableKey?: ProductDetailsEditableKeys;
}

const BulletPointsWrapper: React.FC<BulletPointsProps> = (props) => {
  const product = useSelector(CompleteProductSelector);
  const currentUser = useSelector(DbUserSelector);
  const productStatus = product?.status;
  const dispatch = useDispatch();
  const [showChanges, setShowChanges] = useState<boolean>(false);
  const { isDesktop } = useDeviceType();

  const [menuState, setMenuState] = useState<any>({ openPopper: false, anchorEl: null });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentCompany = useSelector(CompanySelector);
  const [showPricingPlan] = React.useState<boolean>(() =>
    currentUser?.companyID ? checkIsTrailCompany(currentCompany?.role) : checkIsUser(currentUser?.role)
  );

  const { editableKey, heading, isLong = false, body, isLive, liveProperty, showEditButton } = props;
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    dispatch(completeProductDispatch({ ...product, showBulletPointChanges: showChanges }));
  }, [showChanges]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (product && product.status === ContentStatusEnum.APPROVED) {
      setShowChanges(false);
    }
  }, [productStatus]);

  let editMode: boolean = false;
  if (editableKey) {
    editMode = product[`${editableKey}EditMode`] as boolean;
  }
  const disableEditMode = () => {
    if (editableKey) dispatch(handleEditModeForDetailPageAction(product, editableKey, false));
  };
  const enableEditMode = () => {
    if (editableKey) dispatch(handleEditModeForDetailPageAction(product, editableKey, true));
  };

  const updateData = () => {
    if (editableKey) {
      setIsLoading(true);
      dispatch(saveEditedValueForDetailPageAction(product, editableKey))
        .catch((e: any) => {
          toast.error(errorHandler(e));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const showBulletPointsChanges = () => {
    setShowChanges(!showChanges);
  };

  const showUpgradeMessage = () => {
    if (currentUser?.companyID)
      if (currentUser?.role !== RolesEnum.COMPANY_ADMIN) {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
      }
  };

  const handleEditClick = () => {
    showPricingPlan ? showUpgradeMessage() : enableEditMode();
  };

  const handleShowEditsClick = () => {
    showPricingPlan ? showUpgradeMessage() : showBulletPointsChanges();
  };

  return (
    <div>
      <div className={`d-flex justify-content-between align-items-center ${classes.HeaderButtonContainer}`}>
        <div className={"d-flex"}>
          {isLive && (
            <div className={"px-2 d-flex align-items-center"}>
              <ProductStatus
                type={liveProperty ? ProductLiveStatusEnum.LIVE : ProductLiveStatusEnum.INACTIVE}
                showInfo={false}
                variant={"small"}
              />
            </div>
          )}
          <Box component={"div"} className="d-flex align-items-center" gap={{ xs: "8px", md: "16px" }}>
            <MTypography variant={"subtitle1"} customClass={`${classes.Heading} ${editMode && "my-2"}`}>
              {heading}
            </MTypography>
            {(currentUser?.companyID ? (
              showDensityOnDetailsPageCompany(currentCompany?.role)
            ) : showDensityOnDetailsPage(currentUser?.role)) &&
              product?.status !== ContentStatusEnum.ERROR &&
              !editMode && (
              <KeywordDensity title={heading} />)}
          </Box>
        </div>

        <div className={"d-flex align-items-center gap-3"}>
          <div>
            {!editMode ? (
              <div className={"d-flex align-items-center gap-3"}>
                <>
                  {isDesktop ? (
                    <>
                      {product?.status !== ContentStatusEnum.DRAFT && product?.status !== ContentStatusEnum.ERROR && (
                        <div
                          className={`d-flex align-items-center cursor-pointer HoverEffectForButton`}
                          // onClick={() => (showPricingPlan ? showUpgradeMessage() : showBulletPointsChanges())}
                          onClick={handleShowEditsClick}
                        >
                          {showChanges ? (
                            <CheckBoxIcon className={`${classes.CopyIcon} ${classes.ShowEditsIcon}`} />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon
                              className={`${classes.CopyIcon} ${classes.ShowEditsIcon}`}
                            />
                          )}

                          <div>
                            <MTypography
                              variant={"subtitle2"}
                              customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}
                            >
                              {"Show Edits"}
                            </MTypography>
                          </div>
                        </div>
                      )}

                      {showEditButton &&
                        product?.status !== ContentStatusEnum.APPROVED &&
                        product?.status !== ContentStatusEnum.PUBLISHED &&
                        product?.status !== ContentStatusEnum.ERROR &&
                        !showChanges && (
                          <div
                            className={`d-flex align-items-center cursor-pointer HoverEffectForButton ${
                              showPricingPlan ? "pe-none" : "pe-auto"
                            }`}
                            onClick={handleEditClick}
                          >
                            <img
                              src={showPricingPlan ? EditedIconDisabled : EditNoteOutlinedIcon}
                              alt={"alt-icon"}
                              className={classes.CopyIcon}
                            />
                            <div>
                              <MTypography
                                variant={"subtitle2"}
                                customClass={`${classes.Limit} ${classes.ShowEditsIcon} ${
                                  showPricingPlan ? classes.DisabledText : ""
                                }`}
                              >
                                {"Edit"}
                              </MTypography>
                            </div>
                          </div>
                        )}
                      <div className={""}>
                        <MTypography
                          variant={"subtitle2"}
                          customClass={`${classes.Limit} ${isLong && classes.LimitExceed}`}
                        >{`${body?.length || 0}/${allBulletPointsLength}`}</MTypography>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={""}>
                        <MTypography
                          variant={"subtitle2"}
                          customClass={`${classes.Limit} ${isLong && classes.LimitExceed}`}
                        >{`${body?.length || 0}/${allBulletPointsLength}`}</MTypography>
                      </div>
                      <ProductDetailsTabCardMenuM
                        setMenuState={setMenuState}
                        menuState={menuState}
                        handleEditClick={handleEditClick}
                        handleShowEditsClick={handleShowEditsClick}
                        isShowEditOptionVisible={
                          product?.status !== ContentStatusEnum.DRAFT && product?.status !== ContentStatusEnum.ERROR
                        }
                        isEditOptionVisible={
                          showEditButton &&
                          product?.status !== ContentStatusEnum.APPROVED &&
                          product?.status !== ContentStatusEnum.ERROR &&
                          !showChanges
                        }
                        isCopyOptionVisible={false}
                        showChanges={showChanges}
                        isBulletPointsWrapper={true}
                      />
                    </>
                  )}
                </>
              </div>
            ) : (
              <div className={"d-flex align-items-center gap-3"}>
                <div onClick={disableEditMode}>
                  <MTypography variant={"subtitle2"} customClass={`${classes.Limit} cursor-pointer`}>
                    {"Cancel"}
                  </MTypography>
                </div>

                <MButton size={"small"} onClick={updateData} isLoading={isLoading}>
                  {"Save"}
                </MButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${classes.BodyContainer} ${isLive && !liveProperty && classes.InActive} ${
          editMode ? classes.EditBorder : classes.TransparentBorder
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default BulletPointsWrapper;
