import React, { useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Tooltip} from "@mui/material";

import classes from './CheckboxFilters.module.css'
import { Divider } from "@mui/material";
import MButton from "../../../../../Atoms/MButton";
import CloseIcon from '@mui/icons-material/Close';


export const bsrPerformanceCheckBoxValues = {
  bsr:'bsr',
  conversationRate:"conversionRate",
  traffic:"sessions",
  unitsSold:"unitsSold",
}

const checkBoxes: any = [
  { name: "BSR", value: bsrPerformanceCheckBoxValues.bsr, color: "#D87B00",color1:'#F94144' },
  { name: "Conversion Rate %", value: bsrPerformanceCheckBoxValues.conversationRate, color: "#4285F4",color1:'#90BE6D' },
];

interface CheckboxFiltersProps {
  setCheckbox: any;
  selected: string
}

const CheckboxFilters: React.FC<CheckboxFiltersProps> = ({ setCheckbox, selected }) => {
  // const [selected, setSelected] = useState<string[]>([]);

  const [showTooltip,setShowTooltip] = React.useState(false);
  // const handleCheckboxes = (value: string):any => {
  //   if(value === bsrPerformanceCheckBoxValues.bsr)
  //     return false
  //   if (isChecked(value)) {
  //     const filteredCheckboxes = selected.filter((item: string) => item !== value);
  //     setCheckbox(filteredCheckboxes);
  //   } else {
  //     if(selected.length >=2){
  //       return false
  //     }
  //     setCheckbox([...selected, value]);
  //   }
  // };
  console.log(selected,setCheckbox);
  useEffect(() => {
    setShowTooltip(true)
  }, []);

  const vsTooltip = (
    <div>
      <div className={'d-flex justify-content-between'}>
        <p className={classes.TooltipTitle}>Update Alert</p>
        <CloseIcon className={classes.CloseIcon} onClick={() => setShowTooltip(false)} />
      </div>
      <p className={classes.TooltipSubTitle}> We've updated the chart layout. </p>
      <ul>
        <li className={classes.TooltipLi}>BSR is now on the right y-axis.</li>
        <li className={classes.TooltipLi}>Units Sold is now on the left y-axis.</li>
      </ul>
      <Divider />
      <div className={"d-flex w-100 justify-content-end pt-2"}>
        <MButton onClick={() => setShowTooltip(false)} size={"small"}>
          Got it
        </MButton>
      </div>
    </div>
  );


  return (
    <div className={"d-flex gap-1 my-1"}>
      <RadioGroup row className={"d-flex"} onChange={(e: any) => {
        setCheckbox(e.target.value)
        setShowTooltip(false)
      }} value={selected}>
        {checkBoxes.map((checkbox: any) => (
          <FormControlLabel
            key={checkbox.name}
            value={checkbox.value}
            control={<Radio />}
            label={
              <Tooltip    componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'white',
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "18px",
                    padding: "0.75rem",
                    color: " #000000",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  },
                },
              }}    open={checkbox.name === 'BSR' ? showTooltip: false} onMouseEnter={() => {}}   title={vsTooltip} placement={'bottom-start'} >
              <div className={"d-flex align-items-center gap-2"}>
                <div
                  className={classes.Dot}
                  style={{
                    backgroundColor: checkbox.color,
                    opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1,
                  }}
                ></div>
                <div
                  className={classes.Text}
                  style={{ opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}
                >
                  {checkbox.value === bsrPerformanceCheckBoxValues.bsr ? <div className={'d-flex gap-1'}>
                    Units Sold vs
                  </div> : 'Traffic vs'}
                </div>

                <div
                  className={classes.Dot}
                  style={{
                    backgroundColor: checkbox.color1,
                    opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1,
                  }}
                ></div>
                <div
                  className={classes.Text}
                  style={{ opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}
                >
                  {checkbox.value === bsrPerformanceCheckBoxValues.bsr ? 'BSR' : 'Conversation Rate'}
                </div>
              </div>
              </Tooltip>
            }
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default CheckboxFilters;
