import * as yup from "yup";

export interface CreateProductObject {
  brand: string;
  title: string;
  productContent: string;
}

export const inputName = {
  brand: "brand",
  title: "title",
  productContent: "productContent",
};

export const initialValue: CreateProductObject = {
  brand: "",
  title: "",
  productContent: "",
};

export const validationSchema = yup.object().shape({
  brand: yup.string().trim().notRequired(),
  title: yup.string().trim().required("Title is required."),
  productContent: yup.string().trim().required("Product Content is required."),
});
