import React, { 
  // useContext, 
  useState 
} from "react";
import { useNavigate } from "react-router-dom";
import {
  PRIORITISE_KEYWORDS,
  URL_LANDING_PAGE,
  URL_PRODUCT_FETCHED,
  URL_PRODUCT_FETCHED_COMPETITORS,
} from "../../../../routes/routes-path";

import { ContentStatusEnum, CountryDomainEnum, ProductFetchType, TabsEnum } from "../../../../utils/constants/enums";
import { navigatePage } from "../../../../utils/helpers/common";
import MButton from "../../../Atoms/MButton";
import FetchProductDetails from "../index";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import Skeleton from "@mui/material/Skeleton";
import classes from "./index.module.css";
import { useSelector } from "react-redux";
import MTypography from "../../../Atoms/MTypography";
import {
  RainforestProductSelector,
  ScrapeProductSelector,
} from "../../../../store/product/productScrape/productScrape.selectors";
import { toast } from "react-toastify";
import { ProductApis } from "../../../../apis/product";
import { errorHandler } from "../../../../utils/helpers/apis";
import KeywordsPageDialoge from "../../../mui/dialogebox/keywordsPageDialoge";
import { generatingProcessTimeTenToFifteen } from "../../../../utils/constants/common";
import ActionBox from "../../../Molecules/ActionBox";
import { checkIsCompanyReachedHeliumOrContentRegenLimit, checkIsProductOwnerReachedHisLimit } from "../../../../pages/SavedProducts/config";
import { DbUserSelector } from "../../../../store/user/user.selector";
import { UnlockGreaterPossibilities } from "../../../../utils/constants/messages/errors";
import ToastError from "../../../Atoms/ToastError";
// import PricingContext from "../../../../context/PricingContext";

export interface FetchProductState {
  openErrorBox: boolean;
  errorMessage: string;
}

const FetchProduct: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const { isDesktop, isIpad, isMobile } = useDeviceType();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const rainforestProduct = useSelector(RainforestProductSelector);
  
  const dbUser = useSelector(DbUserSelector);
  // const { onOpen } = useContext(PricingContext);

  const [state, setState] = useState<FetchProductState>({
    openErrorBox: false,
    errorMessage: "",
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeDialogBox = () => {
    setIsOpen(false);
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, { replace: true });
  };
  /* page 3 FetchProduct // handle Next button action */

  const showNextPage = async (): Promise<any> => {
    const scrapeProductAsin = scrapeProduct?.productASIN ? scrapeProduct?.productASIN : scrapeProduct?.product?.asin;
    if (scrapeProduct?.fetchType === ProductFetchType.GOGO) {
      if (scrapeProduct?.productStatus === ContentStatusEnum.GENERATING && scrapeProduct?.productAlreadyExistWithCurrentUser) {
        setState({
          ...state,
          openErrorBox: true,
          errorMessage: "Product is already in generating state",
        });
      } else {
        try {
          ProductApis.gogoAutomatoProductGeneration({
            domain: scrapeProduct.domain as CountryDomainEnum,
            asin: scrapeProduct?.product?.asin || "",
          })
            .then(() => {
              setIsOpen(true);
            })
            .catch((e: any) => {
              throw new Error(e);
            });
        } catch (e: any) {
          toast.error(errorHandler(e));
          setState({
            ...state,
            openErrorBox: true,
            errorMessage: e,
          });
        }
      }
    } else if (rainforestProduct?.productStatus === ContentStatusEnum.GENERATING) {
      setState({
        ...state,
        openErrorBox: true,
        errorMessage: "Product is already in generating state",
      });
      return new Promise(() => { });
    } else if (
      scrapeProduct.productAlreadyExistWithDifferentDomain &&
      scrapeProduct.showCompetitors
    ) {
      // this condition is added for changing competitors for domain changed product
      navigatePage(
        `${URL_PRODUCT_FETCHED}/${scrapeProduct?.domain?.split(".")[1] ?? "com"}/${scrapeProductAsin
        }${URL_PRODUCT_FETCHED_COMPETITORS}`,
        navigate
      );
    } else if (
      rainforestProduct.productAlreadyExistWithCurrentUser &&
      rainforestProduct?.selectedCompetitors.length > 0 &&
      rainforestProduct?.productStatus !== ContentStatusEnum.ERROR
    ) {
      navigatePage(
        `${URL_PRODUCT_FETCHED}/${scrapeProduct?.domain?.split(".")[1] ?? "com"}/${scrapeProductAsin
        }${PRIORITISE_KEYWORDS}`,
        navigate
      );
    } else if (
      scrapeProduct.showCompetitors ||
      rainforestProduct?.selectedCompetitors.length === 0 ||
      (rainforestProduct?.productStatus === null && rainforestProduct?.selectedCompetitors.length > 0) ||
      rainforestProduct?.productStatus === ContentStatusEnum.ERROR
    ) {
      navigatePage(
        `${URL_PRODUCT_FETCHED}/${scrapeProduct?.domain?.split(".")[1] ?? "com"}/${scrapeProductAsin
        }${URL_PRODUCT_FETCHED_COMPETITORS}`,
        navigate
      );
    } else {
      navigatePage(
        `${URL_PRODUCT_FETCHED}/${scrapeProduct?.domain?.split(".")[1] ?? "com"}/${scrapeProductAsin
        }${PRIORITISE_KEYWORDS}`,
        navigate
      );
    }
  };
  const isProductAsin = scrapeProduct?.product?.asin || scrapeProduct?.productASIN;

  const handleMoveToNextPage = async () => {
    if (scrapeProduct?.productAlreadyExistWithCurrentUser) {      
      // await checkIsProductOwnerReachedHisLimit(dbUser?._id)
      const isLimitReached = dbUser?.companyID
      ? await checkIsCompanyReachedHeliumOrContentRegenLimit(dbUser?.companyID)
      : await checkIsProductOwnerReachedHisLimit(dbUser?._id);
      if (isLimitReached) {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
        // onOpen();
      } else {
        showNextPage()
      }
    }else{
      showNextPage()
    }

  }

  return (
    <div>
      <KeywordsPageDialoge open={isOpen} onClose={closeDialogBox} title={generatingProcessTimeTenToFifteen} />
      <div>
        <div
          style={{
            padding: isDesktop || isIpad ? "1.828rem 0rem 2rem" : "2.38rem 0rem 1.25rem",
          }}
        >
          <div className={"d-flex"}>
            {!scrapeProduct?.productFetch ? (
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  padding: isDesktop || isIpad ? "0.5rem" : "0px",
                }}
              >
                <Skeleton variant={"rectangular"} height={350} />
              </div>
            ) : (
              <div className={isDesktop ? "w-100" : ""}>
                {isProductAsin ? (
                  <FetchProductDetails />
                ) : (
                  <div className={"d-flex align-items-center justify-content-center p-5"}>
                    <MTypography variant={"body1"}>{"No data found"}</MTypography>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <>
            <div className={"d-flex flex-column w-100"}>
              <MButton
                onClick={handleMoveToNextPage}
                // onClick={showNextPage}
                width={"large"}
                size={"medium"}
                variant={"contained"}
                rootClass={"comp-button-mb"}
                disabled={!scrapeProduct?.competitorsFetch && scrapeProduct?.fetchType === ProductFetchType.STANDARD}
              >
                {scrapeProduct?.fetchType === ProductFetchType.GOGO
                  ? "Continue"
                  : !scrapeProduct?.competitorsFetch
                  ? "Loading Competitors"
                  : "Next"}
              </MButton>

              {scrapeProduct?.productFetch && (
                <p className={classes.ButtonCaptionMobile}>
                  {!scrapeProduct?.competitorsFetch && scrapeProduct?.fetchType !== ProductFetchType.GOGO
                    ? "This may take 30-45 seconds"
                    : ""}
                </p>
              )}
            </div>
          </>
        )}
        {isProductAsin && (
          <div
            className={isDesktop || isIpad ? "d-flex flex-column align-items-end" : "d-none"}
            style={{ padding: isDesktop || isIpad ? "1rem 0rem" : "" }}
          >
            <MButton
              disabled={!scrapeProduct?.competitorsFetch && scrapeProduct?.fetchType === ProductFetchType.STANDARD}
              onClick={handleMoveToNextPage}
              fullWidth={true}
              width={"large"}
            >
              {scrapeProduct?.fetchType === ProductFetchType.GOGO
                ? "Continue"
                : !scrapeProduct?.competitorsFetch
                ? "Loading Competitors"
                : "Next"}
            </MButton>
            <div>
              <p className={classes.ButtonCaption}>
                {!scrapeProduct?.competitorsFetch && scrapeProduct?.fetchType !== ProductFetchType.GOGO
                  ? "This may take 30-45 seconds"
                  : ""}
              </p>
            </div>
          </div>
        )}

        <ActionBox
          handleAction={() => {
              setState({ ...state, openErrorBox: false });
              navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, {
                replace: true,
              });
            }}
          // handleBack={() => {}}
          open={state.openErrorBox}
          actionText={"Ok"}
          message={state.errorMessage}
          title="Backend Response "
        // backText={"Cancel"}
        />
      </div>
    </div>
  );
};

export default FetchProduct;
