import React from 'react'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import classes from "../PublishErrorCard.module.css";


import Alert from "@mui/material/Alert";

interface PublishErrorAlertProps{
  title?:string;
  isAdditionalSupport?:boolean;
  code?:string;
  message?:string;
  sku?:string;
}



const PublishErrorAlert:React.FC<PublishErrorAlertProps> = ({sku='',isAdditionalSupport=false,code,message}) => {




  const openAmazonErrors = () => {
    window.open('https://www.automatoai.com/docs/amazonpublisherrors','_blank');
  }

  return <Alert icon={sku === '' ? <ErrorOutlineOutlinedIcon style={{width: '18px'}} /> : false} severity="error" sx={{
    'root': {
      backgroundColor: 'rgb(102, 36, 36) !important',
      color: 'rgb(102, 36, 36) !important',
    }
  }}>
    {sku && <p className={classes.SkuText}>{sku}</p>}
    <p className={classes.ErrorTitle}><strong>Error {code}</strong>, {message}</p>
    {isAdditionalSupport &&
      <p className={classes.ErrorSubtitle}>For additional support and information, please click <span
        onClick={openAmazonErrors} className={classes.AmazonErrorButton}>Amazon Publish Errors</span></p>}

  </Alert>
}

export default PublishErrorAlert