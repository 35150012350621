import { useDeviceType } from "../../../../../hooks/useDeviceType";
import fileIcon from "../../../../../assets/svgs/accessKeysIcons/face-file-icon.svg"
import MTypography from "../../../../Atoms/MTypography";
import MButton from "../../../../Atoms/MButton";
import classes from './index.module.css'
import React from "react";

interface NoApiAvailableViewProps {
    handleClick: () => void
}

const NoApiAvailableView: React.FC<NoApiAvailableViewProps> = (props) => {
    const { handleClick } = props;
    const { isDesktop } = useDeviceType();

    return (
        <div className={`mw-100 mh-100 d-flex justify-content-center align-items-center flex-column text-center ${isDesktop ? 'mt-5' : 'mt-5 px-3'}`}>
            <div>
                <img src={fileIcon} alt="file-icon" style={isDesktop ? { width: '100%', marginTop: '2rem' } : { width: '70%' }} />
            </div>

            <MTypography variant="subtitle2" customClass={classes.Title}>
                Currently, there are no APIs associated with your account.
            </MTypography>

            <MTypography variant="subtitle1" customClass={classes.Description}>
                By clicking the 'Generate New API Key' button, you can create a new API key if needed.
            </MTypography>

            <div className={classes.ButtonContainer}>
                <MButton variant="contained" fullWidth onClick={handleClick} rootClass={"d-none"}>
                    Generate new Key
                </MButton>
            </div>
        </div>
    )
}

export default NoApiAvailableView;