import React, { useState } from "react";

import TimerIcon from "@mui/icons-material/TimerOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CardOverlay from "../../../Atoms/CardOverlay";
import MTypography from "../../../Atoms/MTypography";
import MuiTooltip from "../../../Atoms/MuiTooltip";

import classes from "./PublishErrorCard.module.css";
import PublishErrorAlert from "./PublishErrorAlert/PublishErrorAlert";
import PublishHistoryDrawer from "../PublishHistoryDrawer/PublishHistoryDrawer";
import ActionBox from "../../../Molecules/ActionBox";
import {
  extractAndCapitalizeLastWords,
  formateDateForHistory,
  ProductHistoryStatus,
  timeAgo,
} from "./PublishErrorCard.config";
import PublishStatusCard from "./PublishStatusCard/PublishStatusCard";

interface PublishErrorCardProps{
  sellerPartner:any
}

const PublishErrorCard:React.FC<PublishErrorCardProps> = ({sellerPartner}) => {

  const [openHistory,setOpenHistory] = useState<boolean>(false);
  const [openModal,setOpenModal] = useState<boolean>(true);


  let errorObject = {}

  if(sellerPartner?.history && sellerPartner?.history[0]?.payload !== null){
    errorObject = sellerPartner?.history[0]?.response?.issues[0]
  }

  const histories = sellerPartner?.history
  const errorHistory = sellerPartner?.history[0]
  const submittedContent = extractAndCapitalizeLastWords(errorHistory?.payload?.patches)

  console.log('test pick histories', histories)

  const handleOpenHistory = () => {

    setOpenHistory(true)
  }

  const openAmazonErrors = () => {
    window.open('https://www.automatoai.com/docs/amazonpublisherrors','_blank');
  }

  const handleCloseHistory = () => setOpenHistory(false)

  const tooltipComponent = (
    <div>
      <MTypography variant={"body2"} customClass={`${classes.TooltipText} mb-2`}>
        Time passed since your most recent publish attempt.
      </MTypography>
      <MTypography variant={"body2"} customClass={classes.TooltipText}>
        <span className={classes.Note}>Note:</span> Amazon recommends waiting 48 hours before republishing
      </MTypography>
    </div>
  );

  return (
  <>
    <CardOverlay>
      <div className={'px-4'}>
        <div className={'d-flex justify-content-between'}>
          <MTypography variant={"body2"} customClass={classes.PublishContentText} >
            Publish Content Metrics
          </MTypography>
          <div className={'d-flex gap-1 align-items-center cursor-pointer'} onClick={handleOpenHistory}>
            <TimerIcon style={{
              fontSize: 16,
              color: '#E6B540'
            }} />
            <MTypography variant={"body2"} customClass={classes.PublishHistoryText} >
              Publish History
            </MTypography>
          </div>
        </div>

        <div className={'d-flex gap-5 mt-2 mb-4'}>

          <div className={'d-flex flex-column gap-1 ' + classes.SubmittedText}>
            <p className={classes.ContentLightTitle}>Submitted Content
              <MuiTooltip arrow content={"The content elements you attempted to publish."} placement="top">
                <InfoOutlinedIcon style={{ fontSize: '14px', marginTop: '1px' }} />
              </MuiTooltip>
            </p>
            <p className={classes.ContentDark}> {submittedContent.join(', ')}</p>
          </div>

          <div className={'d-flex flex-column gap-1 ' + classes.RecentText}>
            <p className={classes.ContentLightTitle}>Most Recent Attempt
              <MuiTooltip arrow content={"When you last tried to publish this content."} placement="top">
                <InfoOutlinedIcon style={{ fontSize: '14px', marginTop: '1px' }} />
              </MuiTooltip>
            </p>
            <p className={classes.ContentDark}>{formateDateForHistory(errorHistory.updatedAt)}</p>
          </div>

          <div className={'d-flex flex-column gap-1 '+ classes.SubmittedText}>
            <p className={classes.ContentLightTitle}>Time Since Most Recent Attempt
              <MuiTooltip arrow content={tooltipComponent} placement="top">
                <InfoOutlinedIcon style={{ fontSize: '14px', marginTop: '1px' }} />
              </MuiTooltip>
            </p>
            <p className={classes.ContentDark}>{timeAgo(errorHistory.updatedAt)}</p>
          </div>

          <div className={'d-flex flex-column gap-1  w-50'}>
            <p className={classes.ContentLightTitle}>Publish Status
              <MuiTooltip arrow content={"Shows whether your content was accepted, rejected, or is pending."} placement="top">
                <InfoOutlinedIcon style={{ fontSize: '14px', marginTop: '1px' }} />
              </MuiTooltip>
            </p>
          <div className={'d-flex flex-row gap-2 flex-wrap'}>
            {histories && histories.length > 0 && histories.map((history:any) => history?.response?.status === ProductHistoryStatus.ACCEPTED ? <div key={history?.sku}></div> : <PublishStatusCard key={history?.sku} sku={history?.response?.sku} onClick={handleOpenHistory} errors={history.response.issues.length} />)}
          </div>
          </div>


        </div>

        {/*<PublishErrorAlert title={`Error ${errorObject.code},${errorObject.message}`} isAdditionalSupport />*/}


        <PublishHistoryDrawer submittedContent={submittedContent} errorObject={errorObject} errorHistory={sellerPartner?.history} open={ openHistory} onClose={handleCloseHistory}  />

        <ActionBox
          handleAction={() => setOpenModal(false)}
          open={openModal}
          actionText={"Dismiss"}
          message={
            <div>
              <p>We encountered an issue while publishing this product. It is currently not live on Amazon.</p>
              {errorHistory?.response?.issues.map((issue:any)=> <PublishErrorAlert sku={errorHistory?.response?.sku} key={issue.code}  message={issue.message} code={issue.code} isAdditionalSupport={false} />)}

              <p className={classes.ErrorSubtitle}>For additional support and information, please click <span
                onClick={openAmazonErrors} className={classes.AmazonErrorButton}>Amazon Publish Errors</span></p>
            </div>
          }
          title={"Publish Error"}
        />

      </div>
    </CardOverlay>
    <div className={'mb-4'}></div>
  </>
  )

}


export default PublishErrorCard