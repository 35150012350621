import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

import NoResultIcon from '../../../assets/svgs/settingsIcons/no-result.svg'
import { SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import MTypography from "../../../components/Atoms/MTypography";
import MButton from "../../../components/Atoms/MButton";
import { navigatePage } from "../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import UploadConfigModal from "../../../components/Organisms/ConfigFiles/UploadConfigModal/UploadConfigModal";

import classes from "./ConfigFiles.module.css"
import JsonCard from "../../../components/Organisms/ConfigFiles/JsonCard/JsonCard";
import ActionBox from "../../../components/Molecules/ActionBox";

interface ConfigFilesPageProps {
  isPage?: boolean;
}

const ConfigFilesPage:React.FC<ConfigFilesPageProps> = ({
  isPage = true,
                                                        }) => {

  const navigate = useNavigate();

  const [isOpenUpload,setIsOpenUpload] = React.useState(false);
  const [isFile,setIsFile] = useState<boolean>(false);
  const [selectedJsonContent, setSelectedJsonContent] = React.useState({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [deleteConfigModal,setDeleteConfigModal] = React.useState(false);

  const navigateToBrands = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND}`, navigate);
  };

  const handleCloseUpload = () => {
    setIsOpenUpload(false)
  }
  const openUpload = () => {
    setIsOpenUpload(true)
  }


  const handleContinue = (file:any) => {
    setIsOpenUpload(false)
    setSelectedFile(file)
  }

  const openDeleteModal = () => {
    setDeleteConfigModal(true)
  }

  const closeDeleteModal = () => {
    setDeleteConfigModal(false)
  }

  const confirmDeleteAction = () => {
    setSelectedFile(null)
    setSelectedJsonContent({})
    closeDeleteModal()
  }

  console.log(setIsFile);


  return (
    <div className={"mb-4"}>
      {isPage && <TabsToolbar
        title={SettingsEnum.CONFIG_FILES}
        children={
          <div className="d-flex align-items-center gap-4">
            <div className={"cursor-pointer"} onClick={navigateToBrands}>
              <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
            </div>
            {isFile &&   <MButton variant="contained" onClick={()=>console.log()}>
              Upload Config File
            </MButton> }
          </div>
        }
      />}
      {Object.keys(selectedJsonContent).length > 0 ?
        <JsonCard openDeleteModal={openDeleteModal} fileName={selectedFile?.name} data={selectedJsonContent} initialLines={4} /> :  <div className={'d-flex flex-column gap-4 align-items-center mt-5'}>
        <img src={NoResultIcon} className={classes.NoResultIcon} alt={'No Result'} />
        <MTypography variant={"subtitle1"} customClass={classes.NoResultTitle}>No Config Files Uploaded Yet</MTypography>
        <MTypography variant={"subtitle1"} customClass={classes.NoResultSubTitle}>Upload a configuration file to manage your automated writing settings efficiently. These files help personalize and optimize content generation according to your brand’s needs.</MTypography>
        <MButton variant="contained" onClick={openUpload}>
          Upload Config File
        </MButton>
      </div>}

      <UploadConfigModal
        setSelectedJsonContent={setSelectedJsonContent}
        open={isOpenUpload}
        onClose={handleCloseUpload}
        handleContinueClick={handleContinue}
        selectedAsinsArray={[]}
      />

      <ActionBox
        handleAction={confirmDeleteAction}
        handleBack={closeDeleteModal}
        open={deleteConfigModal}
        actionText={"Delete"}
        message={"Are you sure you want to delete this configuration file? This action cannot be undone."}
        title={"Delete Configuration File?"}
        backText={"Cancel"}
      />
    </div>
  );
};

export default ConfigFilesPage ;
