import { SettingsEnum } from "../../../utils/constants/enums";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import brandAliasIcon from "../../../assets/svgs/settingsIcons/brand-alias-icon.svg"
import bulletPointsIcon from "../../../assets/svgs/settingsIcons/bulletpoints-format-icons.svg"
import configFileIcon from "../../../assets/svgs/settingsIcons/config-file-icon.svg"
// import brandVoiceIcon from "../../../assets/svgs/settingsIcons/brand-voice.svg"

export const brandsMenu = [
    // only adding one for now 
    {
        title: capitalizeEachLetter(SettingsEnum.CONTENT_FORMAT_SETTINGS.split("-").join(" ")),
        description: "Customize how your content format appears to customers.",
        icon: bulletPointsIcon,
        name: SettingsEnum.CONTENT_FORMAT_SETTINGS,
    },
    {
        title: capitalizeEachLetter(SettingsEnum.BRAND_ALIAS_MANAGEMENT.split("-").join(" ")),
        description: "Personalize aliases and define your brand's distinctive voice.",
        icon: brandAliasIcon,
        name: SettingsEnum.BRAND_ALIAS_MANAGEMENT,
    },
    {
        title: capitalizeEachLetter(SettingsEnum.CONFIG_FILES.split("-").join(" ")),
        description: "Manage custom configuration files for automated content generation.",
        icon: configFileIcon,
        name: SettingsEnum.CONFIG_FILES,
    },
    // {
    //     title: capitalizeEachLetter(SettingsEnum.BRAND_VOICE.split("-").join(" ")),
    //     description: "Define and refine your brand's distinctive voice.",
    //     icon: brandVoiceIcon,
    //     name: SettingsEnum.BRAND_VOICE,
    // }, 
]