import { useNavigate } from "react-router-dom";
import MTypography from "../../../components/Atoms/MTypography";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import { navigatePage } from "../../../utils/helpers/common";
import SettingMenuCard from "../../../components/Organisms/SettingsMenuCard";
import { useDeviceType } from "../../../hooks/useDeviceType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { thirdPartyIntegrationOptions } from "./config";
import classes from "./index.module.css";

const ThirdPartyIntegration = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };

  const handleNavigate = (name: string) => {
    if (name === SettingsEnum.PIM) {
      navigate(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.PIM}`);
    } else if (name === SettingsEnum.AMAZON_CONNECTIONS) {
      navigate(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AMAZON_CONNECTIONS}`);
    } else {
    }
  };

  return (
    <div>
      <div>
        {isDesktop ? (
          <div>
            <TabsToolbar
              title={"Third-party Integration"}
              avoidCapitalization={true}
              children={
                <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
                  <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
                </div>
              }
            />
            <MTypography variant="body1" customClass={classes.SubHeading}>
              Connect third-party tools to manage product information and publishing.
            </MTypography>
          </div>
        ) : (
          <div className={"d-flex flex-column p-2 m-2"}>
            <div className={"my-2 mx-2"}>
              <ArrowBackIcon onClick={navigateToSettingsTab} />
            </div>
            <div className={"my-2 mx-2"}>
              <MTypography variant={"h5"}>Third-party Integration</MTypography>
              <MTypography variant="body1" customClass={classes.SubHeading}>
                Connect third-party tools to manage product information and publishing.
              </MTypography>
            </div>
          </div>
        )}

        <div className={`d-flex align-items-center ${isDesktop ? "flex-row" : "flex-column"}`}>
          {thirdPartyIntegrationOptions.map((menu) => (
            <div key={menu.title} onClick={() => handleNavigate(menu.name)} className={`${isDesktop && "m-5"}`}>
              <SettingMenuCard title={menu.title} description={menu.description} icon={menu.icon} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyIntegration;
