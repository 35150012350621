export const SUCC_LOG_OUT = "See you later! You have been logged out.";
export const SUCC_RESET_PASSWORD = "Please check your email inbox for a link to reset your password.";
export const SUCC_VERIFICATION_LINK = "Verification link send. please check your inbox.";
export const SUCC_DELETE_PRODUCT = "Your Product has been deleted successfully.";
export const SUCC_UPDATE_PRODUCT = "Your Product has been updated successfully.";
export const SUCC_ACCESS_KEY_DELETE = "API Access Key has been deleted successfully.";
export const SUCC_ACCESS_KEY_GENERATED = "API Access Key has been generated successfully.";
export const SUCC_ACCESS_KEY_UPDATED = "API Access Key status is updated successfully.";
export const SUCC_CONNECTION_DETAILS_UPDATED = "Connection details updated successfully.";

export const SUCC_BRAND_CREATED = "Brand created successfully.";
export const SUCC_BRAND_UPDATED = "Brand updated successfully.";

export const SUCC_INVITATIONS_SENT = "Invitations sent successfully.";

export const SUCC_USER_PROFILE_UPDATED = "User profile successfully updated.";
export const SUCC_COMPANY_PROFILE_UPDATED = "Company profile successfully updated.";
export const SUCC_USER_STATUS_UPDATED = "User status updated successfully.";
export const SUCC_INVITE_LINK_DELETED = "Invitation link has been deleted successfully.";
export const SUCC_REMINDER_SEND = "Reminder has been send successfully.";
export const SUCC_CSV_EXPORT = "Your export is in progress. Please check your email later.";
export const SUCC_TITLE_UPDATED = "Title updated successfully.";
