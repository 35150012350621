import React, { memo, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { IconButton, Tooltip } from "@mui/material";
import { GridCellParams, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
// import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DeleteIcon from "@mui/icons-material/Delete";

import { toDateAndTimeObject } from "../../../../../utils/helpers/date";
import { copyAsinByRightClick, getExceptionError, navigatePage } from "../../../../../utils/helpers/common";
import { SUCC_CSV_EXPORT, SUCC_DELETE_PRODUCT } from "../../../../../utils/constants/messages/success";
import { defaultImage } from "../../../../../utils/constants/general-constants";
import { errorHandler } from "../../../../../utils/helpers/apis";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";
import { generatingProcessTimeTenToFifteen } from "../../../../../utils/constants/common";
import {
  capitalizeFirstLetterAfterParentheses,
  extractAmazonASINStr,
  generateUniqueString,
  onImgErrorHandler,
  reduceImgSize,
} from "../../../../../utils/helpers/general-helpers";
import { ERR_CSV_EXPORT } from "../../../../../utils/constants/messages/errors";
import {
  isExportButton,
  showAddTagOption,
  showMultipleProductDeleteButton,
  showMultipleProductDeleteButtonCompany,
  // showPublishOnAmazonButton,
  showUserEmailInProductsPage,
} from "../../../../../utils/helpers/priviligesChecks";
import {
  CatalogEnum,
  ContentStatusEnum,
  CountryDomainEnum,
  ProductLiveStatusEnum,
  RolesEnum,
  TabsEnum,
} from "../../../../../utils/constants/enums";

import { URL_PRODUCT_CATALOG } from "../../../../../routes/routes-path";
import { Product } from "../../../../../apis/types/generate-product";

import KeywordsPageDialoge from "../../../../mui/dialogebox/keywordsPageDialoge";
import ExportAsinsDialoge from "../../../../mui/dialogebox/exportAsinsDialoge";

import {
  checkSelectedProductsLiveActions,
  savedProductsDispatch,
  setChangeKeywordsProductOwnerIdDispatch,
  setParentVariationPaginationDispatch,
  setVaraitionsProductSearchFilterDispatch,
  setVaraitionsProductSearchResetDispatch,
} from "../../../../../store/product/product.actions";

import {
  ParentVariationPaginationSelector,
  ProductVariationsSearchFilterSelector,
  SavedProductsSelector,
  UserProductTagsSelector,
} from "../../../../../store/product/product.selector";
import { DbUserSelector, UserRoleSelector } from "../../../../../store/user/user.selector";
import { CompanyRoleSelector } from "../../../../../store/company/company.selector";

import { generateProductApis } from "../../../../../apis/generate-product";

import { usePagination } from "../../../../../hooks/usePagination";

import {
  checkLiveProductLimit,
  downloadSavedProductCsv,
  downloadSelectedProductsCsv,
} from "../../../../../pages/SavedProducts/config";

import MTypography from "../../../../Atoms/MTypography";
import MTextField from "../../../../Atoms/MTextField";
import MuiPopover from "../../../../Atoms/Popover";
import MuiTooltip from "../../../../Atoms/MuiTooltip";

import ProductStatus from "../../../../Molecules/ProductStatus";
import ActionBox from "../../../../Molecules/ActionBox";
import FilterMenu from "../../../../Molecules/FilterMenu";

import { CountryDomainObject, getCountryDomainObjectByName } from "../../../DomainDropDown/config";
import TagPopper from "../../TagPopper";
import { getAllTagsOfProduct } from "../../TagPopper/config";
import { contentFilters, contentFiltersOption } from "../../Brands/config";
import GridSkeleton from "../../GridSkeleton/GridSkeleton";

import classes from "../../ProductDataGrid/index.module.css";
import ExportAllAsinsDialoge, { exportAllProductCsvType } from "../../../../mui/dialogebox/exportAllAsinsDialoge";
import VariationGrid from "../../VariationGrid/VariationGrid";
import { useDeviceType } from "../../../../../hooks/useDeviceType";

type ProductDataGridState = {
  loading: boolean;
  openDialogBox: boolean;
  openDeleteConfirm: boolean;
  currentDeleteId: string;
};

// using same interface for actionsMenu
export interface PopperMenuState {
  showPopper: boolean;
  anchorEl: HTMLDivElement | SVGSVGElement | null | any;
}
const ProductDataGrid = ({
  tab,
  isChildComponent,
  isTeamManagementUser = false,
  teamUserId = "",
  isCompany = false,
}: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tags = useSelector(UserProductTagsSelector);
  const dbUser = useSelector(DbUserSelector);
  const userRole = useSelector(UserRoleSelector);
  const products = useSelector(SavedProductsSelector);
  const productsPagination = useSelector(ParentVariationPaginationSelector);
  const searchQuery = useSelector(ProductVariationsSearchFilterSelector);
  const companyRole = useSelector(CompanyRoleSelector);

  const { isDesktop } = useDeviceType();
  const brand = location?.state?.productBrand;
  const [state, setState] = useState<ProductDataGridState>({
    loading: true,
    openDialogBox: false,
    openDeleteConfirm: false,
    currentDeleteId: "",
  });
  const [variations, setVariations] = useState([]);
  const [variationObjects, setVariationObjects] = useState<any>({});
  const [openMultipleDeleteConfirm, setOpenMultipleDeleteConfirm] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  const [search, setSearch] = useState<string>(searchQuery);
  //const [searchQuery, setSearchQuery] = useState<string>("");
  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy, setSortModel } = usePagination(
    100,
    true,
    productsPagination
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [showToolbarOptions, setShowToolbarOptions] = useState<boolean>(false);
  const [tagOptions, setTagsOption] = useState<PopperMenuState>({
    showPopper: false,
    anchorEl: null,
  });
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [productLiveFilter, setProductLiveFilter] = useState<any>(() => {
    const prevPublishStatus = localStorage.getItem("publishStatus");
    return prevPublishStatus ? prevPublishStatus : contentFilters.all;
  });
  const [hideInternalUsersProducts, setHideInternalUsersProducts] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("hideInternalUsersProducts");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const [showApprovedProducts, setShowApprovedProducts] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("showApprovedProducts");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  // for filter
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [openAllCsvExportDialog, setOpenAllCsvExportDialog] = useState<boolean>(false);

  const [totalGroupsCount, setTotalGroupsCount] = useState<number>(0);
  const [copiedTooltip, setCopiedTooltip] = useState<string | null>(null);

  const handleCsvExport = (exportType: string) => {
    if (!csvLoading) {
      setCsvLoading(true);
      downloadSavedProductCsv({
        showProtectedFields: showUserEmailInProductsPage(dbUser?.role),
        brand,
        exportType,
        teamUserID: teamUserId ? teamUserId : isTeamManagementUser ? dbUser._id : null,
        isCompany:
          isCompany ||
          (dbUser?.companyID &&
            (dbUser?.role === RolesEnum.USER || dbUser?.role === RolesEnum.COMPANY_ADMIN) &&
            !teamUserId &&
            !brand),
        companyID: isCompany
          ? teamUserId
          : dbUser?.companyID && (dbUser?.role === RolesEnum.USER || dbUser?.role === RolesEnum.COMPANY_ADMIN)
          ? dbUser?.companyID
          : "",
        filters: getFilters(),
      })
        .then(() => {
          toast.success(SUCC_CSV_EXPORT);
          setCsvLoading(false);
        })
        .catch((error: any) => {
          toast.error(errorHandler(error));
          setCsvLoading(false);
        });
    } else {
      toast.warn("Exporting Data. Please Wait");
    }
  };
  const handleExportAllCsvClick = () => setOpenAllCsvExportDialog(true);

  const handleSelectedCsvExport = (exportType: string, singleProduct: null | Product = null) => {
    if (!csvLoading) {
      let productAsinsAndUserIds: any[] = [];
      if (singleProduct) {
        productAsinsAndUserIds = [
          {
            productASIN: singleProduct.productASIN,
            userID: singleProduct.userID,
          },
        ];
      } else {
        productAsinsAndUserIds = selectedRows.map((product) => ({
          productASIN: product.productASIN,
          userID: product.userID,
        }));
      }
      setCsvLoading(true);
      downloadSelectedProductsCsv({
        payload: productAsinsAndUserIds,
        exportType: exportType,
        showProtectedFields: showUserEmailInProductsPage(dbUser?.role),
        bulletPointsFormat: dbUser?.bulletPointsFormat,
      })
        .then(() => {
          toast.success("CSV export successful.");
          setCsvLoading(false);
          setSelectedRows([]);
          setSelectionModel([]);
        })
        .catch((error: any) => {
          toast.error(errorHandler(error));
          setCsvLoading(false);
        });
    } else {
      toast.warn("Exporting Data. Please Wait");
    }
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      dispatch(setVaraitionsProductSearchFilterDispatch(value));
    }, 1500)
  ).current;

  const getProducts = async ({ filters }: any) => {
    try {
      setState({ ...state, loading: true });
      let res = await generateProductApis.getProductVariations({
        offset: filters.offset,
        limit: filters.limit,
        searchQuery: searchQuery,
        sortBy,
        sortOrder,
      });
      let variationObj: any = {};

      const variationData: any = [];

      res?.completeProductsData.forEach((item: any) => {
        variationData.push({
          ...item,
          id: generateUniqueString(item.productASIN || ""),
        });

        variationObj = {
          ...variationObj,
          [item.parentASIN]: {
            ...item,
          },
        };
      });
      setTotalGroupsCount(res?.totalGroups || 0);
      setVariationObjects(variationObj);

      setVariations(variationData);
      setState({ ...state, loading: false });
    } catch (e) {
      setState({ ...state, loading: false });
    }
  };
  const getFilters = (): any => {
    let filters: any = {
      offset,
      limit: pageSize,
      searchQuery: searchQuery,
      isReviewPage: tab === TabsEnum.REVIEW,
      hideInteralUsersProduct: false,
      searchFilter: "",
      isApprovedProducts: false,
    };
    if (sortBy !== "") {
      filters = {
        ...filters,
        sortBy,
        sortOrder,
      };
    }

    if (productLiveFilter) {
      filters = {
        ...filters,
        searchKey: productLiveFilter === contentFilters.all ? "" : "productLive.isProductLive",
        searchFilter: productLiveFilter === contentFilters.all ? "" : productLiveFilter,
      };
    }
    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    if (brand) {
      filters = {
        ...filters,
        productBrand: brand === "Others" ? undefined : brand,
      };
    }

    if (hideInternalUsersProducts) {
      filters = {
        ...filters,
        hideInteralUsersProduct: hideInternalUsersProducts,
      };
    }

    if (showApprovedProducts) {
      filters = {
        ...filters,
        isApprovedProducts: showApprovedProducts,
      };
    }

    return filters;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      dispatch(setVaraitionsProductSearchResetDispatch());
    };
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selectedRows?.length) setShowToolbarOptions(true);
    else setShowToolbarOptions(false);
  }, [selectedRows]);

  /* eslint-disable react-hooks/exhaustive-deps */
  //useEffect to retain the state of pagination
  useEffect(() => {
    dispatch(
      setParentVariationPaginationDispatch({
        pageNumber,
        pageSize,
        offset,
        sortBy,
        sortOrder,
      })
    );
  }, [pageNumber, pageSize, offset, sortBy, sortOrder, searchQuery]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(savedProductsDispatch([]));
    // to clear the state
    dispatch(setChangeKeywordsProductOwnerIdDispatch(null));
    localStorage.removeItem("productOwnerId");
    localStorage.removeItem("isGenericVariation");
  }, []);

  const isInitialRender = useRef(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInitialRender.current) {
      getProducts({ filters: getFilters(), navigate });
    } else {
      isInitialRender.current = false;
    }
  }, [
    pageNumber,
    pageSize,
    offset,
    sortBy,
    sortOrder,
    searchQuery,
    productLiveFilter,
    hideInternalUsersProducts,
    showApprovedProducts,
  ]);

  const handleMultipleDelete = () => setOpenMultipleDeleteConfirm(true);
  const closeConfirmDelete = () => {
    if (state.openDeleteConfirm) setState({ ...state, openDeleteConfirm: false, currentDeleteId: "" });
    else if (openMultipleDeleteConfirm) setOpenMultipleDeleteConfirm(false);
  };
  const handleCellClick = async (gridCellParams: GridCellParams, openInNewTab = false) => {
    if (!gridCellParams?.rowNode?.parent) {
      const firstChildId = gridCellParams?.rowNode.children ? gridCellParams?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      if (childProduct?.status === ContentStatusEnum.GENERATING) {
        setState({ ...state, openDialogBox: true });
        setTimeout(() => {
          setState({ ...state, openDialogBox: false });
        }, 5000);
      } else if (openInNewTab) {
        const url = `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${childProduct?.productASIN}/${childProduct?.userID}`;
        window.open(url, "_blank");
      } else {
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${childProduct?.productASIN}/${childProduct?.userID}`,
          navigate,
          {
            state: {
              isChildComponent: TabsEnum.SAVED_ASIN,
            },
          }
        );
      }
    } else if (gridCellParams?.row.productASIN) {
      const childProduct: any = gridCellParams?.row;
      if (childProduct?.status === ContentStatusEnum.GENERATING) {
        setState({ ...state, openDialogBox: true });
        setTimeout(() => {
          setState({ ...state, openDialogBox: false });
        }, 5000);
      } else if (openInNewTab) {
        const url = `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${childProduct?.productASIN}/${childProduct?.userID}`;
        window.open(url, "_blank");
      } else {
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${childProduct?.productASIN}/${childProduct?.userID}`,
          navigate,
          {
            state: {
              isChildComponent: TabsEnum.SAVED_ASIN,
            },
          }
        );
      }
    } else {
      toast.warning("No child available!");
    }
  };

  const confirmDeleteProduct = async () => {
    if (state.openDeleteConfirm) {
      setState({ ...state, loading: true });
      generateProductApis
        .deleteGeneratedProducts([state.currentDeleteId])
        .then(() => {
          let filteredProducts = products.filter((product: Product) => product._id !== state.currentDeleteId);
          dispatch(savedProductsDispatch(filteredProducts));
          toast.success(SUCC_DELETE_PRODUCT);
          closeConfirmDelete();
        })
        .catch((e) => {
          setState({ ...state, loading: false });
          closeConfirmDelete();
          getExceptionError(e);
        });
    } else if (openMultipleDeleteConfirm) {
      setState({ ...state, loading: true });
      try {
        await generateProductApis.deleteGeneratedProducts(selectedRows.map((p) => p._id));

        getProducts({ filters: getFilters(), navigate });
        setSelectedRows([]);
        setSelectionModel([]);
        setOpenMultipleDeleteConfirm(false);
        toast.success("Your product has been deleted successfully");
        setState({ ...state, loading: false });
      } catch (e) {
        errorHandler(e);
        setState({ ...state, loading: false });
        setOpenMultipleDeleteConfirm(false);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };
  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    setSelectedRows(products.filter((row: any) => selectedIDs.has(row.id)));
    setSelectionModel(e);
  };
  const handleProductLive = () => {
    if (selectedRows?.length) {
      const approved = selectedRows?.filter(
        (pro) => pro?.status !== ContentStatusEnum.GENERATING && pro?.status !== ContentStatusEnum.ERROR
      );
      if (approved.length <= checkLiveProductLimit) {
        setState({ ...state, loading: true });
        dispatch(
          checkSelectedProductsLiveActions(
            approved.map((r) => ({ productASIN: r?.productASIN || "", userID: r?.userID || "" }))
          )
        )
          .then(() => {
            getProducts({ filters: getFilters(), navigate });
            setSelectedRows([]);
            setSelectionModel([]);
          })
          .catch((e: any) => {
            errorHandler(e);
            setState({ ...state, loading: false });
          });
      } else {
        toast.warn(`Cannot select more than ${checkLiveProductLimit} products`);
      }
    } else {
      toast.warn("No Approved Product Selected");
    }
  };
  const handleAddTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTagsOption({ ...tagOptions, showPopper: true, anchorEl: e.currentTarget });
  };

  const handleFilterChange = (value: string, showInternalProduct: boolean, showApprovedChecked: boolean) => {
    if (
      localStorage.getItem("hideInternalUsersProducts") ||
      localStorage.getItem("publishStatus") ||
      localStorage.getItem("showApprovedProducts")
    ) {
      localStorage.removeItem("hideInternalUsersProducts");
      localStorage.removeItem("publishStatus");
      localStorage.removeItem("showApprovedProducts");
    }

    try {
      setHideInternalUsersProducts(showInternalProduct);
      setShowApprovedProducts(showApprovedChecked);
      localStorage.setItem("hideInternalUsersProducts", JSON.stringify(showInternalProduct));
      localStorage.setItem("publishStatus", value);
      localStorage.setItem("showApprovedProducts", JSON.stringify(showApprovedChecked));
      const filter: string = value;
      setProductLiveFilter(filter);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowFilterMenu(!showFilterMenu);
    setAnchorEl(e.currentTarget);
  };

  const handleFilterClose = () => {
    setShowFilterMenu(false);
    setAnchorEl(null);
  };

  const handleRefresh = () => {
    getProducts({ filters: getFilters(), navigate });
  };

  const productsColumns: GridColDef[] = [];

  const handleCopyAsinClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, asin: string) => {
    e.preventDefault();
    setCopiedTooltip(asin);
    setTimeout(() => {
      setCopiedTooltip(null);
    }, 1000);

    copyAsinByRightClick(e, asin, isDesktop);
  };

  productsColumns.push({
    field: "productASIN",
    headerName: "ASIN",
    width: 160,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: any) => {
      const prodAsin: any = extractAmazonASINStr(params.id);

      const actualProduct: any = prodAsin ? variationObjects[prodAsin] : params.row;
      const firstChildId = params?.rowNode.children ? params?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      const currentProductStatus = childProduct?.status ? childProduct?.status : actualProduct?.status;

      return (
        <>
          {prodAsin ? (
            <div className="d-flex justify-content-between align-items-center w-100">
              <div
                style={{
                  pointerEvents: "all",
                  cursor: state.loading ? "not-allowed" : "pointer",
                }}
                onClick={() => handleCellClick(params, false)}
                onContextMenu={(e) => handleCopyAsinClick(e, prodAsin)}
              >
                <div className={"d-flex align-items-center"}>
                  <Tooltip title={copiedTooltip === prodAsin ? "Copied" : ""} arrow placement={"top"}>
                    <div className={`${classes.ASIN} HoverEffectForLinks`}>{prodAsin}</div>
                  </Tooltip>
                </div>
              </div>
              <div style={{ cursor: "pointer", zIndex: 200 }}>
                {!state.loading && currentProductStatus !== ContentStatusEnum.GENERATING && (
                  <Tooltip title={"Open in new tab"} placement="top">
                    <IconButton onClick={() => handleCellClick(params, true)}>
                      <ArrowOutwardIcon htmlColor="#2e2e2e" fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center w-100">
              <div
                style={{
                  pointerEvents: "all",
                  cursor: state.loading ? "not-allowed" : "pointer",
                }}
                onClick={() => handleCellClick(params)}
                onContextMenu={(e) => handleCopyAsinClick(e, params.row.productASIN)}
              >
                <div className={"d-flex align-items-center"}>
                  <Tooltip title={copiedTooltip === params.row.productASIN ? "Copied" : ""} arrow placement={"top"}>
                    <div className={`${classes.ASIN} HoverEffectForLinks`}>{params.row.productASIN}</div>
                  </Tooltip>
                </div>
              </div>
              <div style={{ cursor: "pointer", zIndex: 200 }}>
                {!state.loading && currentProductStatus !== ContentStatusEnum.GENERATING && (
                  <Tooltip title={"Open in new tab"} placement="top">
                    <Link
                      to={`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${params.row?.productASIN}/${params.row?.userID}`}
                      target="_blank"
                    >
                      <IconButton>
                        <ArrowOutwardIcon htmlColor="#2e2e2e" fontSize="medium" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            </div>
          )}
        </>
      );
    },
  });

  productsColumns.push({
    field: "title",
    headerName: "Title",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const prodAsin: any = extractAmazonASINStr(_params.id);
      const actualProduct: any = prodAsin ? variationObjects[prodAsin] : _params.row;
      const firstChildId = _params?.rowNode.children ? _params?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      const titleToShow = childProduct?.title ? childProduct?.title : actualProduct?.title;
      const imageToShow = childProduct?.imageURL ? childProduct?.imageURL : actualProduct?.imageURL;

      return (
        <div className={"d-flex align-items-center"}>
          <img
            src={reduceImgSize(imageToShow || defaultImage)}
            onError={(e) => onImgErrorHandler(e, defaultImage)}
            alt={actualProduct.productASIN}
            className={classes.Image}
          />
          <div>
            <div className={classes.CellLinesLimitContainer} title={titleToShow}>
              {titleToShow}
            </div>
            {actualProduct?.status !== ContentStatusEnum.ERROR &&
              actualProduct?.status !== ContentStatusEnum.GENERATING && (
                <div className={"d-flex align-items-center gap-3"}>
                  {(actualProduct?.isProductLiveBeingChecked !== undefined || actualProduct?.productLive) && (
                    <div className={"my-1"}>
                      <ProductStatus
                        type={
                          actualProduct?.isProductLiveBeingChecked
                            ? ProductLiveStatusEnum.CHECKING
                            : actualProduct?.productLive?.isProductLive
                        }
                        variant={"small"}
                      />
                    </div>
                  )}

                  {!!getAllTagsOfProduct(tags, actualProduct?._id)?.length && (
                    <MuiTooltip
                      arrow
                      content={
                        <div className={"d-flex align-items-center p-2 flex-wrap gap-2"}>
                          {getAllTagsOfProduct(tags, actualProduct?._id)?.map((tag) => (
                            <div
                              key={tag?._id}
                              style={{ backgroundColor: tag.color, color: "white" }}
                              className={`${classes.TagContainer}`}
                            >
                              {tag?.name}
                            </div>
                          ))}
                        </div>
                      }
                    >
                      <div className={`d-flex align-items-center ${classes.Tag} cursor-pointer`}>
                        <LocalOfferOutlinedIcon fontSize={"small"} />
                        {getAllTagsOfProduct(tags, actualProduct?._id)?.length}
                      </div>
                    </MuiTooltip>
                  )}
                </div>
              )}
          </div>
        </div>
      );
    },
  });

  productsColumns.push({
    field: "uniqueAttribute",
    headerName: "Unique attribute",
    width: 140,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const actualProduct: any = _params.row;
      const uniqueAttributes = actualProduct?.uniqueAttribute || "";
      return actualProduct?.parentASIN !== undefined ? (
        <div>
          <div className={classes.CellLinesLimitContainer} title={uniqueAttributes}>
            {capitalizeFirstLetterAfterParentheses(uniqueAttributes) || "N/a"}
          </div>
        </div>
      ) : (
        <div />
      );
    },
  });

  productsColumns.push({
    field: "attributes",
    headerName: "Variation attributes",
    width: 140,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const actualProduct: any = _params.row;
      const variationAttributes = actualProduct?.attributes || "";
      return actualProduct?.parentASIN !== undefined ? (
        <div className={classes.CellLinesLimitContainer} title={variationAttributes}>
          {capitalizeFirstLetterAfterParentheses(variationAttributes) || "N/a"}
        </div>
      ) : (
        <div />
      );
    },
  });

  productsColumns.push({
    field: "domain",
    headerName: "Marketplace",
    width: 120,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const firstChildId = _params?.rowNode.children ? _params?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      const domainToShow = childProduct?.domain
        ? childProduct?.domain
        : _params.row?.domain || CountryDomainEnum.AMAZON_USA;
      const countryDomainObject = getCountryDomainObjectByName(domainToShow) as CountryDomainObject;

      return (
        <div>
          <div className={`d-flex align-items-center`}>
            <img src={countryDomainObject.flag} alt={`${countryDomainObject.name} Flag`} className={classes.Flag} />
            <MTypography variant={"subtitle2"} customClass={classes.FlagName}>
              {countryDomainObject.marketPlaceName}
            </MTypography>
          </div>
        </div>
      );
    },
  });

  productsColumns.push({
    field: "productBrand",
    headerName: "Brand",
    width: 110,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const prodAsin: any = extractAmazonASINStr(_params.id);
      const actualProduct: any = prodAsin ? variationObjects[prodAsin] : _params.row;
      const firstChildId = _params?.rowNode.children ? _params?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      const finalSelectedProduct = childProduct ? childProduct : actualProduct;

      return (
        <div title={finalSelectedProduct.productBrand} className={classes.CellLinesLimitContainer}>
          {capitalizeEachLetter(finalSelectedProduct.productBrand || "")}
        </div>
      );
    },
  });

  productsColumns.push({
    field: "groupBy",
    headerName: "",
    width: 80,
    sortingOrder: [],
    sortable: false,
    renderCell: (params: GridCellParams) => {
      return (
        <div className={classes.CellLinesLimitContainer}>
          {params?.rowNode.children !== undefined ? params?.rowNode.children.length : ""}
        </div>
      );
    },
  });

  if (showUserEmailInProductsPage(userRole as RolesEnum) || dbUser?.companyID) {
    productsColumns.push({
      field: "userEmail",
      headerName: "User",
      hideable: true,
      width: 210,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const prodAsin: any = extractAmazonASINStr(params.id);
        const actualProduct: any = prodAsin ? variationObjects[prodAsin] : params.row;

        const firstChildId = params?.rowNode.children ? params?.rowNode?.children[0] : "";
        const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
        const userEmailToShow = childProduct?.userEmail ? childProduct?.userEmail : actualProduct?.userEmail || "";

        return (
          <>
            {userRole !== RolesEnum.USER || dbUser?.companyID ? (
              <div title={userEmailToShow} className={classes.CellLinesLimitContainer}>
                {userEmailToShow}
              </div>
            ) : (
              <div></div>
            )}
          </>
        );
      },
    });
  }
  productsColumns.push({
    field: "status",
    headerName: "Status",
    width: 220,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const prodAsin: any = extractAmazonASINStr(_params.id);
      const actualProduct: any = prodAsin ? variationObjects[prodAsin] : _params.row;

      const firstChildId = _params?.rowNode.children ? _params?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      const statusToShow = childProduct?.status ? childProduct?.status : actualProduct?.status;

      return (
        <>
          <ProductStatus type={statusToShow || ""} showInfo />
        </>
      );
    },
  });
  productsColumns.push({
    field: "lastProductGeneratedAt",
    headerName: "Last version generated",
    width: 210,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const prodAsin: any = extractAmazonASINStr(_params.id);
      const actualProduct: any = prodAsin ? variationObjects[prodAsin] : _params.row;

      const firstChildId = _params?.rowNode.children ? _params?.rowNode?.children[0] : "";
      const childProduct: any = variations?.filter((product: any) => product.id === firstChildId)[0];
      const dateToShow = childProduct?.lastProductGeneratedAt
        ? childProduct?.lastProductGeneratedAt
        : actualProduct?.lastProductGeneratedAt || "";

      return (
        <div>
          {dateToShow ? (
            <div title={dateToShow} className={classes.CellLinesLimitContainer}>
              <>
                {toDateAndTimeObject(dateToShow).date} <br />@ {toDateAndTimeObject(dateToShow).time}
              </>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    },
  });

  const handleExportAsinTypeClick = (exportType: string) => {
    setOpenExportDialog(false);
    handleSelectedCsvExport(exportType, null);
  };

  const handleProductHistoryCsvExport = () => {
    setCsvLoading(true);
    generateProductApis
      .emailProductsHistory()
      .then(() => {
        toast.success(SUCC_CSV_EXPORT);
      })
      .catch((error: any) => {
        toast.error(errorHandler(error));
      })
      .finally(() => {
        setCsvLoading(false);
      });
  };

  const handleExportOptionClick = (exportType: string) => {
    if (products?.length > 0) {
      if (exportType === exportAllProductCsvType.all) {
        handleCsvExport("row");
      } else {
        handleProductHistoryCsvExport();
      }
    } else {
      toast.error(ERR_CSV_EXPORT);
    }
    setOpenAllCsvExportDialog(false);
  };

  const showFiltersAndExportButtons = false;

  return (
    <div className={isChildComponent || isTeamManagementUser ? "" : ``}>
      <div className={"d-flex justify-content-between align-items-center mb-3"}>
        <MTextField
          icon={"search"}
          position={"start"}
          onChange={handleChange}
          name={"searchbar"}
          placeholder={
            (showUserEmailInProductsPage(userRole as RolesEnum) || dbUser?.companyID) && !isTeamManagementUser
              ? "Search products by ASIN, Title or Email"
              : isTeamManagementUser && teamUserId && isCompany
              ? "Search products by ASIN, Title or Email"
              : "Search products by ASIN or Title"
          }
          value={search}
          margin={"dense"}
          rootClass={classes.TextInput}
        />
        {showToolbarOptions ? (
          <div className={`${classes.ToolbarsContainer} d-flex align-items-center gap-3`}>
            {(showMultipleProductDeleteButton(dbUser?.role) ||
              showMultipleProductDeleteButtonCompany(dbUser?.role, companyRole)) && (
              <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
                <div onClick={handleMultipleDelete} className={"d-flex align-items-center cursor-pointer"}>
                  <DeleteIcon className={classes.ToolbarIcon} />
                  {"Delete"}
                </div>
              </MTypography>
            )}
            <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
              <div onClick={handleProductLive} className={"d-flex align-items-center cursor-pointer "}>
                <OnlinePredictionOutlinedIcon className={classes.ToolbarIcon} />
                {"Check Product Live"}
              </div>
            </MTypography>
            {showAddTagOption(dbUser?.role) && (
              <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
                <>
                  <MuiPopover
                    open={tagOptions.showPopper}
                    anchorEl={tagOptions.anchorEl}
                    onClose={() => setTagsOption({ ...tagOptions, showPopper: false })}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <TagPopper selectedProducts={selectedRows} />
                  </MuiPopover>
                  <div onClick={handleAddTag} className={"d-flex align-items-center cursor-pointer"}>
                    <LocalOfferOutlinedIcon className={classes.ToolbarIcon} />
                    {"Add a Tag"}
                  </div>
                </>
              </MTypography>
            )}
            {!isExportButton(userRole, dbUser?.companyID, companyRole) && showFiltersAndExportButtons && (
              <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
                <div onClick={() => setOpenExportDialog(true)} className={"d-flex align-items-center cursor-pointer"}>
                  <FileDownloadOutlinedIcon fontSize={"medium"} />
                  {"Export"}
                </div>
              </MTypography>
            )}
          </div>
        ) : (
          <div className={`d-flex align-items-center justify-content-end ${state.loading ? "gap-4" : "gap-2"}`}>
            <div
              className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer ${
                state.loading ? `pe-none ${classes.DisabledText}` : "pe-auto HoverEffectForButton"
              }`}
              onClick={handleRefresh}
            >
              <CachedIcon />
              <MTypography variant={"subtitle1"} color="inherit">
                {"Refresh"}
              </MTypography>
            </div>
            <div>
              {showFiltersAndExportButtons && (
                <>
                  <div
                    className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer ${
                      state.loading ? `pe-none ${classes.DisabledText}` : "pe-auto HoverEffectForButton"
                    }`}
                    onClick={handleFilterClick}
                  >
                    <FilterListIcon fontSize={"medium"} />
                    <MTypography variant={"subtitle1"} color="inherit">
                      {"Filters"}
                    </MTypography>
                  </div>
                  <FilterMenu
                    open={showFilterMenu}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                    options={contentFiltersOption}
                    handleFilterChange={handleFilterChange}
                    hideInternalUsersProducts={hideInternalUsersProducts}
                    showApprovedProducts={showApprovedProducts}
                    productLiveFilter={productLiveFilter}
                    hideProductVisibilityFilter={!!teamUserId}
                  />
                </>
              )}
            </div>
            {showFiltersAndExportButtons && (
              <div
                className={`d-flex align-items-center gap-1 cursor-pointer ${
                  state.loading || isExportButton(userRole, dbUser?.companyID, companyRole)
                    ? `pe-none ${classes.DisabledText}`
                    : "pe-auto HoverEffectForButton"
                }`}
                // onClick={() => handleCsvExport("row")}
                onClick={handleExportAllCsvClick}
              >
                {!csvLoading && <FileDownloadOutlinedIcon fontSize={"medium"} />}
                <MTypography variant={"subtitle1"} color="inherit">
                  {csvLoading ? "Exporting..." : "Export"}
                </MTypography>
              </div>
            )}
          </div>
        )}
      </div>
      {state.loading ? (
        <GridSkeleton componentType={"parentVariations"} />
      ) : (
        <div>
          <VariationGrid
            total={totalGroupsCount}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            columns={productsColumns}
            rows={variations ?? []}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userEmail: showUserEmailInProductsPage(dbUser?.role),
                },
              },
            }}
            sortModel={[
              {
                field: sortBy || "lastProductGeneratedAt",
                sort: sortOrder ? (sortOrder === 1 ? ("asc" as any) : ("desc" as any)) : "asc",
              },
            ]}
            // onCellClick={handleCellClick}
            setSortModel={setSortModel}
            onSelectionModelChange={onSelectedRows}
            selectionModel={selectionModel}
            checkboxSelection={false}
            isRowSelectable={(params) =>
              params.row.status !== ContentStatusEnum.ERROR && params.row.status !== ContentStatusEnum.GENERATING
            }
          />
        </div>
      )}

      <KeywordsPageDialoge
        open={state.openDialogBox}
        onClose={() => {
          setState({ ...state, openDialogBox: false });
        }}
        title={generatingProcessTimeTenToFifteen}
      />
      <ActionBox
        handleAction={confirmDeleteProduct}
        handleBack={closeConfirmDelete}
        open={state.openDeleteConfirm || openMultipleDeleteConfirm}
        actionText={"Delete"}
        message={
          state.openDeleteConfirm ? "Do you want to delete your product?" : "Do you want to delete selected products?"
        }
        title={state.openDeleteConfirm ? "Confirm Delete Product" : "Confirm Delete Selected Products"}
        backText={"Cancel"}
      />

      <ExportAsinsDialoge
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        handleTypeClick={handleExportAsinTypeClick}
        title="Export"
      />

      <ExportAllAsinsDialoge
        open={openAllCsvExportDialog}
        onClose={() => setOpenAllCsvExportDialog(false)}
        handleTypeClick={handleExportOptionClick}
        title="Export"
      />
    </div>
  );
};

export default memo(ProductDataGrid);
