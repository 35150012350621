import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import { PartnerType, SettingsEnum, TabsEnum } from "../../../../utils/constants/enums";
import { navigatePage } from "../../../../utils/helpers/common";
import MTypography from "../../../Atoms/MTypography";
import MButton from "../../../Atoms/MButton";
import classes from "./index.module.css";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import NoDataAvialableYetView from "../../../Molecules/NoDataAvialableYetView";
import AmazonConnectionsTable from "./AmazonConnectionsTable";
import { usePagination } from "../../../../hooks/usePagination";
import { TablePagination } from "@mui/material";
import AuthorizeAccountTypeDialoge from "../../../mui/dialogebox/AuthorizeAccountDialoge";
import { getUnique7digitsString } from "../../../Molecules/AuthorizeBar/config";
import AmazonAcountNameAndVendorDialog from "../../../mui/dialogebox/AmazonAcountNameAndVendorDialoge";
import { amazonConnectionsListSelector } from "../../../../store/amazonAuthorize/amazonAuthorize.selector";
import { useDispatch } from "react-redux";
import { getAuthorizedAmazonConnectionsListDispath } from "../../../../store/amazonAuthorize/amazonAuthorize.actions";
import { useSelector } from "react-redux";
import AmazonConnectionsSkeletons from "./AmazonConnectionsSkeletons";

type DialogeTypes = {
  authorizeAccountTypeDialog: boolean;
  enterDetailsDialog: boolean;
};
const AmazonConnections = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const [loading, setLoading] = useState<boolean>(true);
  const [mode, setMode] = useState<boolean>(false); //new==false, renew=true
  const location = useLocation();
  const [selectedAccountDetails, setSelectedAccountDetails] = useState<any>({});

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const connectionsList = useSelector(amazonConnectionsListSelector);

  const { pageSize, setPageNumber, pageNumber, setPageSize } = usePagination(20);

  const [open, setOpen] = useState<DialogeTypes>({
    authorizeAccountTypeDialog: false,
    enterDetailsDialog: false,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      await dispatch(getAuthorizedAmazonConnectionsListDispath());
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      !loading &&
      connectionsList.length &&
      location?.state?.openDetailsDialog === true &&
      location?.state?.sellingPartnerId !== ""
    ) {
      setOpen({ ...open, enterDetailsDialog: true });
      const result = connectionsList?.find((item: any) => item.sellingPartnerID === location?.state?.sellingPartnerId);
      setSelectedAccountDetails(result);
    }
  }, [searchParams, loading]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    for (let val of searchParams.entries()) {
      // console.log(val);
      if (val[0] === "amazon_state") setMode(true);
    }
  }, [searchParams]);

  const navigateToAccessKeysTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.THIRD_PARTY_INTEGRATION}`, navigate);
  };

  const APPLICATION_ID = "amzn1.sp.solution.e29a84c4-04f3-40a9-bcc7-b188c87611f0";
  const SELLER_URL = "https://sellercentral.amazon.com";
  const VENDOR_URL = "https://vendorcentral.amazon.com";

  const startOauth = async (AUTH_URL: string) => {
    if (!mode) {
      window.open(AUTH_URL, "_self");
    } else {
      const amazon_state = searchParams.get("amazon_state");
      const amazon_callback_uri = searchParams.get("amazon_callback_uri");
      const ReAuthURL = `${amazon_callback_uri}?amazon_state=${amazon_state}`;
      window.open(ReAuthURL, "_self");
    }
    setOpen({ authorizeAccountTypeDialog: false, enterDetailsDialog: false });
  };

  const handleClickType = (type: string) => {
    localStorage.removeItem("authState");
    const state = getUnique7digitsString();
    localStorage.setItem("authState", state);
    // setting accountType
    localStorage.removeItem("partner_type");
    localStorage.setItem("partner_type", type);

    localStorage.removeItem("authorize_view");
    localStorage.setItem("authorize_view", "multi_authorize_accounts_view");

    if (type === PartnerType.SELLER) {
      const SELLER_OAUTH_URL =
        SELLER_URL +
        `/apps/authorize/consent?application_id=${APPLICATION_ID}&state=${state}&redirect_uri=${window.location.origin}/amazon`;
      startOauth(SELLER_OAUTH_URL);
    } else if (type === PartnerType.VENDOR) {
      const VENDOR_OAUTH_URL =
        VENDOR_URL +
        `/apps/authorize/consent?application_id=${APPLICATION_ID}&state=${state}&redirect_uri=${window.location.origin}/amazon`;
      startOauth(VENDOR_OAUTH_URL);
    } else {
    }
  };

  // const handleDelete = (connectionID: string) => {
  //   console.log("delete item ****** ", connectionID);
  // };

  const handleEdit = (connectionItem: any) => {
    navigatePage(
      `${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AMAZON_CONNECTIONS}/${connectionItem?._id}`,
      navigate
    );
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  return (
    <>
      {isDesktop ? (
        <div className={`d-flex justify-content-between align-items-center ${classes.Container}`}>
          <MTypography variant={"h3"} customClass={classes.Heading}>
            {capitalizeEachLetter(SettingsEnum.AMAZON_CONNECTIONS.split("-").join(" "))}
          </MTypography>
          <div className="d-flex align-items-center gap-3">
            <div className={"cursor-pointer"} onClick={navigateToAccessKeysTab}>
              <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
            </div>

            <MButton
              size={"small"}
              onClick={() => setOpen({ ...open, authorizeAccountTypeDialog: true })}
              rootClass={connectionsList.length ? "" : "d-none"}
            >
              Authorize New
            </MButton>
          </div>
        </div>
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex align-items-center justify-content-between"}>
            <ArrowBackIcon onClick={navigateToAccessKeysTab} />
            <MButton
              size={"small"}
              onClick={() => setOpen({ ...open, authorizeAccountTypeDialog: true })}
              disabled={loading}
              rootClass={connectionsList.length ? "" : "d-none"}
              // temporarily hiding this button
            >
              Authorize New
            </MButton>
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>
              {capitalizeEachLetter(SettingsEnum.AMAZON_CONNECTIONS.split("-").join(" "))}
            </MTypography>
          </div>
        </div>
      )}
      {/* views */}
      {connectionsList.length && !loading ? (
        <>
          {/* {isDesktop ? ( */}
          {/* <div> */}
          <AmazonConnectionsTable
            // handleDelete={handleDelete}
            handleEdit={handleEdit}
            pageSize={pageSize}
            pageNumber={pageNumber}
            dataList={connectionsList}
          />
          {/* </div>
          ) : (
            <div> </div>
          )} */}
          {connectionsList.length > 20 && !loading && (
            <div className={isDesktop ? classes.PaginationContainer : ""}>
              <TablePagination
                sx={{
                  "& .MuiInputBase-root": {
                    width: "auto",
                  },
                  "& .MuiTablePagination-root ": {
                    width: "100% !important",
                  },
                  "& .MuiTablePagination-selectLabel ": {
                    marginTop: "14px !important",
                  },
                  "& .MuiTablePagination-displayedRows ": {
                    marginTop: "14px !important",
                  },
                  "& .MuiTablePagination-actions": {
                    marginLeft: { xs: "0px !important", md: "20px !important" },
                  },
                  "& .MuiTablePagination-toolbar": {
                    paddingLeft: { xs: "0px !important", md: "16px !important" },
                    paddingRight: { xs: "0px !important", md: "2px !important" },
                    maxWidth: { xs: "100% !important" },
                  },
                }}
                component="div"
                count={connectionsList?.length}
                page={pageNumber}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[20, 50, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {!loading ? (
            <div>
              <NoDataAvialableYetView
                handleClick={() => setOpen({ ...open, authorizeAccountTypeDialog: true })}
                heading="You haven’t connected your Amazon account yet."
                subHeading="To get started, click the 'Authorize Now' button and securely link your Amazon Seller or Vendor account with AutoMato."
                buttonName="Authorize Now"
              />
            </div>
          ) : (
            <>
              {" "}
              {isDesktop ? (
                <>
                  <AmazonConnectionsSkeletons />
                </>
              ) : (
                <>mobile loading</>
              )}
            </>
          )}
        </>
      )}

      {/* to select vendor or seller */}
      <AuthorizeAccountTypeDialoge
        open={open.authorizeAccountTypeDialog}
        onClose={() => setOpen({ authorizeAccountTypeDialog: false, enterDetailsDialog: false })}
        title={"Select Amazon Account Type"}
        handleTypeClick={handleClickType}
        isAmazonConnections={true}
      />

      <AmazonAcountNameAndVendorDialog
        open={open.enterDetailsDialog}
        onClose={() => setOpen({ authorizeAccountTypeDialog: false, enterDetailsDialog: false })}
        title={"Add Amazon Account Details"}
        selectedAccountDetails={selectedAccountDetails}
      />
    </>
  );
};

export default AmazonConnections;
