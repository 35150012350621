import { produce } from "immer";
import { AmazonConnectionsActionTypes } from "./amazonAuthorize.action-types";
import { amazonAuthorizeActionType } from "./amazonAuthorize.actions";

export interface State {
  authorizedConnectionsList: any[];
}

const initialState: State = {
  authorizedConnectionsList: []
};

const amazonConnectionsReducer = produce((state: State = initialState, action: amazonAuthorizeActionType) => {
  switch (action.type) {
    case AmazonConnectionsActionTypes.setAmazonConnectionsList:
      const dataList = action.payload;
      if (Array.isArray(dataList)) {
        state.authorizedConnectionsList = dataList
      } else {
        state.authorizedConnectionsList = []
      }
      return state;
    default:
      return state;
  }
});

export default amazonConnectionsReducer;
