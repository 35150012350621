import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import connectionSwitchAnim from "../../assets/gif/connectionSwitchAnim.gif";
import Applogo from "../../assets/svgs/automatoai/logo-appbar.svg";
import amazonLogo from "../../assets/svgs/amazon-logo.svg";
import sellerCentralLogo from "../../assets/svgs/amazon-seller-central.svg";
import vendorCentralLogo from "../../assets/svgs/amazon-vendor-central.svg";

import { amazonAuthorizeApi } from "../../apis/amazonAuthorize";
import { useDeviceType } from "../../hooks/useDeviceType";
import AppBar from "../../components/Molecules/AppBar";
import MTypography from "../../components/Atoms/MTypography";

import { URL_LANDING_PAGE, URL_PRODUCTS } from "../../routes/routes-path";
import { navigatePage } from "../../utils/helpers/common";
import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { PartnerType, SettingsEnum, TabsEnum } from "../../utils/constants/enums";

import classes from "./index.module.css";

export default function Amazon() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedpartnerType, setSelectedPartnerType] = useState<string | null>(null);
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();

  const handleNavigateToDesiredView = (handleNavigateToDesiredView: string, sellingPartnerId: string) => {
    if (handleNavigateToDesiredView === "multi_authorize_accounts_view") {
      navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AMAZON_CONNECTIONS}`, navigate, {
        replace: true,
        state: {
          openDetailsDialog: sellingPartnerId ? true : false,
          sellingPartnerId: sellingPartnerId,
        },
      });
      document.title = capitalizeEachLetter(TabsEnum.PRODUCTS.replace("-", " "));
    } else {
      navigatePage(URL_PRODUCTS, navigate, { replace: true });
      document.title = capitalizeEachLetter(TabsEnum.PRODUCTS.replace("-", " "));
    }
  };

  const makeApiCall = async (payload: any) => {
    setIsLoading(true);
    const searchParamsObject = Object?.fromEntries([...searchParams]);
    const { authorizeViewType, ...restPayload } = payload;

    amazonAuthorizeApi
      .logAmazon(searchParamsObject)
      .then((res: any) => {
        console.log("res : ", res);
      })
      .catch((error: any) => {
        console.log("res : ", error);
      });

    amazonAuthorizeApi
      .loginAmazon(restPayload)
      .then((res: any) => {
        console.log("response ***** ", res);
        setTimeout(() => {
          toast.success("You have successfully authorized access to your Amazon account. Thank you for trusting us.");
        }, 3000);
        handleNavigateToDesiredView(authorizeViewType, restPayload.selling_partner_id);
      })
      .catch((error: any) => {
        console.log("error ***** ", error);
        setTimeout(() => {
          toast.error("Amazon authorization failed! Please try again after some time!");
        }, 3000);
        handleNavigateToDesiredView(authorizeViewType, "");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const spapi_oauth_code = searchParams.get("spapi_oauth_code");
    const state = searchParams.get("state");
    const selling_partner_id = searchParams.get("selling_partner_id");
    const localAuthStateVal = localStorage.getItem("authState");
    const partner_type = localStorage.getItem("partner_type");
    const authorizeViewType = localStorage.getItem("authorize_view");

    setSelectedPartnerType(partner_type);

    if (state && spapi_oauth_code && selling_partner_id) {
      const payload = { spapi_oauth_code, state, selling_partner_id, partner_type, authorizeViewType };
      if (localAuthStateVal === state) {
        makeApiCall(payload);
      } else {
        toast.error("Unable to authorize! Please try again later.");
      }
    }
  }, [searchParams]);

  return (
    <div>
      {isDesktop && <AppBar hideAuthorizeBar={true} />}
      {!isLoading && (
        <div
          className={`d-flex w-100 justify-content-center gap-3 ${
            isDesktop ? "pt-5" : "flex-column align-items-center pt-3"
          }`}
        >
          <div className={`${classes.LogoContainer} ${!isDesktop && classes.LogoContainerM}`}>
            <img src={Applogo} alt="app-logo" style={{ height: isDesktop ? "50px" : "25px" }} />
          </div>

          <div style={{ textAlign: "center", position: "relative" }}>
            <img
              src={connectionSwitchAnim}
              alt={"connection"}
              style={{ objectFit: "contain", zIndex: 1, height: isDesktop ? "90%" : "150px" }}
            />
            <MTypography variant="subtitle1" customClass={classes.Authorizing}>
              Authorizing...
            </MTypography>
          </div>

          <div className={`${classes.LogoContainer} ${!isDesktop && classes.LogoContainerM}`}>
            <img
              src={
                selectedpartnerType === PartnerType.VENDOR
                  ? vendorCentralLogo
                  : selectedpartnerType === PartnerType.SELLER
                  ? sellerCentralLogo
                  : amazonLogo
              }
              alt="app-logo"
              style={{ height: isDesktop ? "50px" : "25px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
