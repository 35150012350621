export enum ProductActionTypes {
  setProducts = "setProducts",
  loadProducts = "loadProducts",
  setOpportunityProducts = "setOpportunityProducts",
  loadOpportunityProducts = "loadOpportunityProducts",
  resetProducts = "resetProducts",
  resetOpportunityProducts = "resetOpportunityProducts",
  setTags = "setTags",
  setSelectedKeywords = "setSelectedKeywords",
  setPriortiseKeywords = "setPriortiseKeywords",
  setSellingKeywords = "setSellingKeywords",
  resetKeywords = "resetKeywords",
  setAsinKeywordHistory = "setAsinKeywordHistory",
  setTotalProduct = "setTotalProduct",
  setTotalOpportunityProduct = "setTotalOpportunityProduct",
  setActiveBrandTab = "setActiveBrandTab",
  setOpportunityStats = "setOpportunityStats",
  setChangeKeywordsProductOwnerId = "setChangeKeywordsProductOwnerId",
  setProductsPagination = "setProductsPagination",
  setParentVariationPagination = "setParentVariationPagination",
  setTotalProductsStatus = "setTotalProductsStatus",
  setProductSearchFilter = "setProductSearchFilter",
  setVariationsProductSearchFilter = "setVariationsProductSearchFilter",
  setProductSearchReset = "setProductSearchReset",
  setVariationsProductSearchReset = "setVariationsProductSearchReset",
  setWaitingProductsRefetch = "setWaitingProductsRefetch",
}
