import React from "react";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import classes from "./index.module.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import AmazonAccountStatus from "../../../../Molecules/AmazonAccountStatus";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";

export interface AmazonConnectionsTableProps {
  // handleDelete: (connectionID: string) => void;
  handleEdit: (connectionRow: any) => void;
  pageSize: number;
  pageNumber: number;
  dataList?: any[];
}

const AmazonConnectionsTable: React.FC<AmazonConnectionsTableProps> = (props) => {
  const {
    // handleDelete,
    pageNumber,
    pageSize,
    handleEdit,
    dataList = [],
  } = props;

  return (
    <div className={classes.Container}>
      <TableContainer classes={{ root: classes.TableContainer }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Name
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Account Type
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Account ID
              </TableCell>
              {/* <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Last Authorized
              </TableCell> */}
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                Status
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: true ? "350px" : "400px" }}>
                {""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(pageSize > 0 ? dataList?.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize) : dataList)?.map(
              (item: any, i: number) => (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={i}>
                  <TableCell align="left" component="th" scope="row">
                    <span
                      style={{ maxWidth: "300px", overflow: "hidden", display: "block" }}
                      className={classes.CellLinesLimitContainer}
                    >
                      {item.name ?? "N/A"}
                    </span>
                  </TableCell>

                  <TableCell classes={{ root: classes.TableCells }} align="left">
                    {item?.vendorCode
                      ? "Vendor Central"
                      : item?.partnerType
                      ? capitalizeEachLetter(`${item?.partnerType} Central` || "")
                      : "Seller Central"}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="left">
                    {item.sellingPartnerID ?? "N/A"}
                  </TableCell>
                  {/* <TableCell classes={{ root: classes.TableCells }} align="left">
                    {item.lastAuthorized ?? "N/A"}
                  </TableCell> */}
                  <TableCell classes={{ root: classes.TableCells }} align="left">
                    <AmazonAccountStatus type={"Active"} showInfo={false} />
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="left">
                    <div className={"d-flex w-100 gap-2 align-items-center"}>
                      <div>
                        <Tooltip title={"Edit"} placement="top">
                          <EditIcon
                            onClick={() => {
                              handleEdit(item);
                            }}
                            color="secondary"
                            style={{ cursor: "pointer", color: "rgb(230, 181, 64)" }}
                          />
                        </Tooltip>
                      </div>
                      {/*                       
                        <div>
                          <Tooltip title={"Delete"} placement="top">
                            <DeleteIcon
                              onClick={() => {
                                handleDelete(item?.id);
                              }}
                              color="secondary"
                              style={{ cursor: "pointer", color: "rgb(230, 181, 64)" }}
                            />
                          </Tooltip>
                        </div>
                       */}
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AmazonConnectionsTable;
