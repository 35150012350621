import * as React from "react";
import { DialogProps } from "@mui/material/Dialog";
import { Grid, LinearProgress } from "@mui/material";
import "./keywordsPageDialoge.style.scss";
import DialogeBox from "..";
import { generatingProcessTimeEightToTenMinutes } from "../../../../utils/constants/common";

interface KeywordsPageDialogePros extends DialogProps {
  title?: string;
}

const KeywordsPageDialoge = (props: KeywordsPageDialogePros) => {
  return (
    <DialogeBox
      open={props.open}
      onClose={props.onClose}
      className="keywords-page-dialoge"
      title={"Generating Product"}
      confirmButton={{
        action: props.onClose as any,
        element: "OK",
      }}
    >
      <Grid className="keywords-page-dialoge">
        {props.title ? props.title : generatingProcessTimeEightToTenMinutes}
        <LinearProgress />
      </Grid>
    </DialogeBox>
  );
};
export default KeywordsPageDialoge;
