import React, { useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import classes from "../../Organisms/ProductDetails/ProductDetailsSwipeableTabs/ProductDetailsTab/TabCard/index.module.css";
import MTypography from "../MTypography";
import { Tooltip } from "@mui/material";
import { copyText } from "../../../utils/helpers/common";
import { useDeviceType } from "../../../hooks/useDeviceType";

interface CopyButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const { text } = props;
  const { isDesktop } = useDeviceType();
  const [copiedTooltipText, setCopiedTooltipText] = useState<string>("");

  const handleCopyClick = (e: any) => {
    setCopiedTooltipText("Copied");
    setTimeout(() => {
      setCopiedTooltipText("");
    }, 2000);
    copyText(text, isDesktop);
    e.stopPropagation();
  };

  return (
    <>
      <Tooltip title={copiedTooltipText} arrow placement={"top"}>
        <div className={"d-flex align-items-center cursor-pointer HoverEffectForButton"} onClick={handleCopyClick}>
          <ContentCopyOutlinedIcon className={classes.CopyIcon} />
          <div>
            <MTypography variant={"subtitle2"} customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}>
              {"Copy"}
            </MTypography>
          </div>
        </div>
      </Tooltip>
    </>
  );
};
export default CopyButton;
