import React from 'react'

import classes from './PublishHistoryCard.module.css'
import PublishErrorAlert from "../../PublishErrorCard/PublishErrorAlert/PublishErrorAlert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ContentStatusEnum } from "../../../../../utils/constants/enums";
import ProductStatus from "../../../../Molecules/ProductStatus";
import { extractAndCapitalizeLastWords, formateDateForHistory } from "../../PublishErrorCard/PublishErrorCard.config";
import MuiTooltip from '../../../../Atoms/MuiTooltip';


interface PublishHistoryCardProps {
  isError?: boolean;
  history?: any;
  content?: any
}

const PublishHistoryCard:React.FC<PublishHistoryCardProps> = ({isError=false,history,content}) => {
  const errorObject = content
  const errorHistory = history
  const submittedContent = extractAndCapitalizeLastWords(errorHistory?.payload?.patches)

  return <div className={classes.CardShell}>

    <div className={'d-flex justify-content-between mb-3'}>
      <div className={'d-flex flex-column w-50 flex-grow-1 gap-2'}>
        <p className={classes.ContentLightTitle}>SKU</p>
        <p className={classes.ContentDark}>{errorHistory?.response?.sku}</p>
        <div className={'w-25'}>
          {isError ? <ProductStatus variant={"small"}  type={ContentStatusEnum.REJECTED} /> :
            <ProductStatus variant={"small"}  type={ContentStatusEnum.ACCEPTED} />}
        </div>
      </div>
      <div className={'d-flex flex-column w-50 flex-grow-1 gap-2'}>
        <p className={classes.ContentLightTitle}>Publish Attempted:</p>
        <p className={classes.ContentDark}>{formateDateForHistory(errorHistory.updatedAt)}</p>
      </div>
      <div className={'d-flex flex-column w-50 gap-2 ' + classes.BorderLeft}>
        <p className={classes.ContentLightTitle}>Submitted Content: 
          <MuiTooltip arrow content={"The content elements you attempted to publish."} placement="top">
            <InfoOutlinedIcon style={{ fontSize: '14px', marginTop: '1px' }} />
          </MuiTooltip>
        </p>
        <p className={classes.ContentDark}>{submittedContent.join(', ')}</p>
      </div>
    </div>

    {isError && <PublishErrorAlert
      code={errorObject.code}
      message={errorObject.message}
      title={`Error ${errorObject.code}, ${errorObject.message}`}
      isAdditionalSupport />}


  </div>
}

export default PublishHistoryCard