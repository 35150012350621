import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import MButton from "../../../Atoms/MButton";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { handleBookMeetingClick } from "../../../../utils/helpers/common";
import classes from "./UpgradeNowBanner.module.css";

interface UpgradeNowBannerProps {
  limit?: number;
}
const UpgradeNowBanner: React.FC<UpgradeNowBannerProps> = ({ limit = 0 }) => {
  const { isMobile, isDesktop } = useDeviceType();

  const getContent = () => {
    if (isMobile) {
      return `You've hit your ${limit} ASINs limit.`;
    } else {
      // return `As a trial user, you've hit your search limit ${limit} ASINs`
      return `You've reached your ${limit} ASINs limit. Upgrade now for unlimited possibilities!`;
    }
  };
  return (
    <>
      <div className={isDesktop ? classes.Container : classes.ContainerMobile}>
        <div
          className={`${isDesktop ? "d-flex align-items-center gap-2" : "d-flex align-items-center gap-2"} ${
            classes.TextContainer
          }`}
        >
          <InfoOutlinedIcon style={{ color: "#0052EA" }} />
          <div className={classes.Text}>{getContent()}</div>
        </div>
        <div
          className={isDesktop ? `${classes.Button} d-flex justify-content-end` : `w-100 d-flex justify-content-end`}
        >
          <MButton
            size={"small"}
            variant="contained"
            onClick={handleBookMeetingClick}
            rootClass={isDesktop ? classes.Button : classes.BookMeetingButtonM}
          >
            Book a Meeting
          </MButton>
        </div>
      </div>
    </>
  );
};

export default UpgradeNowBanner;
