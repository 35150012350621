import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTheme } from "@mui/material/styles";
import { DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";

import classes from "./index.module.css";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { Form, Formik } from "formik";
import {
  validationSchemaForVendorCentral,
  validationSchemaForSellerCentral,
  authorizedAccountNameAndVendorCodeObject,
  authorizedAccountInputNames,
} from "./config";
import FormTextField from "../../../Molecules/FormTextField";
import MButton from "../../../Atoms/MButton";
import MTypography from "../../../Atoms/MTypography";
import { amazonAuthorizeApi } from "../../../../apis/amazonAuthorize";
import { toast } from "react-toastify";
import { SUCC_CONNECTION_DETAILS_UPDATED } from "../../../../utils/constants/messages/success";
import { getAuthorizedAmazonConnectionsListDispath } from "../../../../store/amazonAuthorize/amazonAuthorize.actions";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../../../utils/helpers/apis";
import { PartnerType } from "../../../../utils/constants/enums";
import { Link } from "react-router-dom";

type AmazonAcountNameAndVendorDialogProps = DialogProps & {
  title?: string;
  open: boolean;
  onClose: () => void;
  selectedAccountDetails: any;
};

const AmazonAcountNameAndVendorDialog = (props: AmazonAcountNameAndVendorDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { isDesktop } = useDeviceType();

  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const submitForm = async (values: authorizedAccountNameAndVendorCodeObject) => {
    if (props.selectedAccountDetails._id && props.selectedAccountDetails.sellingPartnerID) {
      setLoading(true);
      try {
        const payload = {
          id: props.selectedAccountDetails._id,
          name: values.name,
          vendorCode: props.selectedAccountDetails.partnerType === PartnerType.VENDOR ? values.vendorCode : "",
          sellingPartnerID: props.selectedAccountDetails.sellingPartnerID,
        };
        await amazonAuthorizeApi.updateAuthorizedAccount(payload);
        toast.success(SUCC_CONNECTION_DETAILS_UPDATED);
        setLoading(false);
        dispatch(getAuthorizedAmazonConnectionsListDispath());
      } catch (error) {
        console.log(error);
        toast.error(errorHandler(error));
        setLoading(false);
      }
    } else {
      toast.error("Selling Partner ID cannot be null");
    }
  };

  const isVendorCentral =
    props.selectedAccountDetails?.partnerType === PartnerType.VENDOR || props.selectedAccountDetails?.vendorCode;

  const formInitialValues = isVendorCentral
    ? {
        name: props.selectedAccountDetails?.name ? props.selectedAccountDetails?.name : "",
        vendorCode: props.selectedAccountDetails?.vendorCode ? props.selectedAccountDetails?.vendorCode : "",
      }
    : {
        name: props.selectedAccountDetails?.name ? props.selectedAccountDetails?.name : "",
      };

  const validationSchema = isVendorCentral ? validationSchemaForVendorCentral : validationSchemaForSellerCentral;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          maxWidth: "528px",
        },
      }}
    >
      {props?.title && (
        <DialogTitle className={classes.Title}>
          <div className="flex-grow-1">{props.title}</div>
          <IconButton
            sx={{
              padding: 0,
              color: "inherit",
              "&:hover": {
                background: "none",
              },
            }}
            onClick={props.onClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={`${isDesktop ? classes.BodyContainer : ""}`}>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, dirty }) => (
            <Form
              autoComplete="off"
              className="d-flex flex-column justify-content-between"
              style={{ minHeight: "100%" }}
            >
              <div className={isDesktop ? "d-flex flex-column" : "d-flex flex-column"}>
                <MTypography variant={"body1"} customClass={`${classes.SubHeading} ${isDesktop ? "" : ""}`}>
                  Please enter a unique name.
                  {isVendorCentral ? "And provide your Vendor Code (sellingPartnerID)." : ""}
                </MTypography>
                <FormTextField name={authorizedAccountInputNames.name} label={"Enter name"} />
                {isVendorCentral && (
                  <>
                    <FormTextField name={authorizedAccountInputNames.vendorCode} label={"Enter Your Vendor Code"} />
                    <Link to={"https://www.automatoai.com/blog/how-to-find-your-amazon-vendor-code"} target="_blank" style={{textDecoration: "none"}}>
                      <MTypography variant="body2" color="primary" customClass={classes.FindVendorCodeText}>
                        Need help? Learn where to find your Vendor Code?
                      </MTypography>
                    </Link>
                  </>
                )}
              </div>
              <div className="pt-5">
                <Divider />
                <div className={"gap-3 d-flex align-items-center justify-content-end p-3"}>
                  <MTypography variant={"h2"} customClass={classes.Cancel}>
                    <div onClick={props.onClose as any}>{"Cancel"}</div>
                  </MTypography>
                  <MButton size={"small"} variant={"contained"} disabled={loading || !dirty} onClick={handleSubmit}>
                    <div className={"d-flex align-items-center gap-1"}>{loading ? "Loading" : "Save"}</div>
                  </MButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AmazonAcountNameAndVendorDialog;
