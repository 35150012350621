// reference : // https://github.com/louis-young/keyword-density-calculator/blob/master/src/data/constants.js

import { insignificantWords } from "./insignificantWords";

export const acceptablePercentage = 5;
export const acceptableTitleCount = 2;

const convertWordsToLowerCase = (words: any) => {
    const lowerCaseWords = words?.map((word: any) => word?.toLowerCase());

    return lowerCaseWords;
};

const splitWords = (text: any) => {
    // Match words with hyphens and possessive apostrophes as whole words
    const words = text?.match(/\b(\w+(?:-\w+|'\w+)?)\b/g) || [];
    return words;
};


const getKeywords = (words: any) => {
    const keywords = words?.filter((word: any) => !insignificantWords.has(word) && isNaN(word));

    return keywords;
};

const calculateOccurrences = (words: any) => {
    const occurrences = words?.reduce((accumulator: any, word: any) => {
        accumulator[word] ? accumulator[word]++ : (accumulator[word] = 1);

        return accumulator;
    }, {});

    return occurrences;
};

const sortOccurrencesDescending = (occurrences: any) => {
    const sortedOccurrences = Object.entries(occurrences)?.sort(([, a]: any, [, b]: any) => b - a);

    return sortedOccurrences;
};

const calculatePercentage = (occurrences: any, total: any) => {
    const percentage = (occurrences / total) * 100;

    const roundedPercentage = Math.round(percentage * 100) / 100;

    return roundedPercentage;
};

const addDensityPercentile = (occurrence: any, words: any) => {
    const [word, occurrences] = occurrence;

    const density = calculatePercentage(occurrences, words.length);

    return { word, occurrences, density };
};

// export const calculateKeywordDensities = (title: string, bulletPointsList: string[], description: string) => {
export const calculateKeywordDensities = (data: string | string[]) => {
    // const combinedText = `${title} ${description} ${bulletPointsList?.join(' ')}`;
    let dataString: string = "";
    if (Array.isArray(data)) {
        dataString = data?.join(' ')
    } else {
        dataString = data;
    }
    const normalizedText = dataString?.replace(/\s\s+/g, ' ');

    const words = splitWords(normalizedText);

    const lowerCaseWords = convertWordsToLowerCase(words);

    const keywords = getKeywords(lowerCaseWords);

    const occurrences = calculateOccurrences(keywords);

    const sortedOccurrences = sortOccurrencesDescending(occurrences);

    const densities = sortedOccurrences.map((occurrence) => addDensityPercentile(occurrence, words));
    const isAnyExceeded = densities.some((item: any) => item?.density > acceptablePercentage);

    const dataList = { densities, isAnyExceeded }
    return dataList;
};
