import { Dispatch } from "redux";
import { generateProductApis } from "../../../apis/generate-product";
import { KeywordsApis } from "../../../apis/keywords";
import { CompleteProduct, ProductEventsListInterface, ProductEvent, ProductEventsHistoriesPayload, ProductEventsListMessage } from "../../../apis/types/generate-product";
import { errorHandler, transformKeywordsHistory, transformProductEventsHistoryResponse } from "../../../utils/helpers/apis";
import { ContentStatusEnum, ProductDetailsEditableKeys } from "../../../utils/constants/enums";
import { toast } from "react-toastify";
import {
  getSelectedTitleVariation,
  longTextNumber,
  maxDescriptionLength,
  maxSearchTermLength,
  maxTitleLength,
} from "../../../components/Organisms/ProductDetails/ProductDetailsSwipeableTabs/ProductDetailsTab/config";
import { configureEventsResponse } from "../../../components/Organisms/ProductDetails/ReactBigCalendar/config";

export enum ProductDetailsActionTypes {
  setCompleteProduct = "setCompleteProduct",
  setProductEventHistory = "setProductEventHistory",
  setProductEventHistoryMessage = "setProductEventHistoryMessage",
}

export interface SetCompleteProduct {
  type: ProductDetailsActionTypes.setCompleteProduct;
  payload: CompleteProduct;
}

export const completeProductDispatch = (completeProduct: CompleteProduct): SetCompleteProduct => {
  return {
    type: ProductDetailsActionTypes.setCompleteProduct,
    payload: completeProduct,
  };
};

const mappingObject = {
  [ProductDetailsEditableKeys.TITLE]: "title",
  [ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT]: "bulletPoints",
  [ProductDetailsEditableKeys.DESCRIPTION]: "description",
  [ProductDetailsEditableKeys.SEARCH_TERMS]: "searchTerm",
  [ProductDetailsEditableKeys.BULLET_POINTS]: "simpleBulletPoints",
};

export const getCompleteProductAction =
  ({ productASIN, userID }: any, refreshData?: boolean): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      let promises = [
        generateProductApis.getCompleteProduct(productASIN, userID),
        KeywordsApis.getKeywordTracking({
          productASIN,
          userID,
        }),
      ];
      if (refreshData) {
        promises.pop();
      }
      const allResp = await Promise.all(promises);
      let product: CompleteProduct = {} as CompleteProduct;
      if (allResp[0]?.productASIN) {
        product = allResp[0];
      }
      if (allResp[1]?.length) {
        product = { ...product, trackingKeywords: transformKeywordsHistory(allResp[1], productASIN) };
      }

      product = {
        ...product,

        aiGeneratedProduct: {
          ...product.aiGeneratedProduct,
          [ProductDetailsEditableKeys.TITLE]:
            product?.status === ContentStatusEnum.APPROVED
              ? product?.editedGeneratedProductContent?.titleVariations?.find(e => e.selected && e.title)?.title || ""
              : product?.titleVariations?.find(e => e.selected && e.title)?.title || "",
          [ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT]:
            product?.status === ContentStatusEnum.APPROVED
              ? product?.editedGeneratedProductContent?.bulletPoints || []
              : product?.bulletPointsByChat || [],
          [ProductDetailsEditableKeys.DESCRIPTION]: 
            product?.status === ContentStatusEnum.APPROVED
              ? product?.editedGeneratedProductContent?.description
              : product?.generatedDescription,
          [ProductDetailsEditableKeys.SEARCH_TERMS]: 
            product?.status === ContentStatusEnum.APPROVED
              ? product?.editedGeneratedProductContent?.searchTerm
              : product?.searchTerm, 
        },
        editedProduct: {
          ...product.editedProduct,
          [ProductDetailsEditableKeys.TITLE]:
            // product?.editedGeneratedProductContent?.title || product?.generatedTitle || "",
            getSelectedTitleVariation(product),
          [ProductDetailsEditableKeys.SEARCH_TERMS]:
            product?.editedGeneratedProductContent.searchTerm || product?.searchTerm || "",
          [ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT]:
            product?.editedGeneratedProductContent?.bulletPoints || product?.bulletPointsByChat || [],
          [ProductDetailsEditableKeys.DESCRIPTION]:
            product?.editedGeneratedProductContent?.description || product?.generatedDescription || "",
        },
        // same data is being used while publishing on amazon
        approvedProduct: {
          ...product.approvedProduct,
          [ProductDetailsEditableKeys.TITLE]:
            product?.status === ContentStatusEnum.APPROVED || ContentStatusEnum.PUBLISHED ? product?.generatedTitle : "",
          [ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT]:
            product?.status === ContentStatusEnum.APPROVED || ContentStatusEnum.PUBLISHED ? product?.bulletPointsByChat || [] : [],
          [ProductDetailsEditableKeys.DESCRIPTION]:
            product?.status === ContentStatusEnum.APPROVED || ContentStatusEnum.PUBLISHED ? product?.generatedDescription || "" : "",
          [ProductDetailsEditableKeys.SEARCH_TERMS]:
            product?.status === ContentStatusEnum.APPROVED || ContentStatusEnum.PUBLISHED ? product?.searchTerm || "" : "",
        },
      };

      if (product?.status !== ContentStatusEnum.APPROVED) {
        product = {
          ...product,
          [`${ProductDetailsEditableKeys.TITLE}Edited`]:
            // product?.editedGeneratedProductContent?.title || getSelectedTitleVariation(product),  // product?.generatedTitle,
            getSelectedTitleVariation(product),
          [`${ProductDetailsEditableKeys.DESCRIPTION}Edited`]:
            product?.editedGeneratedProductContent?.description || product?.generatedDescription,
          [`${ProductDetailsEditableKeys.SEARCH_TERMS}Edited`]:
            product?.editedGeneratedProductContent?.searchTerm || product?.searchTerm,
          [`${ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT}Edited`]:
            product?.editedGeneratedProductContent?.bulletPoints || product?.bulletPointsByChat,
        };
      }
      dispatch(completeProductDispatch(product));
      return product;
    } catch (error) {
      return error;
    }
  };
export const getProductLiveCheckAction =
  (product: CompleteProduct): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      let productWithLiveCheck: CompleteProduct = {} as CompleteProduct;
      let productLive = await generateProductApis.checkProductsLive(
        [{ productASIN: product?.productASIN as string, userID: product?.userID }],
        true
      );
      if (productLive?.productASIN) {
        productWithLiveCheck = {
          ...product,
          productLive: productLive?.productLive,
          showLiveCheck: true,
        };
        dispatch(completeProductDispatch(productWithLiveCheck));
      }
      return productWithLiveCheck;
    } catch (error) {
      return error;
    }
  };
export const setHighlightedKeywordAction =
  (product: CompleteProduct, highlightedKeyword: string, highlightClass?: string): any =>
  (dispatch: Dispatch<any>) => {
    try {
      let productWithHighlightedKeyword = {
        ...product,
        highlightedKeyword,
        highlightClass,
      };
      dispatch(completeProductDispatch(productWithHighlightedKeyword));

      return productWithHighlightedKeyword;
    } catch (error) {
      return error;
    }
  };

export const handleEditModeForDetailPageAction =
  (product: CompleteProduct, editKey: ProductDetailsEditableKeys, status: boolean): any =>
  (dispatch: Dispatch<any>) => {
    try {
      if (!status) {
        const editingKey: "title" | "bulletPoints" | "description" = mappingObject[editKey] as
          | "title"
          | "bulletPoints"
          | "description";
        product = {
          ...product,
          [`${editKey}Edited`]: product?.editedGeneratedProductContent[editingKey] || product[editKey],
        };
      }
      dispatch(completeProductDispatch({ ...product, [`${editKey}EditMode`]: status }));
    } catch (error: any) {
      throw new Error(error);
    }
  };

export const handleEditingForDetailPageAction =
  (product: CompleteProduct, editKey: ProductDetailsEditableKeys, value: string, bulletIndex?: number): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      if (bulletIndex || bulletIndex === 0) {
        let editedArray = [...product[`${editKey}Edited`]];
        editedArray[bulletIndex] = value;
        dispatch(completeProductDispatch({ ...product, [`${editKey}Edited`]: editedArray }));
      } else {
        dispatch(completeProductDispatch({ ...product, [`${editKey}Edited`]: value }));
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };
export const saveEditedValueForDetailPageAction =
  (product: CompleteProduct, editKey: ProductDetailsEditableKeys): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      const editedContent = product[`${editKey}Edited`];

      if (editedContent !== undefined) {
        const contentLength = Array.isArray(editedContent)
            ? editedContent.join("").length
              : editedContent.length;

        if (
          (editKey === ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT &&
            Array.isArray(editedContent) &&
            editedContent.some((item) => item.length <= 20 || item.length > longTextNumber)) ||
          (editKey === ProductDetailsEditableKeys.TITLE && (contentLength <= 20 || contentLength > maxTitleLength)) ||
          (editKey === ProductDetailsEditableKeys.DESCRIPTION &&
            (contentLength <= 100 || contentLength > maxDescriptionLength)) ||
          (editKey === ProductDetailsEditableKeys.SEARCH_TERMS && (contentLength <= 20 || contentLength > maxSearchTermLength))
        ) {
          throw new Error(
            `Content length should be between ${
              editKey === ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT
                ? "20"
                : editKey === ProductDetailsEditableKeys.TITLE
                ? "20"
                : editKey === ProductDetailsEditableKeys.SEARCH_TERMS
                ? "20"
                : "100"
            } to ${
              editKey === ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT
                ? longTextNumber
                : editKey === ProductDetailsEditableKeys.TITLE
                ? maxTitleLength
                : editKey === ProductDetailsEditableKeys.SEARCH_TERMS 
                ? maxSearchTermLength
                : maxDescriptionLength
            }`
          );
        }
      } else {
        throw new Error(`Edited content is not defined for ${editKey}`);
      }

        // updating title variation for payload
        let payload : any = {
          productASIN: product.productASIN,
          userID: product.userID,
          [mappingObject[editKey]]: editKey === ProductDetailsEditableKeys.SEARCH_TERMS ? product[`${editKey}Edited`].toLowerCase() : product[`${editKey}Edited`],
        }

        if (editKey === ProductDetailsEditableKeys.TITLE) {
          const titleVariations = product.editedGeneratedProductContent?.titleVariations?.length
          ? product.editedGeneratedProductContent.titleVariations
          : product.titleVariations;

          const updatedTitleVariations = titleVariations.map(variation => ({
            ...variation,
            title: variation.selected ? product[`${editKey}Edited`] : variation.title,
          }));

          payload = {
            productASIN: product.productASIN,
            userID: product.userID,
            titleVariations: updatedTitleVariations,
          };
        }
        const resp = await generateProductApis.editGeneratedProductContent(payload);

        if (resp?.productASIN) {
          toast.success("Product content has been successfully updated.");
          dispatch(
            completeProductDispatch({
              ...product,
              highlightClass: "",
              highlightedKeyword: "",
              [`${editKey}EditMode`]: false,
              status: ContentStatusEnum.EDITED,
              editedGeneratedProductContent: resp?.editedGeneratedProductContent,
              editedProduct: {
                ...product.editedProduct,
                [ProductDetailsEditableKeys.TITLE]:
                  resp?.editedGeneratedProductContent?.title ||
                  product?.editedGeneratedProductContent?.title ||
                  product?.generatedTitle ||
                  "",
                [ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT]:
                  resp?.editedGeneratedProductContent?.bulletPoints ||
                  product?.editedGeneratedProductContent?.bulletPoints ||
                  product?.bulletPointsByChat ||
                  [],
                [ProductDetailsEditableKeys.DESCRIPTION]:
                  resp?.editedGeneratedProductContent?.description ||
                  product?.editedGeneratedProductContent?.description ||
                  product?.generatedDescription ||
                  "",
                [ProductDetailsEditableKeys.SEARCH_TERMS]:
                  resp?.editedGeneratedProductContent?.searchTerm ||
                  product?.editedGeneratedProductContent?.searchTerm ||
                  product?.searchTerm ||
                  "",
              },
            })
          );
        }
      } catch (error: any) {
        throw new Error(errorHandler(error));
      }
    };
export const approveProductContentAction =
  (product: CompleteProduct): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      let payload = {
        productASIN: product.productASIN || "",
        title: getSelectedTitleVariation(product) || "",
        userID: product?.userID || "",
        description: product?.generatedDescriptionEdited || product?.generatedDescription || "",
        searchTerm: product?.editedGeneratedProductContent.searchTerm ? product?.editedGeneratedProductContent.searchTerm : product?.searchTerm || "",
        bulletPoints: product?.bulletPointsByChatEdited?.length
          ? product?.bulletPointsByChatEdited
          : product?.bulletPointsByChat?.length
          ? product?.bulletPointsByChat
          : [],
        titleVariations: product?.editedGeneratedProductContent?.titleVariations ? product?.editedGeneratedProductContent?.titleVariations : product?.titleVariations
      };
      if (product?.isUnApprove) {
        payload = {
          ...payload,
          title: getSelectedTitleVariation(product) || "",
          description:
              product?.editedGeneratedProductContent?.description ||
              product?.generatedDescriptionEdited ||
              product?.generatedDescription ||
              "",
            bulletPoints: product?.editedGeneratedProductContent?.bulletPoints?.length ?
              product?.editedGeneratedProductContent?.bulletPoints :
              product?.bulletPointsByChatEdited?.length
                ? product?.bulletPointsByChatEdited
                : product?.bulletPointsByChat?.length
                  ? product?.bulletPointsByChat
                  : [],
          titleVariations: product?.editedGeneratedProductContent?.titleVariations || product?.titleVariations,
          searchTerm: product?.editedGeneratedProductContent?.searchTerm || product?.searchTerm ||
            "",
        };
      }

      const resp = await generateProductApis.approveProductContent(payload);

      if (resp) {
        dispatch(getCompleteProductAction({ productASIN: product?.productASIN, userID: product?.userID }));
      } else throw new Error("Can't approve product content");

      return product;
    } catch (error) {
      throw error;
    }
  };



export interface SetProductEventHistory {
  type: ProductDetailsActionTypes.setProductEventHistory;
  payload: ProductEventsListInterface
}

export const setProductEventsHistoryDispatch = (eventsHistory: ProductEventsListInterface): SetProductEventHistory => {
  return {
    type: ProductDetailsActionTypes.setProductEventHistory,
    payload: eventsHistory,
  };
};

export const getProductEventsHistoryAction =
  (payload: ProductEventsHistoriesPayload): any =>
    async (dispatch: Dispatch<any>) => {
      try {
        let eventsHistoryData: ProductEvent[] = await generateProductApis.getProductEventsHistories(payload);
        const updatedEventDataWithColors = configureEventsResponse(eventsHistoryData || [], false)
        const transformedData = transformProductEventsHistoryResponse(updatedEventDataWithColors) 
        dispatch(setProductEventsHistoryDispatch(transformedData))
        return eventsHistoryData;
      } catch (error) {
        throw error;
      }
    };

    export interface SetProductEventHistoryMessage {
      type: ProductDetailsActionTypes.setProductEventHistoryMessage;
      payload: ProductEventsListMessage
    }
    
    export const setProductEventsHistoryMessageDispatch = (message: ProductEventsListMessage) => {
      return {
        type: ProductDetailsActionTypes.setProductEventHistoryMessage,
        payload: message,
      };
    }
    
export type ProductsDetailsReducerActionType =
  | SetCompleteProduct
  | SetProductEventHistory
  | SetProductEventHistoryMessage;
