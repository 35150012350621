import React, { useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Divider } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useDeviceType } from "../../../../../hooks/useDeviceType";
import {
  ageGroupOptions,
  BrandVoiceObject,
  genderOptions,
  incomeBracketOptions,
  initialValue,
  inputName,
  primaryUsageOptions,
  toneAndStyleOptions,
  validationSchemaForBrandAlias,
} from "./config";
import FormTextField from "../../../../Molecules/FormTextField";
import MButton from "../../../../Atoms/MButton";
import CardOverlay from "../../../../Atoms/CardOverlay";
import FormRadioGroup from "../../../../Molecules/FormRadioGroup";
import FormCheckBoxButton from "../../../../Molecules/FormCheckboxButton";
import FormTitleWithLabel from "../../../../Molecules/FormTitleWithLabel";
import { SelectedBrandAliasSelector } from "../../../../../store/brands/brands.selector";
import { BrandApis } from "../../../../../apis/brands";
import { SUCC_BRAND_CREATED, SUCC_BRAND_UPDATED } from "../../../../../utils/constants/messages/success";
import { errorHandler } from "../../../../../utils/helpers/apis";
import { navigatePage } from "../../../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../../../utils/constants/enums";
// import MTypography from "../../../../Atoms/MTypography";

// import classes from "./index.module.css";

interface BrandVoiceFormProps {
  isBrandAliasForm?: boolean;
}

const BrandVoiceForm: React.FC<BrandVoiceFormProps> = (props) => {
  const { isBrandAliasForm = false } = props;

  const { isDesktop } = useDeviceType();

  const selectedBrandAlias = useSelector(SelectedBrandAliasSelector);

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}`, navigate);
  };

  let initValues = initialValue;

  let validationSchema;

  if (isBrandAliasForm) {
    validationSchema = validationSchemaForBrandAlias;
  }

  if (selectedBrandAlias) {
    initValues = {
      ...initValues,
      name: selectedBrandAlias?.name,
      alias: selectedBrandAlias?.alias,
      voice: selectedBrandAlias?.voice || "",
    };
  }

  const submitForm = async (values: BrandVoiceObject) => {
    try {
      setLoading(true);
      if (isBrandAliasForm) {
        if (selectedBrandAlias) {
          await BrandApis.updateBrand({
            name: values?.name,
            alias: values?.alias,
            brandID: selectedBrandAlias?._id,
            userID: selectedBrandAlias?.userID,
            voice: values?.voice,
            // status: !!values?.alias,
            status: false,
          });
          toast.success(SUCC_BRAND_UPDATED);
        } else {
          await BrandApis.createBrand({
            name: values?.name,
            alias: values?.alias,
            voice: values?.voice,
            status: false,
          });
          toast.success(SUCC_BRAND_CREATED);
        }
      }
      setLoading(false);
      navigateToSettingsTab();
    } catch (e) {
      setLoading(false);
      toast.error(errorHandler(e));
    }
  };

  return (
    <div>
      <Formik enableReinitialize validationSchema={validationSchema} initialValues={initValues} onSubmit={submitForm}>
        {({ handleSubmit, values, dirty }) => (
          <Form autoComplete="off">
            <div className={`${isDesktop && "pt-4"} px-4`}>
              {isBrandAliasForm ? (
                <>
                  <div className={"mt-2"}>
                    <FormTitleWithLabel title="Brand Alias Management" label={""} />
                    <FormTitleWithLabel isSubtitle title="Choose a name for your brand's internal use." label={""} />
                    <CardOverlay>
                      <div>
                        <div className={"mx-4"}>
                          <FormTextField
                            fullWidth
                            name={inputName.name}
                            label={"Brand Name*"}
                            disabled={selectedBrandAlias}
                          />
                        </div>
                        <div className={"mx-4"}>
                          <FormTextField fullWidth name={inputName.alias} label={"Brand Alias"} />
                        </div>
                      </div>
                    </CardOverlay>
                  </div>
                  {/* <div className={"mt-4"}>
                    <FormTitleWithLabel title="Brand Voice Management" label={""} />
                    <FormTitleWithLabel isSubtitle title="Describe how your brand speaks to customers." label={""} />
                    <CardOverlay>
                      <div>
                        <div className={"mx-4"}>
                          <FormTitleWithLabel title="Description" label={""} />
                          <MTypography variant={"subtitle2"} customClass={`${classes.Limit} px-4 text-end mb-1`}>
                            {values?.voice?.length}/2000
                          </MTypography>
                          <FormTextField multiline rows={5} fullWidth name={inputName.voice} label={"Brand Voice"} />
                        </div>
                      </div>
                    </CardOverlay>
                  </div> */}
                </>
              ) : (
                <>
                  <div>
                    <FormTitleWithLabel title="Your Market" label={""} />
                    <CardOverlay>
                      <div>
                        <div className={"mx-4"}>
                          <FormTitleWithLabel title="Target Audience Gender" label={""} />
                          <FormRadioGroup name={inputName.gender} options={genderOptions} label={""} />
                        </div>
                        <Divider className={"my-2"} />
                        <div className={"mx-4"}>
                          <FormTitleWithLabel title="Age Group" label={"Select all that apply"} />
                          <FormCheckBoxButton name={inputName.ageGroup} options={ageGroupOptions} />
                        </div>
                        <Divider className={"my-2 mt-4"} />
                        <div className={"mx-4"}>
                          <FormTitleWithLabel title="Income Bracket" label={""} />
                          <FormRadioGroup name={inputName.incomeBracket} options={incomeBracketOptions} label={""} />
                        </div>
                        <Divider className={"my-2"} />
                        <div className={"mx-4"}>
                          <FormTitleWithLabel title="Primary Usage Enviorment" label={""} />
                          <FormRadioGroup name={inputName.primaryUsage} options={primaryUsageOptions} label={""} />
                        </div>
                      </div>
                    </CardOverlay>
                  </div>

                  <div className={"mt-2"}>
                    <FormTitleWithLabel title="Your Message" label={""} />
                    <CardOverlay>
                      <div>
                        <div className={"mx-4"}>
                          <FormTitleWithLabel title="Age Group" label={"Select all that apply"} />
                          <FormCheckBoxButton name={inputName.toneAndStyle} options={toneAndStyleOptions} />
                        </div>
                        <Divider className={"my-2 mt-4"} />
                        <div className={"mx-4"}>
                          <FormTitleWithLabel
                            title="Custom Brand Descriptors"
                            label={"Input up to 3, 6 words or less each to describe your brand"}
                          />
                          <div className={"mb-1"}>
                            <FormTextField
                              multiline
                              rows={2}
                              fullWidth
                              name={inputName.descriptor1}
                              label={"Descriptor 1"}
                            />
                          </div>

                          <div className={"mb-1"}>
                            <FormTextField
                              multiline
                              rows={2}
                              fullWidth
                              name={inputName.descriptor2}
                              label={"Descriptor 2"}
                            />
                          </div>

                          <div className={""}>
                            <FormTextField
                              multiline
                              rows={2}
                              fullWidth
                              name={inputName.descriptor3}
                              label={"Descriptor 3"}
                            />
                          </div>
                        </div>
                      </div>
                    </CardOverlay>
                  </div>

                  <div>
                    <FormTitleWithLabel title="Additional Insights" label={""} />
                    <CardOverlay>
                      <div>
                        <div className={"mx-4"}>
                          <FormTitleWithLabel
                            title="Key Selling Points"
                            label={"What are the most important features or benefits of your product?"}
                          />
                          <div className={""}>
                            <FieldArray name={inputName.sellingPoints}>
                              {({ push, remove }: any) => (
                                <>
                                  {values.sellingPoints.map((_sellingPoint: any, index: number) => (
                                    <div key={index}>
                                      <div className={"d-flex gap-2 align-items-center"}>
                                        <div className="d-flex w-100 gap-2">
                                          <FormTextField
                                            fullWidth
                                            name={`sellingPoints.${index}`}
                                            label={"Add Feature/Benefits"}
                                          />
                                        </div>
                                        <Box component={"div"} sx={{ width: { sx: index === 0 ? "" : "40px" }, pt: 1 }}>
                                          {index !== 0 && (
                                            <div onClick={() => remove(index)} className="cursor-pointer">
                                              <CloseRoundedIcon
                                                sx={{ color: "#80828A", fontSize: { xs: "26px", md: "32px" } }}
                                              />
                                            </div>
                                          )}
                                        </Box>
                                      </div>
                                    </div>
                                  ))}
                                  <MButton size={"small"} onClick={() => push("")} variant="outlined">
                                    Add Another
                                  </MButton>
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                        <Divider className={"my-2"} />
                        <div className={"mx-4"}>
                          <FormTitleWithLabel
                            title="Competitor Awareness: OPTIONAL"
                            label={"List any main competitors, if applicable"}
                          />
                          <div className={""}>
                            <FieldArray name={inputName.competitorAwareness}>
                              {({ push, remove }: any) => (
                                <>
                                  {values.competitorAwareness.map((_data: any, index: number) => (
                                    <div key={index}>
                                      <div className={"d-flex gap-2 align-items-center"}>
                                        <div className="d-flex w-100 gap-2">
                                          <FormTextField
                                            fullWidth
                                            name={`competitorAwareness.${index}`}
                                            label={"Add Competitor"}
                                          />
                                        </div>
                                        <Box component={"div"} sx={{ width: { sx: index === 0 ? "" : "40px" }, pt: 1 }}>
                                          {index !== 0 && (
                                            <div onClick={() => remove(index)} className="cursor-pointer">
                                              <CloseRoundedIcon
                                                sx={{ color: "#80828A", fontSize: { xs: "26px", md: "32px" } }}
                                              />
                                            </div>
                                          )}
                                        </Box>
                                      </div>
                                    </div>
                                  ))}
                                  <MButton size={"small"} onClick={() => push("")} variant="outlined">
                                    Add Another
                                  </MButton>
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                    </CardOverlay>
                  </div>
                </>
              )}

              <div className="d-flex justify-content-end mt-4 mb-5">
                <MButton
                  isLoading={loading}
                  size={"small"}
                  variant={"contained"}
                  onClick={handleSubmit}
                  disabled={!dirty}
                >
                  {isBrandAliasForm ? "Save" : "Generate"}
                </MButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BrandVoiceForm;
