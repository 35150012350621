import { Box, Drawer } from "@mui/material";
import React from "react";
import { useTopBarHeight } from "../../../hooks/useTopBarHeight";
import { useDeviceType } from "../../../hooks/useDeviceType";

interface MDrawerProps {
  open: boolean;
  children: React.ReactNode;
  anchor?: "right" | "bottom" | "left" | "top";
  onClose: () => void;
  width?: number | string; // i.e, can be 300 or 300px
  boxShadow?: string;
  backgroundColor?: string;
}

const MDrawer = ({ open, children, anchor = "right", width = "618px", onClose }: MDrawerProps) => {
  const { getTopBarHeight } = useTopBarHeight();
  const { isDesktop } = useDeviceType();

  // getting for both mobile and desktop currently using only for desktop
  const appbarHeight = getTopBarHeight();

  return (
    <>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        sx={{
          marginTop: isDesktop ? appbarHeight.desktop : appbarHeight.mobile,
          "& .MuiPaper-root": {
            backgroundColor: "white",
            marginTop: isDesktop ? appbarHeight.desktop : appbarHeight.mobile,
            boxShadow: "-4px 5px 10px rgba(0, 0, 0, 0.1)",
          },
        }}
        ModalProps={{
          BackdropProps: {
            sx: {
              // marginTop: isDesktop ? appbarHeight.desktop : appbarHeight.mobile,
              background: "none",
            },
          },
        }}
      >
        <Box sx={{ width: isDesktop ? width : "100%", maxHeight: `calc(100% - ${isDesktop ? appbarHeight.desktop : appbarHeight.mobile})` }}>
          {children}
        </Box>
      </Drawer>
    </>
  );
};

export default MDrawer;
