import React from "react";
import classes from "./index.module.css";
import MTypography from "../../../../Atoms/MTypography";

const AccountTypeCard = ({ details, increaseWidth = false }: any) => {
  return (
    <div className={`${classes.CardContainer} ${increaseWidth ? classes.largeCardContainerWidth : ""}`}>
      <img src={details.logo} alt="" />
      <MTypography variant="h6" customClass={`${classes.Title}`}>
        {details.title}
      </MTypography>
      <MTypography variant="subtitle1" customClass={`${classes.Description}`}>
        {details.description}
      </MTypography>
    </div>
  );
};

export default AccountTypeCard;
