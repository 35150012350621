import { useDeviceType } from "../../../hooks/useDeviceType";
import fileIcon from "../../../assets/svgs/accessKeysIcons/face-file-icon.svg";
import MTypography from "../../Atoms/MTypography";
import MButton from "../../Atoms/MButton";
import classes from "./index.module.css";
import React from "react";

interface NoDataAvialableYetViewProps {
  handleClick: () => void;
  heading?: string;
  subHeading?: string;
  buttonName?: string;
}

const NoDataAvialableYetView: React.FC<NoDataAvialableYetViewProps> = (props) => {
  const {
    handleClick,
    heading = "Please pass heading prop with heading.",
    subHeading = "Please pass subHeading prop with subHeading.",
    buttonName = "Button",
  } = props;
  const { isDesktop } = useDeviceType();

  return (
    <div
      className={`mw-100 mh-100 d-flex justify-content-center align-items-center flex-column text-center ${
        isDesktop ? "mt-5" : "mt-5 px-3"
      }`}
    >
      <div>
        <img
          src={fileIcon}
          alt="file-icon"
          style={isDesktop ? { width: "100%", marginTop: "2rem" } : { width: "70%" }}
        />
      </div>

      <MTypography variant="subtitle2" customClass={classes.Title}>
        {heading}
      </MTypography>

      <MTypography variant="subtitle1" customClass={classes.Description}>
        {subHeading}
      </MTypography>

      <div className={classes.ButtonContainer}>
        <MButton variant="contained" fullWidth onClick={handleClick}>
          {buttonName}
        </MButton>
      </div>
    </div>
  );
};

export default NoDataAvialableYetView;
