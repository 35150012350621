import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import productReducer from "./product/product.reducers";
import productDetailsReducers from "./product/productDetails/productDetails.reducers";
import productScrapeReducer from "./product/productScrape/productScrape.reducers";
import opportunityReportReducer from "./opportunity-report/opportunityReport.reducers";
import accessKeysReducer from "./accessKeys/accessKeys.reducers";
import teamManagementReducer from "./team-management/team-management.reducer";
import companyReducer from "./company/company.reducer";
import clientsReducer from "./clients/clients.reducers";
import brandsReducer from "./brands/brands.reducers";
import amazonConnectionsReducer from "./amazonAuthorize/amazonAuthorize.reducers";

const reducers: any = combineReducers({
  user: userReducer,
  product: productReducer,
  productDetails: productDetailsReducers,
  productScrape: productScrapeReducer,
  opportunityReport: opportunityReportReducer,
  accessKeys: accessKeysReducer,
  teamManagement: teamManagementReducer,
  company: companyReducer,
  clients: clientsReducer,
  brands: brandsReducer,
  amazonConnectionsReducer: amazonConnectionsReducer,
});

export type AppState = ReturnType<typeof reducers>;
export default reducers;
