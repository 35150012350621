import React, { useState, useRef, useEffect } from 'react';
import styles from './JsonCard.module.css';

import DeleteIcon from '@mui/icons-material/Delete';

interface JsonCardProps {
  data: object;
  initialLines?: number;
  className?: string;
  fileName?: string;
  openDeleteModal?:any
}

const JsonCard: React.FC<JsonCardProps> = ({
                                             data,
                                             initialLines = 3,
                                             className = '',
  fileName = '',openDeleteModal
                                           }) => {
  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const jsonString = JSON.stringify(data, null, 2);
  const lines = jsonString.split('\n');

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(expanded
        ? contentRef.current.scrollHeight
        : Math.min(contentRef.current.scrollHeight, initialLines * 24) // 24px per line
      );
    }
  }, [expanded, initialLines]);

  return (
    <div className={`${styles.cardContainer} ${className}`}>
      <div
        className={styles.contentWrapper}
        ref={contentRef}
        style={{ maxHeight: `${contentHeight}px` }}
      >
        <div className={'d-flex justify-content-between align-items-center'}>
          <h3 className={'m-0'}>{fileName}</h3>
          <DeleteIcon onClick={openDeleteModal} className={'cursor-pointer'} />
        </div>
        <pre className={styles.jsonPre}>
          {lines.map((line, index) => (
            <div key={index} className={styles.jsonLine}>
              {line}
            </div>
          ))}
        </pre>
      </div>

      {lines.length > initialLines && (
        <button
          className={styles.toggleButton}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Read less' : 'Read more...'}
        </button>
      )}
    </div>
  );
};

export default JsonCard;