import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormControl, MenuItem, Select } from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import {
  approveProductContentAction,
  completeProductDispatch,
} from "../../../../store/product/productDetails/productDetails.actions";
import { CompanyRoleSelector } from "../../../../store/company/company.selector";
import { DbUserSelector } from "../../../../store/user/user.selector";
import { generateProductApis } from "../../../../apis/generate-product";
import { SUCC_TITLE_UPDATED } from "../../../../utils/constants/messages/success";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { errorHandler } from "../../../../utils/helpers/apis";
import { Colors } from "../../../../utils/themes/main-theme";
import {
  disableTitleVariationDropdown,
  enableTitleDropdown,
  enableTitleDropdownForCompany,
} from "../../../../utils/helpers/priviligesChecks";
import { ContentStatusEnum } from "../../../../utils/constants/enums";
import CopyButton from "../../../Atoms/CopyButton/CopyButton";
import MTypography from "../../../Atoms/MTypography";
import ActionBox from "../../../Molecules/ActionBox";
import { getTitleVariationHeader } from "./config";
import classes from "./index.module.css";

interface TitleVariationsDropdownProps {
  isEditing: boolean;
}

const TitleVariationsDropdown = ({ isEditing = false }: TitleVariationsDropdownProps) => {
  const { isDesktop } = useDeviceType();
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  const [titlesList, setTitlesList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDoNotDisturbIconIndex, setShowDoNotDisturbIconIndex] = useState<number | null>(null);
  const [showUnapproveDialoge, setShowUnapproveDialoge] = useState<boolean>(false);
  const [currentSelectedTitle, setCurrentSelectedTitle] = useState<any>(null);

  const completeProduct = useSelector(CompleteProductSelector);

  const currentUser = useSelector(DbUserSelector);
  const companyRole = useSelector(CompanyRoleSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (completeProduct?.titleVariations) {
      const titleVariationsList =
        completeProduct.status !== ContentStatusEnum.APPROVED &&
        completeProduct.status !== ContentStatusEnum.PUBLISHED &&
        completeProduct.editedGeneratedProductContent.titleVariations
          ? completeProduct.editedGeneratedProductContent.titleVariations
          : completeProduct.titleVariations;
      const updatedVariations = titleVariationsList?.map((item) => ({
        ...item,
        header: getTitleVariationHeader(item.titleType),
      }));
      setTitlesList(updatedVariations);
      const selectedItem = updatedVariations.find((item) => item.selected);
      setSelectedTitle(selectedItem || { header: "No variations available" });
    }
  }, [completeProduct]);

  const updateSelectedTitle = async (event?: any) => {
    setIsLoading(true);
    const value = event ? event?.target?.value : currentSelectedTitle?.target?.value;
    const selectedObject = titlesList.find((item: any) => item.header === value);
    setSelectedTitle(selectedObject || { header: "No variations available" });
    try {
      const resp = await generateProductApis.updateSelectedTitleVariant({
        productId: completeProduct.id,
        titleType: selectedObject.titleType,
      });
      let updatedProduct = {
        ...completeProduct,
        ...resp,
      };

      if (Object.keys(completeProduct?.editedGeneratedProductContent)?.length !== 0) {
        updatedProduct = {
          ...completeProduct,
          ...resp,
        };
      }

      dispatch(completeProductDispatch({ ...updatedProduct, highlightClass: "", highlightedKeyword: "" }));
      setIsLoading(false);
      setCurrentSelectedTitle(null);
      toast.success(SUCC_TITLE_UPDATED, {
        toastId: "succ-title-updated",
      });
    } catch (error: any) {
      console.log("error : ", error);
      setCurrentSelectedTitle(null);
      setIsLoading(false);
      toast.error(errorHandler(error));
    }
  };

  const enableDropdown = currentUser?.companyID
    ? enableTitleDropdownForCompany(currentUser?.role, companyRole)
    : enableTitleDropdown(currentUser?.role);

  const handleTitleChange = (event: any) => {
    setCurrentSelectedTitle(event);
    if (disableTitleVariationDropdown(completeProduct.status as ContentStatusEnum)) {
      setShowUnapproveDialoge(true);
    } else {
      updateSelectedTitle(event);
    }
  };

  const confirmUnapprove = async () => {
    setIsLoading(true);
    dispatch(
      approveProductContentAction({
        ...completeProduct,
        isUnApprove: true,
      })
    )
      .then(() => {
        setShowUnapproveDialoge(false);
        toast.success("Product content has been unapproved.", {
          toastId: "product-unapproved",
        });
        updateSelectedTitle();
        setIsLoading(false);
      })
      .catch((e: any) => {
        setShowUnapproveDialoge(false);
        setIsLoading(false);
        toast.error(errorHandler(e));
      });
  };

  const cancelUnapprove = () => setShowUnapproveDialoge(false);

  return (
    <>
      {completeProduct?.titleVariations?.length ? (
        <>
          <div className={`d-flex align-items-center gap-2 px-2 ${isDesktop ? "" : "justify-content-between w-100"}`}>
            <MTypography variant={"subtitle1"} customClass={classes.TitleVariation}>
              Title Variations
            </MTypography>
            <FormControl sx={{ width: "206px" }}>
              <Select
                id="title-variation-select"
                value={selectedTitle?.header || ""}
                color="primary"
                onChange={handleTitleChange}
                disabled={isLoading || isEditing || !enableDropdown}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: `${Colors.primary} !important`,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${Colors.primary} !important`,
                  },
                  ".MuiSelect-icon": {
                    color: `${Colors.primary} !important`,
                  },
                  ".MuiSelect-select": {
                    color: `${Colors.primary} !important`,
                  },
                  "&.Mui-disabled": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#828282cc !important",
                    },
                    ".MuiSelect-icon": {
                      borderColor: "#828282cc !important",
                      color: "#828282cc !important",
                    },
                    ".MuiSelect-select": {
                      color: "#828282cc !important",
                    },
                  },
                  height: "40px",
                  fontSize: "14px",
                }}
                renderValue={(selected) => (selected ? selected : <em>Select a title</em>)}
              >
                {titlesList.length === 0 ? (
                  <MenuItem disabled>
                    <em>No variations available for this product</em>
                  </MenuItem>
                ) : (
                  titlesList.map((item: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={item.header}
                      className={`mt-2 mb-3 mx-3 ${!item.selected && classes.HoverEffect} ${
                        item.selected ? classes.ActiveMenuItem : classes.MenuItem
                      }`}
                      onMouseEnter={() => setShowDoNotDisturbIconIndex(index)}
                      onMouseLeave={() => setShowDoNotDisturbIconIndex(null)}
                    >
                      <div className={"d-flex w-100 justify-content-between align-items-center"}>
                        <div className={classes.Header}>{item.header}</div>
                        {disableTitleVariationDropdown(completeProduct.status as ContentStatusEnum) &&
                          !item.selected &&
                          showDoNotDisturbIconIndex === index && <DoNotDisturbIcon color="error" />}

                        <CopyButton text={item.title} />
                      </div>
                      <div className={classes.Title}>{item.title}</div>
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </div>
          <ActionBox
            handleAction={confirmUnapprove}
            handleBack={cancelUnapprove}
            open={showUnapproveDialoge}
            actionText={"Confirm"}
            message={`The product content is currently approved. Would you like to unapprove it and switch to the selected title variation?`}
            title={"Unapprove Content to Make Changes"}
            backText={"Cancel"}
            isLoading={isLoading}
          />
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default TitleVariationsDropdown;
