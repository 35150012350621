import { fetchAmazonASIN } from "../../../../utils/helpers/regex";
import * as XLSX from "xlsx";

export const bulkAsinsLimitUsingFile = 5000;//2500;

export const instructionsList = [
  "You can upload only XLS or XLSX file.",
  "File must have ASINS column.",
  "File size should not exceed 10MB.",
  "You can download sample file.",
  `Maximum upload limit is ${bulkAsinsLimitUsingFile} ASINs.`,
];


export const arrayContainsAllUndefined = (arr: any[]) => {
  return arr.every((element) => element === undefined);
};

const arrangeDataOfImportedSheet = (dataArray: string[]) => {
  // Remove the header line
  // const rows = dataArray.slice(1);
  const rows = dataArray;
  // Function to parse each row and create objects
  return rows.map(row => {
    const columns = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(col => col.trim());

    // Extract relevant data based on position
    const asin = columns[0];
    let attribute1 = columns[1] || "";
    let attribute2 = columns[2] || "";
    let attribute3 = columns[3] || "";
    let instructions = columns[4] || "";

    // removing / aaded for commas values
    attribute1 = attribute1?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');
    attribute2 = attribute2?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');
    attribute3 = attribute3?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');
    instructions = instructions?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');

    const attributes = [attribute1, attribute2, attribute3]?.filter(attr => attr)?.join("|||");

    const validAsin: string | false = fetchAmazonASIN(asin);
    if (validAsin && validAsin.length === 10 && validAsin.substring(0, 2) === "B0") {
      return {
        productASIN: asin,
        attributes: attributes,
        titleInstruction: instructions
      };
    } else {
      return null;
    }
  })?.filter(entry => entry !== null);
};

const removeDuplicatesAsins = (array: any) => {
  const unique = [];
  const seenAsins = new Set();

  for (const item of array) {
    if (!seenAsins.has(item.productASIN)) {
      seenAsins.add(item.productASIN);
      unique.push(item);
    }
  }

  return unique;
};

export const removeDuplicatesAsinsUpdated = (array: any) => {
  const uniqueMap = new Map();

  return array.filter((item: any) => {
    if (!uniqueMap.has(item["Product ID (ASIN)"])) {
      uniqueMap.set(item["Product ID (ASIN)"], true);
      return true;
    }
    return false;
  });

};

export const parseFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event?.target?.result;

      if (file.name.endsWith(".csv")) {
        if (typeof data === "string") {
          const rows = data.split(/\r?\n/);
          const arrangedData = arrangeDataOfImportedSheet(rows)
          const filteredList = removeDuplicatesAsins(arrangedData)
          resolve(filteredList);
        }
      } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheettocsv = XLSX.utils.sheet_to_csv(sheet);
        const rows = sheettocsv.split(/\r?\n/);
        const arrangedData = arrangeDataOfImportedSheet(rows)
        const filteredList = removeDuplicatesAsins(arrangedData)
        // const filteredList = generateArray(rows);
        resolve(filteredList);
      } else {
        reject(new Error("Unsupported file format"));
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
};

export function isArrayOfString(value: any): value is string[] {
  return Array.isArray(value) && value.every((item) => typeof item === "string");
}
